import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ children }) => {
  // Check if the token exists in localStorage
  const token = localStorage.getItem('token');

  // If token is not available, redirect to login page
  return token ? children : <Navigate to="/admin/login" />;
};

export default PrivateRoute;
