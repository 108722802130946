import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import SendTwoToneIcon from "@mui/icons-material/SendTwoTone";
import CancelTwoToneIcon from "@mui/icons-material/CancelTwoTone";
import InputLabel from "@mui/material/InputLabel";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import { Autocomplete } from "@mui/material";

const AddQuizPage = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();
    const [levels, setLevels] = useState([]);
    const [subjects, setSubjects] = useState([]);
    const [levelName, setLevelName] = useState('');
    const [subjectName, setSubjectName] = useState('');
    const [title, setTitle] = useState("");
    const [difficulty, setDifficulty] = useState("beginner"); // Default value


    const [subjectNameError, setSubjectNameError] = useState('');
    const [levelNameError, setLevelNameError] = useState('');
    const [titleError, setTitleError] = useState("");
    const [difficultyError, setDifficultyError] = useState("");
    const [loading, setLoading] = useState(false);

    const handleBackClick = () => {
        navigate(-1);
    };

    const handleCancelClick = () => {
        navigate("/admin/quiz"); // Replace '/desired/path' with the path you want to navigate to on cancel
    };

    useEffect(() => {
        const fetchSubject = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get(`${apiUrl}/admin/subject/get_all_subject`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                });
                setSubjects(response.data.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching category:', error);
                setLoading(false);
            }
        };
        const fetchLevel = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get(`${apiUrl}/admin/level/get_all_level`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                });
                setLevels(response.data.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching category:', error);
                setLoading(false);
            }
        };

        fetchSubject();
        fetchLevel();
    }, [apiUrl]);

    const handleSubmit = () => {
        setLevelNameError("");
        setSubjectNameError("");
        setTitleError("");
        setDifficultyError("");
        setLoading(true);

        // Initialize an error object
        const errors = {
            levelName: "",
            subjectName: "",
            title: "",
            difficulty: ""
        };

        // Validation checks
        if (!levelName) {
            errors.levelName = "Level is required.";
        }
        if (!subjectName) {
            errors.subjectName = "Subject is required.";
        }
        if (!title) {
            errors.title = "Title is required.";
        }
        if (!difficulty) {
            errors.difficulty = "Difficulty is required.";
        }

        // Check if there are any errors
        if (Object.values(errors).some(error => error)) {
            // Set error states if there are any errors
            setLevelNameError(errors.levelName);
            setSubjectNameError(errors.subjectName);
            setTitleError(errors.title);
            setDifficultyError(errors.difficulty);
            setLoading(false);
            return; // Exit if there are errors
        }

        const token = localStorage.getItem("token");
        const formData = new FormData();
        formData.append("level", levelName);
        formData.append("subject", subjectName);
        formData.append("title", title);
        formData.append("difficulty", difficulty);

        axios
            .post(`${apiUrl}/admin/quiz/create_quiz`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((response) => {
                const quizData = response.data;
                toast.success(quizData.message);
                setLoading(false);
                navigate("/admin/quiz"); // Adjust the path to where you want to navigate after success
            })
            .catch((error) => {
                setLoading(false);
                if (error.response) {
                    if (error.response.data && error.response.data.errors) {
                        if (error.response.data.errors.level) {
                            setLevelNameError(error.response.data.errors.level[0]);
                        }
                        if (error.response.data.errors.subject) {
                            setSubjectNameError(error.response.data.errors.subject[0]);
                        }
                        if (error.response.data.errors.title) {
                            setTitleError(error.response.data.errors.title[0]);
                        }
                        if (error.response.data.errors.difficulty) {
                            setDifficultyError(error.response.data.errors.difficulty[0]);
                        }

                    } else if (error.response.data.message) {
                        Swal.fire("Error", error.response.data.message, "error");
                    }
                } else {
                    toast.error("Server error or network issue. Please try again later.");
                }
            });
    };


    return (
        <Box sx={{ display: "flex" }}>
            <ToastContainer />
            <Paper sx={{ width: "100%", overflow: "hidden", padding: "4px" }}>
                <Box sx={{ display: "flex", alignItems: "center", marginBottom: 2 }}>
                    <Tooltip title="Return Back">
                        <ArrowBackRoundedIcon
                            color="disabled"
                            onClick={handleBackClick}
                            sx={{ cursor: "pointer", marginRight: 1 }}
                        />
                    </Tooltip>
                    <Typography sx={{
                        marginRight: { xs: "0", sm: "10px", md: "700px" },
                        fontWeight: "bold",
                        whiteSpace: "nowrap",
                        flexGrow: 1,
                        textAlign: { xs: "center", md: "left" } // Center on smaller screens, left align on medium and larger screens
                    }} variant="h6">
                        Add Quiz
                    </Typography>
                </Box>
                <Divider sx={{ marginY: 2 }} />

                <Grid container spacing={2} sx={{ marginBottom: 2 }}>
                    <Grid item xs={12} md={12}>
                        <Grid
                            container
                            spacing={2}
                            sx={{ marginBottom: 3, justifyContent: 'center', alignItems: 'center' }} // Centering the content
                        >
                            <Grid item xs={12} md={5}>
                                <InputLabel
                                    sx={{ fontSize: "1.2rem", fontWeight: "bold" }}
                                    shrink
                                >
                                    Select Level :
                                </InputLabel>
                                <Autocomplete
                                    options={levels}
                                    getOptionLabel={(option) => option.name} // Display shop name
                                    getOptionSelected={(option, value) => option.id === value.id} // Manage the selected option
                                    loading={loading}
                                    value={levels.find(levels => levels.id === levelName) || null}
                                    onChange={(event, newValue) => {
                                        // Store shop ID instead of shop name
                                        setLevelName(newValue ? newValue.id : '');
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder="Choose Level Name"
                                            variant="outlined"
                                            fullWidth
                                            error={Boolean(levelNameError)}
                                            helperText={levelNameError}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <InputLabel
                                    sx={{ fontSize: "1.2rem", fontWeight: "bold" }}
                                    shrink
                                >
                                    Select Subject :
                                </InputLabel>
                                <Autocomplete
                                    options={subjects}
                                    getOptionLabel={(option) => option.name} // Display shop name
                                    getOptionSelected={(option, value) => option.id === value.id} // Manage the selected option
                                    loading={loading}
                                    value={subjects.find(subjects => subjects.id === subjectName) || null} // Set the selected shop based on shop ID
                                    onChange={(event, newValue) => {
                                        // Store shop ID instead of shop name
                                        setSubjectName(newValue ? newValue.id : '');
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder="Choose Subject Name"
                                            variant="outlined"
                                            fullWidth
                                            error={Boolean(subjectNameError)}
                                            helperText={subjectNameError}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            spacing={2}
                            sx={{ marginBottom: 3, justifyContent: 'center', alignItems: 'center' }} // Centering the content
                        >
                            <Grid item xs={12} md={5}>
                                <InputLabel
                                    sx={{ fontSize: "1.2rem", fontWeight: "bold" }}
                                    shrink
                                >
                                    Title :
                                </InputLabel>
                                <TextField
                                    placeholder="Enter Title"
                                    variant="outlined"
                                    fullWidth
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                    error={!!titleError}
                                    helperText={titleError}
                                />
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <InputLabel
                                    sx={{ fontSize: "1.2rem", fontWeight: "bold" }}
                                    shrink
                                >
                                    Difficulty :
                                </InputLabel>
                                <TextField
                                    select
                                    placeholder="Choose Difficulty"
                                    variant="outlined"
                                    fullWidth
                                    value={difficulty}
                                    onChange={(e) => setDifficulty(e.target.value)}
                                    error={!!difficultyError}
                                    helperText={difficultyError}
                                    SelectProps={{
                                        native: true,
                                    }}
                                >
                                    <option selected value={'beginner'}>Beginner</option>
                                    <option value={'intermediate'}>Intermediate</option>
                                    <option value={'advance'}>Advance</option>
                                </TextField>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Divider sx={{ marginY: 2 }} />
                <Box
                    sx={{ display: "flex", justifyContent: "flex-end", margin: 1 }}
                >
                    <Button
                        onClick={handleCancelClick}
                        color="error"
                        variant="contained"
                        startIcon={<CancelTwoToneIcon />}
                        sx={{ marginRight: 2, color: "white" }}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleSubmit}
                        color="success"
                        variant="contained"
                        startIcon={<SendTwoToneIcon />}
                        disabled={loading}
                        sx={{ color: "white" }}
                    >
                        {loading ? "Adding..." : "Add Quiz"}
                    </Button>
                </Box>
            </Paper>
        </Box>
    );
};

export default AddQuizPage;
