import React, { useState, useEffect, useCallback } from 'react';
import { Grid, Paper, Typography, TextField, Button, Divider, List, ListItem, CircularProgress } from '@mui/material';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import axios from 'axios';
import Swal from 'sweetalert2';
import qs from "qs";

const UserQuestionPage = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const [question, setQuestion] = useState('');
    const [questions, setQuestions] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(false);

    // Function to load questions from the server
    const loadQuestions = useCallback(async (page) => {
        try {
            setLoading(true); // Set loading to true when API request starts
            const response = await axios.get(`${apiUrl}/portal/list_question`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('auth_token')}`,
                },
                params: { page },
            });

            const { data, last_page } = response.data.data;
            setQuestions(data);
            setTotalPages(last_page);
        } catch (error) {
            console.log('Error', 'Failed to load questions. Please try again.', 'error');
        } finally {
            setLoading(false); // Set loading to false when API request completes
        }
    }, [apiUrl]);

    useEffect(() => {
        loadQuestions(currentPage);
    }, [loadQuestions, currentPage]);

    // Handle adding a new question item
    const handleAddQuestion = async () => {
        if (!question.trim()) return; // Prevent adding empty Question

        const newQuestion = {
            question: question,
        };

        try {
            const response = await axios.post(
                `${apiUrl}/portal/create_question`,
                newQuestion,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
                    },
                }
            );

            const createdQuestion = response.data;
            setQuestions((prevQuestions) => [createdQuestion, ...prevQuestions]); // Prepend new question to the list
            setQuestion(''); // Clear the input field after adding
            loadQuestions(currentPage);
        } catch (error) {
            Swal.fire('Error', 'Failed to add question. Please try again.', 'error');
        }
    };

    // Handle deleting a question item
    const handleDelete = async (questionId) => {
        Swal.fire({
            title: "Are you sure?",
            text: "This action cannot be undone!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                deleteQuestion(questionId);
            }
        });
    };

    // Function to delete a question
    const deleteQuestion = async (questionId) => {
        const token = localStorage.getItem('auth_token');

        try {
            const response = await axios({
                method: "delete",
                url: `${apiUrl}/portal/delete_question`,
                data: qs.stringify({ question_id: questionId }),
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            });

            Swal.fire("Deleted!", response.data.message, "success");

            // Optimistically remove the deleted question from the list
            setQuestions((prevQuestions) =>
                prevQuestions.filter((question) => question.id !== questionId)
            );

            // Optionally, you can still reload questions for pagination
            // loadQuestions(currentPage); // You can decide whether you need to call this or not

        } catch (error) {
            console.error("Error deleting question:", error);
            Swal.fire("Error!", "An error occurred while deleting the question.", "error");
        }
    };


    // Handle page change (pagination)
    const handlePageChange = (direction) => {
        setCurrentPage((prevPage) => {
            const newPage = direction === 'next' ? prevPage + 1 : prevPage - 1;
            return newPage >= 1 && newPage <= totalPages ? newPage : prevPage;
        });
    };

    return (
        <Grid item xs={12}>
            <Paper sx={{ padding: 2 }} elevation={5}>
                <Typography variant="h6" gutterBottom>
                    <QuestionAnswerIcon sx={{ verticalAlign: 'middle', mr: 1 }} /> Questions
                </Typography>
                <TextField
                    label="Add a new question"
                    fullWidth
                    multiline
                    rows={2}
                    variant="outlined"
                    sx={{ marginBottom: 2 }}
                    value={question}
                    onChange={(e) => setQuestion(e.target.value)}
                />
                <Button variant="contained" startIcon={<AddCircleOutlineIcon />} fullWidth onClick={handleAddQuestion}>
                    Add Question
                </Button>
                <Divider sx={{ marginY: 2 }} />
                <Typography variant="subtitle1">Saved Questions:</Typography>
                {loading ? (
                    <CircularProgress sx={{ display: 'block', margin: '0 auto' }} />
                ) : questions.length === 0 ? (
                    <Typography variant="body1" sx={{ textAlign: 'center' }}>
                        No Questions Found
                    </Typography>
                ) : (
                    <List>
                        {questions.map((question, index) => (
                            <ListItem key={question.id}>
                                <Typography variant="body1" sx={{ flex: 1 }}>
                                    Q{index + 1}: {question.question} {/* Index starts from 0, so we add 1 */}
                                </Typography>
                                <DeleteTwoToneIcon
                                    sx={{ cursor: 'pointer' }}
                                    onClick={() => handleDelete(question.id)}
                                    color="error"
                                />
                            </ListItem>
                        ))}
                    </List>
                )}
                <Divider sx={{ marginY: 2 }} />
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button
                        variant="outlined"
                        onClick={() => handlePageChange('prev')}
                        disabled={currentPage === 1}
                    >
                        Previous
                    </Button>
                    <Button
                        variant="outlined"
                        onClick={() => handlePageChange('next')}
                        disabled={currentPage === totalPages}
                    >
                        Next
                    </Button>
                </div>
            </Paper>
        </Grid>
    );
};

export default UserQuestionPage;
