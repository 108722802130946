import React, { useState, useEffect } from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Collapse } from '@mui/material';
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';
import EventSeatTwoToneIcon from '@mui/icons-material/EventSeatTwoTone';
import AdminPanelSettingsTwoToneIcon from '@mui/icons-material/AdminPanelSettingsTwoTone';
import InfoIcon from '@mui/icons-material/Info';
import CategoryIcon from '@mui/icons-material/Category';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import TerminalTwoToneIcon from '@mui/icons-material/TerminalTwoTone';
import SubjectTwoToneIcon from '@mui/icons-material/SubjectTwoTone';
import QuestionAnswerTwoToneIcon from '@mui/icons-material/QuestionAnswerTwoTone';
import QuizTwoToneIcon from '@mui/icons-material/QuizTwoTone';
import PsychologyAltIcon from '@mui/icons-material/PsychologyAlt';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import IntegrationInstructionsTwoToneIcon from '@mui/icons-material/IntegrationInstructionsTwoTone';
import DataThresholdingTwoToneIcon from '@mui/icons-material/DataThresholdingTwoTone';
import AllInboxTwoToneIcon from '@mui/icons-material/AllInboxTwoTone';
import { ExpandLess, ExpandMore, TableChart as TableChartIcon,} from '@mui/icons-material';
import PagesTwoToneIcon from '@mui/icons-material/PagesTwoTone';
import WebTwoToneIcon from '@mui/icons-material/WebTwoTone';
import { styled, keyframes  } from '@mui/material/styles';
import { useNavigate, useLocation } from 'react-router-dom';

const drawerWidth = 240;
const collapsedWidth = 60;

// Keyframes for the entrance animation
const slideIn = keyframes`
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

const StyledDrawer = styled(Drawer)(({ theme, collapsed }) => ({
  width: collapsed ? collapsedWidth : drawerWidth,
  flexShrink: 0,
  animation: `${slideIn} 1.5s ease-out`,
  '& .MuiDrawer-paper': {
    width: collapsed ? collapsedWidth : drawerWidth,
    backgroundColor: '#f9f9f9',
    top: '64px',
    overflowY: 'auto',
    animation: `${slideIn} 1.5s ease-out`, 
    borderRadius: '0 10px 10px 0',
    boxShadow: '0 6px 10px rgba(0, 0, 0, 0.3)',
    // Scrollbar styles
    '&::-webkit-scrollbar': {
      width: '4px', // Decrease the width of the scrollbar
    },
    '&::-webkit-scrollbar-thumb': {
      background: 'linear-gradient(to right, #c8d0d8, #a3a4a4)',
      borderRadius: '4px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#f1f1f1', // Scrollbar track color
    },
  },
}));

const StyledListItem = styled(ListItem)(({ theme, selected }) => ({
  ...(selected && {
    backgroundColor: '#dbbef1',
    color: '#10d915',
    '& .MuiListItemIcon-root': {
      color: '#10d915',
    },
  }),
  borderRadius: '8px',
  marginBottom: theme.spacing(1),
  '&:hover': {
    backgroundColor: '#dbbef1',
  },
}));

function Sidebar({ open, collapsed }) {
  const [expandedMenu, setExpandedMenu] = React.useState(null);
  const [roleId, setRoleId] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const role = localStorage.getItem('role_id');
    setRoleId(role);  // Save role from localStorage
  }, []);

  const handleListItemClick = (path) => {
    navigate(path);
  };

  const handleMenuClick = (menu) => {
    setExpandedMenu(expandedMenu === menu ? null : menu);
  };

  React.useEffect(() => {
    const path = location.pathname;
    if (path.startsWith('/admin/user') || path.startsWith('/admin/role') || path.startsWith('/admin/privilege') || path.startsWith('/admin/active-log')) {
      setExpandedMenu('account');
    } else if (path.startsWith('/admin/category') || path.startsWith('/admin/level') || path.startsWith('/admin/subcategory') || path.startsWith('/admin/course')) {
      setExpandedMenu('course');
    } else if(path.startsWith('/admin/subject') || path.startsWith('/admin/topic') || path.startsWith('/admin/interview-question')){
      setExpandedMenu('subject');
    } else if(path.startsWith('/admin/quiz') || path.startsWith('/admin/question') || path.startsWith('/admin/option') || path.startsWith('/admin/quiz-result')){
      setExpandedMenu('quiz');
    } else if (path.startsWith('/admin/landing-content')) {
      setExpandedMenu('content');
    } else {
      setExpandedMenu(null);
    }
  }, [location.pathname]);

  const isSubmenuSelected = (submenuPaths) => {
    return submenuPaths.some((submenuPath) => location.pathname.startsWith(submenuPath));
  };

  return (
    <StyledDrawer variant="persistent" open={open} collapsed={collapsed}>
    {roleId === '1' ? (
      <List>
        <StyledListItem
          button
          selected={location.pathname === '/admin/dashboard'}
          onClick={() => handleListItemClick('/admin/dashboard')}
        >
          <ListItemIcon>
            <SpaceDashboardIcon />
          </ListItemIcon>
          {!collapsed && <ListItemText primary="Dashboard" />}
        </StyledListItem>

        <StyledListItem
          button
          selected={isSubmenuSelected(['/admin/user', '/admin/role', '/admin/privilege', '/admin/active-log'])}
          onClick={() => handleMenuClick('account')}
        >
          <ListItemIcon>
            <ManageAccountsIcon />
          </ListItemIcon>
          {!collapsed && (
            <>
              <ListItemText primary="Account" />
              {expandedMenu === 'account' ? <ExpandLess /> : <ExpandMore />}
            </>
          )}
        </StyledListItem>
        <Collapse in={expandedMenu === 'account'} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <StyledListItem
              button
              sx={{ paddingLeft: collapsed ? 2 : 4 }}
              selected={location.pathname === '/admin/user'}
              onClick={() => handleListItemClick('/admin/user')}
            >
              <ListItemIcon>
                <SwitchAccountIcon />
              </ListItemIcon>
              {!collapsed && <ListItemText primary="Users" />}
            </StyledListItem>
            <StyledListItem
              button
              sx={{ paddingLeft: collapsed ? 2 : 4 }}
              selected={location.pathname === '/admin/role'}
              onClick={() => handleListItemClick('/admin/role')}
            >
              <ListItemIcon>
                <EventSeatTwoToneIcon />
              </ListItemIcon>
              {!collapsed && <ListItemText primary="Roles" />}
            </StyledListItem>
            <StyledListItem
              button
              sx={{ paddingLeft: collapsed ? 2 : 4 }}
              selected={location.pathname === '/admin/privilege'}
              onClick={() => handleListItemClick('/admin/privilege')}
            >
              <ListItemIcon>
                <AdminPanelSettingsTwoToneIcon />
              </ListItemIcon>
              {!collapsed && <ListItemText primary="Privileges" />}
            </StyledListItem>
            <StyledListItem
              button
              sx={{ paddingLeft: collapsed ? 2 : 4 }}
              selected={location.pathname === '/admin/active-log'}
              onClick={() => handleListItemClick('/admin/active-log')}
            >
              <ListItemIcon>
                <InfoIcon />
              </ListItemIcon>
              {!collapsed && <ListItemText primary="Active Log" />}
            </StyledListItem>
          </List>
        </Collapse>
        <StyledListItem
          button
          selected={isSubmenuSelected(['/admin/category', '/admin/level', '/admin/subcategory', '/admin/course'])}
          onClick={() => handleMenuClick('Course')}
        >
          <ListItemIcon>
            <CategoryIcon />
          </ListItemIcon>
          {!collapsed && (
            <>
              <ListItemText primary="Course" />
              {expandedMenu === 'Course' ? <ExpandLess /> : <ExpandMore />}
            </>
          )}
        </StyledListItem>
        <Collapse in={expandedMenu === 'Course'} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <StyledListItem
              button
              sx={{ paddingLeft: collapsed ? 2 : 4 }}
              selected={location.pathname === '/admin/category'}
              onClick={() => handleListItemClick('/admin/category')}
            >
              <ListItemIcon>
                <VideoLabelIcon />
              </ListItemIcon>
              {!collapsed && <ListItemText primary="Category" />}
            </StyledListItem>
            <StyledListItem
              button
              sx={{ paddingLeft: collapsed ? 2 : 4 }}
              selected={location.pathname === '/admin/level'}
              onClick={() => handleListItemClick('/admin/level')}
            >
              <ListItemIcon>
                <AllInboxTwoToneIcon />
              </ListItemIcon>
              {!collapsed && <ListItemText primary="Level" />}
            </StyledListItem>
            <StyledListItem
              button
              sx={{ paddingLeft: collapsed ? 2 : 4 }}
              selected={location.pathname === '/admin/subcategory'}
              onClick={() => handleListItemClick('/admin/subcategory')}
            >
              <ListItemIcon>
                <DynamicFeedIcon />
              </ListItemIcon>
              {!collapsed && <ListItemText primary="Subcategory" />}
            </StyledListItem>
            <StyledListItem
              button
              sx={{ paddingLeft: collapsed ? 2 : 4 }}
              selected={location.pathname === '/admin/course'}
              onClick={() => handleListItemClick('/admin/course')}
            >
              <ListItemIcon>
                <IntegrationInstructionsTwoToneIcon />
              </ListItemIcon>
              {!collapsed && <ListItemText primary="Course" />}
            </StyledListItem>
          </List>
        </Collapse>
        <StyledListItem
          button
          selected={isSubmenuSelected(['/admin/subject', '/admin/topic', '/admin/interview-question'])}
          onClick={() => handleMenuClick('Subject')}
        >
          <ListItemIcon>
            <TableChartIcon />
          </ListItemIcon>
          {!collapsed && (
            <>
              <ListItemText primary="Subject" />
              {expandedMenu === 'Subject' ? <ExpandLess /> : <ExpandMore />}
            </>
          )}
        </StyledListItem>
        <Collapse in={expandedMenu === 'Subject'} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <StyledListItem
              button
              sx={{ paddingLeft: collapsed ? 2 : 4 }}
              selected={location.pathname === '/admin/subject'}
              onClick={() => handleListItemClick('/admin/subject')}
            >
              <ListItemIcon>
                <TerminalTwoToneIcon />
              </ListItemIcon>
              {!collapsed && <ListItemText primary="Subject" />}
            </StyledListItem>
            <StyledListItem
              button
              sx={{ paddingLeft: collapsed ? 2 : 4 }}
              selected={location.pathname === '/admin/topic'}
              onClick={() => handleListItemClick('/admin/topic')}
            >
              <ListItemIcon>
                <SubjectTwoToneIcon />
              </ListItemIcon>
              {!collapsed && <ListItemText primary="Topic" />}
            </StyledListItem>
            <StyledListItem
              button
              sx={{ paddingLeft: collapsed ? 2 : 4 }}
              selected={location.pathname === '/admin/interview-question'}
              onClick={() => handleListItemClick('/admin/interview-question')}
            >
              <ListItemIcon>
                <QuestionAnswerTwoToneIcon />
              </ListItemIcon>
              {!collapsed && <ListItemText primary="Interview Question" />}
            </StyledListItem>
          </List>
        </Collapse>
        <StyledListItem
          button
          selected={isSubmenuSelected(['/admin/quiz', '/admin/question', '/admin/option', '/admin/quiz-result'])}
          onClick={() => handleMenuClick('Quiz')}
        >
          <ListItemIcon>
            <QuizTwoToneIcon />
          </ListItemIcon>
          {!collapsed && (
            <>
              <ListItemText primary="Quiz" />
              {expandedMenu === 'Quiz' ? <ExpandLess /> : <ExpandMore />}
            </>
          )}
        </StyledListItem>
        <Collapse in={expandedMenu === 'Quiz'} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <StyledListItem
              button
              sx={{ paddingLeft: collapsed ? 2 : 4 }}
              selected={location.pathname === '/admin/quiz'}
              onClick={() => handleListItemClick('/admin/quiz')}
            >
              <ListItemIcon>
                <PsychologyAltIcon />
              </ListItemIcon>
              {!collapsed && <ListItemText primary="Quiz" />}
            </StyledListItem>
            <StyledListItem
              button
              sx={{ paddingLeft: collapsed ? 2 : 4 }}
              selected={location.pathname === '/admin/question'}
              onClick={() => handleListItemClick('/admin/question')}
            >
              <ListItemIcon>
                <HelpOutlineIcon />
              </ListItemIcon>
              {!collapsed && <ListItemText primary="Question" />}
            </StyledListItem>
            <StyledListItem
              button
              sx={{ paddingLeft: collapsed ? 2 : 4 }}
              selected={location.pathname === '/admin/option'}
              onClick={() => handleListItemClick('/admin/option')}
            >
              <ListItemIcon>
                <QuestionAnswerIcon />
              </ListItemIcon>
              {!collapsed && <ListItemText primary="Option" />}
            </StyledListItem>
            <StyledListItem
              button
              sx={{ paddingLeft: collapsed ? 2 : 4 }}
              selected={location.pathname === '/admin/quiz-result'}
              onClick={() => handleListItemClick('/admin/quiz-result')}
            >
              <ListItemIcon>
                <DataThresholdingTwoToneIcon />
              </ListItemIcon>
              {!collapsed && <ListItemText primary="Quiz Result" />}
            </StyledListItem>
          </List>
        </Collapse>
        <StyledListItem
          button
          selected={isSubmenuSelected(['/admin/landing-content',])}
          onClick={() => handleMenuClick('content')}
        >
          <ListItemIcon>
            <PagesTwoToneIcon />
          </ListItemIcon>
          {!collapsed && (
            <>
              <ListItemText primary="content" />
              {expandedMenu === 'content' ? <ExpandLess /> : <ExpandMore />}
            </>
          )}
        </StyledListItem>
        <Collapse in={expandedMenu === 'content'} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <StyledListItem
              button
              sx={{ paddingLeft: collapsed ? 2 : 4 }}
              selected={location.pathname === '/admin/landing-content'}
              onClick={() => handleListItemClick('/admin/landing-content')}
            >
              <ListItemIcon>
                <WebTwoToneIcon />
              </ListItemIcon>
              {!collapsed && <ListItemText primary="landing-content" />}
            </StyledListItem>
          </List>
        </Collapse>
      </List>
    ) : null}
    </StyledDrawer>
  );
}

export default Sidebar;
