import React, { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Card, CardContent, Container, Typography, TextField, Button, Grid, Box } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./css/RegisterPage.css";

const theme = createTheme({
  palette: {
    primary: {
      main: "#9F63FF", // Custom primary color
    },
  },
});

const RegisterPage = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password_confirmation, setPassword_confirmation] = useState("");
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const isMobile = useMediaQuery("(max-width:600px)");
  const apiUrl = process.env.REACT_APP_API_URL;

  const handleRegister = async (e) => {
    e.preventDefault();
    setNameError("");
    setEmailError("");
    setPasswordError("");
    setIsLoading(true);

    try {
      const response = await axios.post(`${apiUrl}/admin/auth/register`, {
        name,
        email,
        password,
        password_confirmation,
      });
      Swal.fire({
        icon: "success",
        title: response.data.message,
        text: response.data.info,
        showConfirmButton: true,
        timer: 30000,
        confirmButtonColor: "#2AD01B",
        confirmButtonText: "OK",
      }).then(() => {
        window.location.href = "/admin/login";
      });
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        if (error.response.data.errors.name) {
          setNameError(error.response.data.errors.name[0]);
        }
        if (error.response.data.errors.email) {
          setEmailError(error.response.data.errors.email[0]);
        }
        if (error.response.data.errors.password) {
          setPasswordError(error.response.data.errors.password[0]);
        }
      } else {
        toast.error(error.response.data.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <ToastContainer />
        <Grid container spacing={2}>
          {/* Left side with half-circle and image */}
          <Grid item xs={12} md={6} order={1}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
              position="relative"
            >
              <div className="half-circle-register" /> {/* Half-circle div */}
              <img src="/image/register.png" alt="register" style={{ maxWidth: "100%" }} />
            </Box>
          </Grid>
          {/* Right side with registration form */}
          <Grid item xs={12} md={6} order={2}>
            <Card
              elevation={20}
              sx={{
                maxWidth: "400px",
                borderRadius: 3,
                width: "100%",
                height: "fit-content",
                marginLeft: !isMobile ? "100px" : "0",
              }}
            >
              <CardContent sx={{ py: 0.6 }}>
                <div>
                  <Typography variant="h5" gutterBottom>
                    New here?
                  </Typography>
                  <Typography variant="h6" gutterBottom>
                    <span style={{ textAlign: "right" }}>
                      Signing up is easy. It only takes a few steps
                    </span>
                  </Typography>
                </div>
                <form onSubmit={handleRegister}>
                  <TextField
                    label="Name"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    error={!!nameError}
                    helperText={nameError}
                  />
                  <TextField
                    label="Email"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    error={!!emailError}
                    helperText={emailError}
                  />
                  <TextField
                    label="Password"
                    type="password"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    error={!!passwordError}
                    helperText={passwordError}
                  />
                  <TextField
                    label="Confirm Password"
                    type="password"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={password_confirmation}
                    onChange={(e) => setPassword_confirmation(e.target.value)}
                  />
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    size="large"
                    style={{ marginTop: "0.5rem" }}
                    disabled={isLoading}
                  >
                    {isLoading ? "Loading..." : "Sign Up"}
                  </Button>
                  <Typography
                    variant="body1"
                    align="center"
                    style={{ marginTop: "0.5rem" }}
                  >
                    Already have an account? <Link to="/admin/login">Sign In</Link>
                  </Typography>
                </form>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
};

export default RegisterPage;
