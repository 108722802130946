import React, { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Card, CardContent, Container, Typography, TextField, Button, Grid, Box, } from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';
import "./css/ResetPasswordPage.css";

const theme = createTheme({
  palette: {
    primary: {
      main: "#9F63FF", // Your custom color
    },
  },
});

const ResetPasswordPage = () => {
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [passwordConfirmationError, setPasswordConfirmationError] = useState("");
  const [isLoading, setIsLoading] = useState(false); // State to track loading
  const isMobile = useMediaQuery("(max-width:600px)");
  const apiUrl = process.env.REACT_APP_API_URL;

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setPasswordError("");
    setPasswordConfirmationError("");
    setIsLoading(true); // Set loading to true on form submission

    try {
      const token = new URLSearchParams(window.location.search).get("token");
      const response = await axios.post(
        `${apiUrl}/admin/auth/reset_password?token=${token}`,
        {
          password,
          password_confirmation: passwordConfirmation,
        }
      );
      Swal.fire({
        icon: "success",
        title: response.data.message,
        text: response.data.info,
        showConfirmButton: true,
        timer: 30000,
        confirmButtonColor: "#2AD01B",
        confirmButtonText: "OK",
      }).then(() => {
        window.location.href = "/admin/login";
      });
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        if (error.response.data.errors.password) {
          setPasswordError(error.response.data.errors.password[0]);
        }
        if (error.response.data.errors.password_confirmation) {
          setPasswordConfirmationError(
            error.response.data.errors.password_confirmation[0]);
        }
      } else {
        Swal.fire({
          icon: "warning",
          title: error.response.data.message,
          text: error.response.data.info,
          showConfirmButton: true,
          timer: 20000,
          confirmButtonColor: "#F56162",
          confirmButtonText: "OK",
        }).then(() => {
          window.location.href = "/admin/forgotPassword";
        });
      }
    } finally {
      setIsLoading(false); // Reset loading after request completes
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} order={isMobile ? 2 : 1} display="flex" justifyContent="center" alignItems="center">
            <Card elevation={20} sx={{ maxWidth: "400px", width: "100%", borderRadius: 3 }}>
              <CardContent>
                <div>
                  <Typography variant="h5" gutterBottom>
                    Hello! Just enter a new password
                  </Typography>
                  <Typography variant="h6" gutterBottom>
                    <span style={{ textAlign: "right" }}>
                      Update password to continue.
                    </span>
                  </Typography>
                </div>
                <form onSubmit={handleResetPassword}>
                  <TextField
                    label="Password"
                    type="password"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    error={!!passwordError}
                    helperText={passwordError}
                  />
                  <TextField
                    label="Password Confirmation"
                    type="password"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={passwordConfirmation}
                    onChange={(e) => setPasswordConfirmation(e.target.value)}
                    error={!!passwordConfirmationError}
                    helperText={passwordConfirmationError}
                  />
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    size="large"
                    style={{ marginTop: "1rem" }}
                    disabled={isLoading}
                  >
                    {isLoading ? "Updating..." : "Update"}
                  </Button>
                  <Typography
                    variant="body1"
                    align="center"
                    style={{ marginTop: "1rem" }}
                  >
                    Sign in with old credentials? <Link to="/admin/login">Sign In</Link>
                  </Typography>
                </form>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6} order={isMobile ? 1 : 2}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              <div className="half-circle-reset" /> {/* Half-circle div */}
              <img
                src="/image/resetpassword.png"
                alt="resetpassword"
                style={{ maxWidth: "100%" }}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
};

export default ResetPasswordPage;
