import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Container, Grid, IconButton, Tooltip, Typography, Box } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import HelpIcon from '@mui/icons-material/Help';
import StorageIcon from '@mui/icons-material/Storage';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import FileStoragePage from './FileStoragePage';
import TodoListPage from './TodoListPage';
import CheatSheetPage from './CheatSheetPage';
import UserQuestionPage from './UserQuestionPage';
import NotePage from './NotePage';

const options = [
  {
    id: 1,
    icon: <NoteAddIcon />,
    title: 'Prepare Note',
    description: 'Create and save your own notes in the workspace. Organize your thoughts and ideas to stay on top of tasks and projects.',
    color: '#9F63FF'
  },
  {
    id: 2,
    icon: <HelpIcon />,
    title: 'Questions',
    description: 'Store your questions and answers for future reference. Easily revisit and review them whenever you need clarity.',
    color: '#f27474'
  },
  {
    id: 3,
    icon: <StorageIcon />,
    title: 'CheatSheet',
    description: 'Create cheat sheets to help you with your work. Quickly reference important tips, shortcuts, or steps for your tasks.',
    color: '#d9c404'
  },
  {
    id: 4,
    icon: <UploadFileIcon />,
    title: 'Upload File',
    description: 'Upload and save files in your workspace. Keep important documents, images, and media all in one place for easy access.',
    color: '#0f85d9'
  },
  {
    id: 5,
    icon: <PlaylistAddCheckIcon />,
    title: 'Todo List',
    description: 'Keep track of your tasks with a personalized todo list. Stay organized and prioritize your tasks to improve productivity.',
    color: '#12de93'
  },
];

const WorkspacePage = () => {
  const { featureId } = useParams(); // Get the featureId from URL params
  const [openOptions, setOpenOptions] = useState(false);
  const [visibleIndex, setVisibleIndex] = useState(-1);
  const [selectedOption, setSelectedOption] = useState(null); // Tracks selected option (page)
  const [currentContent, setCurrentContent] = useState({
    title: 'Create Your Workspace',
    description: 'Here you can list and describe the features of your workspace. Add content like text, images, or other components as needed.'
  });
  const [cyclingIndex, setCyclingIndex] = useState(0);

  const radius = 100;

  const handleSettingsClick = () => {
    setOpenOptions((prev) => !prev);
    if (!openOptions) {
      setVisibleIndex(0);
    } else {
      setVisibleIndex(-1);
    }
  };

  // Set selectedOption if featureId is available (initial load)
  useEffect(() => {
    if (featureId !== undefined) {
      const id = parseInt(featureId); // Convert featureId to integer
      const optionIndex = options.findIndex(option => option.id === id);
      if (optionIndex !== -1) {
        setSelectedOption(optionIndex);
        setCurrentContent({
          title: `Create Your ${options[optionIndex].title}`,
          description: options[optionIndex].description
        });
      }
    }
  }, [featureId]);

  const handleOptionClick = (index) => {
    setSelectedOption(index);
    setCurrentContent({
      title: `Create Your ${options[index].title}`,
      description: options[index].description
    });
    setOpenOptions(false);
    setVisibleIndex(-1);
    setCyclingIndex(index);
  };

  useEffect(() => {
    let timer;
    if (openOptions && visibleIndex < options.length) {
      timer = setTimeout(() => {
        setVisibleIndex((prev) => prev + 1);
      }, 300);
    }
    return () => clearTimeout(timer);
  }, [openOptions, visibleIndex]);

  useEffect(() => {
    const cycleTimer = setInterval(() => {
      setCyclingIndex((prevIndex) => (prevIndex + 1) % options.length);
    }, 2000);

    return () => clearInterval(cycleTimer);
  }, []);

  useEffect(() => {
    setCurrentContent({
      title: (
        <>
          Explore Your Own <br />
          <span style={{ fontWeight: 'bold', fontSize: '3rem' }}>{options[cyclingIndex].title}</span>
        </>
      ),
      description: options[cyclingIndex].description,
    });
  }, [cyclingIndex]);

  return (
    <Container maxWidth="xl">
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} md={6}>
          <Typography variant="h4" gutterBottom>
            {currentContent.title}
          </Typography>
          <Box sx={{ mt: 1 }}>
            <Typography variant="body1">
              {currentContent.description}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} md={6} sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
          height: '280px',
        }}>
          <IconButton onClick={handleSettingsClick} sx={{
            backgroundColor: '#10d915',
            color: '#fff',
            borderRadius: '50%',
            width: 64,
            height: 64,
            zIndex: 2,
            '&:hover': {
              backgroundColor: '#02b706',
            },
          }}>
            <SettingsIcon />
          </IconButton>

          {options.map((option, index) => {
            const angle = (index / options.length) * 2 * Math.PI;
            const x = radius * Math.cos(angle);
            const y = radius * Math.sin(angle);

            return (
              <Tooltip key={option.id} title={option.title} arrow>
                <IconButton onClick={() => handleOptionClick(index)} sx={{
                  position: 'absolute',
                  top: `calc(45% + ${y}px)`,
                  left: `calc(46% + ${x}px)`,
                  backgroundColor: index <= visibleIndex ? option.color : '#fff',
                  borderRadius: '50%',
                  width: 48,
                  height: 48,
                  color: 'white',
                  opacity: index <= visibleIndex ? 1 : 0,
                  transform: index <= visibleIndex ? 'scale(1)' : 'scale(0.8)',
                  '&:hover': {
                    backgroundColor: index <= visibleIndex
                      ? `${option.color}AA`
                      : '#e0e0e0',
                    transform: 'scale(1.2)',
                  },
                  boxShadow: 2,
                }}>
                  {option.icon}
                </IconButton>
              </Tooltip>
            );
          })}
        </Grid>
      </Grid>

      <Grid container spacing={3} sx={{ padding: 1 }}>
        {selectedOption === 0 && <NotePage />}
        {selectedOption === 1 && <UserQuestionPage />}
        {selectedOption === 2 && <CheatSheetPage />}
        {selectedOption === 3 && <FileStoragePage />}
        {selectedOption === 4 && <TodoListPage />}
      </Grid>
    </Container>
  );
};

export default WorkspacePage;
