import React, { useState, useEffect, useCallback } from 'react';
import { Grid, Paper, Typography, TextField, Button, Divider, List, ListItem, Checkbox, CircularProgress } from '@mui/material';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import axios from 'axios';
import Swal from 'sweetalert2';
import qs from "qs";

const TodoListPage = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const [todoText, setTodoText] = useState('');
    const [todos, setTodos] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(false);

    // Function to load todos from the server
    const loadTodos = useCallback(async (page) => {
        try {
            setLoading(true); // Set loading to true when API request starts
            const response = await axios.get(`${apiUrl}/portal/list_todo`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('auth_token')}`,
                },
                params: { page },
            });

            const { data, last_page } = response.data.data;
            setTodos(data);
            setTotalPages(last_page);
        } catch (error) {
            console.log('Error', 'Failed to load todos. Please try again.', 'error');
        } finally {
            setLoading(false); // Set loading to false when API request completes
        }
    }, [apiUrl]);

    useEffect(() => {
        loadTodos(currentPage);
    }, [loadTodos, currentPage]);

    // Handle adding a new todo item
    const handleAddTodo = async () => {
        if (!todoText.trim()) return; // Prevent adding empty todo

        const newTodo = {
            title: todoText,
            completed: 0, // Set default completed value to 0 (incomplete)
        };

        try {
            const response = await axios.post(
                `${apiUrl}/portal/store_todo`,
                newTodo,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
                    },
                }
            );

            const createdTodo = response.data;
            setTodos((prevTodos) => [createdTodo, ...prevTodos]); // Prepend new todo to the list
            setTodoText(''); // Clear the input field after adding
            loadTodos(currentPage);
        } catch (error) {
            Swal.fire('Error', 'Failed to add todo. Please try again.', 'error');
        }
    };

    // Handle marking a todo as completed or not completed
    const handleCompleteTodo = async (todoId, completed) => {
        try {
            const updatedTodo = await axios.put(
                `${apiUrl}/portal/update_todo/${todoId}`,
                { completed: completed ? 1 : 0 },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
                    },
                }
            );

            setTodos((prevTodos) =>
                prevTodos.map((todo) =>
                    todo.id === todoId ? { ...todo, completed: updatedTodo.data.completed } : todo
                )
            );

            loadTodos(currentPage);
        } catch (error) {
            Swal.fire('Error', 'Failed to update todo status. Please try again.', 'error');
        }
    };

    // Handle deleting a todo item
    const handleDelete = async (todoId) => {
        Swal.fire({
            title: "Are you sure?",
            text: "This action cannot be undone!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                deleteTodo(todoId);
            }
        });
    };

    // Function to delete a todo
    const deleteTodo = async (todoId) => {
        const token = localStorage.getItem('auth_token');

        try {
            const response = await axios({
                method: "delete",
                url: `${apiUrl}/portal/delete_todo`,
                data: qs.stringify({ todo_id: todoId }),
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            });

            Swal.fire("Deleted!", response.data.message, "success");

            const updatedTodos = todos.filter(todo => todo.id !== todoId);
            setTodos(updatedTodos);

            if (updatedTodos.length === 0 && currentPage > 1) {
                setCurrentPage(prevPage => prevPage - 1);
            } else {
                loadTodos(currentPage);
            }
        } catch (error) {
            console.error("Error deleting todo:", error);
            Swal.fire("Error!", "An error occurred while deleting the todo.", "error");
        }
    };

    // Handle page change (pagination)
    const handlePageChange = (direction) => {
        setCurrentPage((prevPage) => {
            const newPage = direction === 'next' ? prevPage + 1 : prevPage - 1;
            return newPage >= 1 && newPage <= totalPages ? newPage : prevPage;
        });
    };

    return (
        <Grid item xs={12}>
            <Paper sx={{ padding: 2 }} elevation={5}>
                <Typography variant="h6" gutterBottom>
                    <PlaylistAddCheckIcon sx={{ verticalAlign: 'middle', mr: 1 }} /> Todo List
                </Typography>
                <TextField
                    label="Add a new todo item"
                    fullWidth
                    variant="outlined"
                    sx={{ marginBottom: 2 }}
                    value={todoText}
                    onChange={(e) => setTodoText(e.target.value)}
                />
                <Button variant="contained" startIcon={<AddCircleOutlineIcon />} fullWidth onClick={handleAddTodo}>
                    Add Todo
                </Button>
                <Divider sx={{ marginY: 2 }} />
                <Typography variant="subtitle1">Todo Items:</Typography>
                {loading ? (
                    <CircularProgress sx={{ display: 'block', margin: '0 auto' }} />
                ) : todos.length === 0 ? (
                    <Typography variant="body1" sx={{ textAlign: 'center' }}>
                        No Todo Items Found
                    </Typography>
                ) : (
                    <List>
                        {todos.map((todo) => (
                            <ListItem
                                key={todo.id}
                                sx={{
                                    textDecoration: todo.completed ? 'line-through' : 'none',
                                    display: 'flex',
                                    alignItems: 'center',
                                    cursor: 'pointer',
                                }}
                            >
                                <Checkbox
                                    checked={todo.completed}
                                    onChange={(e) => handleCompleteTodo(todo.id, e.target.checked)}
                                    color="primary"
                                />
                                <Typography variant="body1" sx={{ flex: 1 }}>
                                    {todo.title}
                                </Typography>
                                <DeleteTwoToneIcon
                                    sx={{ cursor: 'pointer' }}
                                    onClick={() => handleDelete(todo.id)}
                                    color="error"
                                />
                            </ListItem>
                        ))}
                    </List>
                )}
                <Divider sx={{ marginY: 2 }} />
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button
                        variant="outlined"
                        onClick={() => handlePageChange('prev')}
                        disabled={currentPage === 1}
                    >
                        Previous
                    </Button>
                    <Button
                        variant="outlined"
                        onClick={() => handlePageChange('next')}
                        disabled={currentPage === totalPages}
                    >
                        Next
                    </Button>
                </div>
            </Paper>
        </Grid>
    );
};

export default TodoListPage;
