import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import SendTwoToneIcon from "@mui/icons-material/SendTwoTone";
import CancelTwoToneIcon from "@mui/icons-material/CancelTwoTone";
import InputLabel from "@mui/material/InputLabel";
import { useNavigate } from "react-router-dom";
import { Autocomplete, CircularProgress } from '@mui/material';
import axios from "axios";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import qs from "qs";

const UpdateInterviewQuestionPage = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();
    const { interviewQuestionId } = useParams();
    const [interviewQuestion, setInterviewQuestion] = useState(null);
    const [question, setQuestion] = useState('');
    const [subjects, setSubjects] = useState([]);
    const [subjectName, setSubjectName] = useState('');
    const [status, setStatus] = useState('');
    const [answer, setAnswer] = useState('');
    const [questionError, setQuestionError] = useState('');
    const [subjectNameError, setSubjectNameError] = useState('');
    const [statusError, setStatusError] = useState('');
    const [answerError, setAnswerError] = useState('');
    const [loading, setLoading] = useState(false);

    const handleBackClick = () => {
        navigate(-1);
    };

    const handleCancelClick = () => {
        navigate("/admin/interview-question"); // Replace '/desired/path' with the path you want to navigate to on cancel
    };

    const handleUpdate = () => {
        setQuestionError('');
        setSubjectNameError('');
        setStatusError('');
        setAnswerError('');
        setLoading(true);

        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('interviewQuestion_id', interviewQuestion);
        formData.append('question', question);
        formData.append('subject', subjectName);
        formData.append('status', status);
        formData.append('answer', answer);

        axios.post(`${apiUrl}/admin/interview_Question/update_interview_Question`, formData, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
            },
        })
            .then(response => {
                const interviewQuestionData = response.data;
                //console.log(userData);
                toast.success(interviewQuestionData.message);
                setLoading(false);
                navigate('/admin/interview-question'); // Adjust the path to where you want to navigate after success
            })
            .catch(error => {
                setLoading(false);
                if (error.response) {
                    if (error.response.data && error.response.data.errors) {
                        if (error.response.data.errors.question) {
                            setQuestionError(error.response.data.errors.question[0]);
                        }
                        if (error.response.data.errors.subject) {
                            setSubjectNameError(error.response.data.errors.subject[0]);
                        }
                        if (error.response.data.errors.status) {
                            setStatusError(error.response.data.errors.status[0]);
                        }
                        if (error.response.data.errors.answer) {
                            setAnswerError(error.response.data.errors.answer[0]);
                        }
                    } else if (error.response.data.message) {
                        toast.error(error.response.data.message);
                    }
                } else {
                    toast.error('Server error or network issue. Please try again later.');
                }
            });
    };

    useEffect(() => {
        // Fetch user data based on courseId
        const token = localStorage.getItem('token');
        setLoading(true);
        axios.put(
            `${apiUrl}/admin/interview_Question/find_interview_Question`,
            qs.stringify({ interviewQuestion_id: interviewQuestionId }), // Serialize data to x-www-form-urlencoded format
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/x-www-form-urlencoded', // Set the content type to x-www-form-urlencoded
                },
            }
        )
            .then((response) => {
                const interviewQuestionData = response.data.data;
                setInterviewQuestion(interviewQuestionData.id);
                setQuestion(interviewQuestionData.question);
                setSubjectName(interviewQuestionData.subject_id);
                setStatus(interviewQuestionData.status)
                setAnswer(interviewQuestionData.answer);
               
            })
            .catch((error) => {
                console.error("Error fetching user data:", error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [interviewQuestionId, apiUrl]);


    useEffect(() => {
        const fetchSubject = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get(`${apiUrl}/admin/subject/get_all_subject`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                });
                setSubjects(response.data.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching category:', error);
                setLoading(false);
            }
        };

        fetchSubject();
    }, [apiUrl]);

    return (
        <Box sx={{ display: "flex" }}>
            <ToastContainer />
            <Paper sx={{ width: "100%", overflow: "hidden", padding: "4px" }}>
                {loading && (
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "80vh" // Full height to center the loader
                        }}
                    >
                        <CircularProgress />
                    </Box>
                )}
                {!loading && (
                    <Grid>
                        <Box sx={{ display: "flex", alignItems: "center", marginBottom: 2 }}>
                            <Tooltip title="Return Back">
                                <ArrowBackRoundedIcon
                                    color="disabled"
                                    onClick={handleBackClick}
                                    sx={{ cursor: "pointer", marginRight: 1 }}
                                />
                            </Tooltip>
                            <Typography sx={{
                                marginRight: { xs: "0", sm: "10px", md: "700px" },
                                fontWeight: "bold",
                                whiteSpace: "nowrap",
                                flexGrow: 1,
                                textAlign: { xs: "center", md: "left" } // Center on smaller screens, left align on medium and larger screens
                            }} variant="h6">
                                Update Interview Question
                            </Typography>
                        </Box>
                        <Divider sx={{ marginY: 2 }} />

                        <Grid container spacing={2} sx={{ marginBottom: 3, display: 'flex', alignItems: 'stretch' }}>
                            <Grid item xs={12} md={4.5} sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <Grid container spacing={2} sx={{ marginBottom: 3 }}>
                            <Grid item xs={12} md={12}>
                                    <InputLabel
                                        sx={{ fontSize: "1.2rem", fontWeight: "bold" }}
                                        shrink
                                    >
                                        Select Subject :
                                    </InputLabel>
                                    <Autocomplete
                                        options={subjects}
                                        getOptionLabel={(option) => option.name} // Display shop name
                                        getOptionSelected={(option, value) => option.id === value.id} // Manage the selected option
                                        loading={loading}
                                        value={subjects.find(subjects => subjects.id === subjectName) || null} // Set the selected shop based on shop ID
                                        onChange={(event, newValue) => {
                                            // Store shop ID instead of shop name
                                            setSubjectName(newValue ? newValue.id : '');
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                placeholder="Choose Subject Name"
                                                variant="outlined"
                                                fullWidth
                                                error={Boolean(subjectNameError)}
                                                helperText={subjectNameError}
                                            />
                                        )}
                                    />
                                </Grid>
                              
                                <Grid item xs={12} md={12}>
                                    <InputLabel
                                        sx={{ fontSize: "1.2rem", fontWeight: "bold" }}
                                        shrink
                                    >
                                        Choose Status :
                                    </InputLabel>
                                    <TextField
                                        select
                                        placeholder="Select Status For Interview Question"
                                        variant="outlined"
                                        fullWidth
                                        value={status}
                                        onChange={(e) => setStatus(e.target.value)}
                                        error={!!statusError}
                                        helperText={statusError}
                                        SelectProps={{
                                            native: true,
                                        }}
                                    >
                                        <option selected value={1}>Active</option>
                                        <option value={0}>Inactive</option>
                                    </TextField>
                                </Grid>
                            </Grid>
                            </Grid>
                            <Grid item xs={12} md={0.5} sx={{ display: 'flex', alignItems: 'stretch' }}>
                                <Divider orientation="vertical" variant="middle" flexItem />
                            </Grid>
                            <Grid item xs={12} md={7}>
                            <Grid container spacing={2} sx={{ marginBottom: 3 }}>
                            <Grid item xs={12} md={12}>
                                    <InputLabel
                                        sx={{ fontSize: "1.2rem", fontWeight: "bold" }}
                                        shrink
                                    >
                                        Interview Question :
                                    </InputLabel>
                                    <TextField
                                        placeholder="Enter Interview Question"
                                        variant="outlined"
                                        type="text"
                                        fullWidth
                                        value={question}
                                        onChange={(e) => setQuestion(e.target.value)}
                                        error={Boolean(questionError)}
                                        helperText={questionError}
                                    />
                                </Grid>
                                <Grid item xs={12} md={12} sx={{ marginBottom: 3 }}>
                                    <InputLabel
                                        sx={{ fontSize: "1.2rem", fontWeight: "bold" }}
                                        shrink
                                    >
                                        Answer Of Above Question :
                                    </InputLabel>
                                    {answerError && (
                                        <Typography color="error" variant="caption">
                                            {answerError}
                                        </Typography>
                                    )}
                                    <ReactQuill
                                        value={answer}
                                        onChange={setAnswer}
                                        modules={{
                                            toolbar: [
                                                [{ 'header': [1, 2, 3, 4, 5, 6, false] }], // Header levels
                                                [{ 'font': [] }], // Font selection
                                                [{ 'size': ['small', false, 'large', 'huge'] }], // Font size selection
                                                ['bold', 'italic', 'underline', 'strike'], // Text formatting
                                                [{ 'color': [] }, { 'background': [] }], // Text color and background
                                                [{ 'script': 'sub' }, { 'script': 'super' }], // Subscript / superscript
                                                [{ 'list': 'ordered' }, { 'list': 'bullet' }], // Ordered/bullet lists
                                                [{ 'indent': '-1' }, { 'indent': '+1' }], // Indent/outdent
                                                [{ 'align': [] }], // Text alignment
                                                ['link', 'image', 'video'], // Link, image, and video options
                                                ['blockquote', 'code-block'], // Blockquote and code block
                                                ['clean'] // Remove formatting
                                            ],
                                        }}
                                        placeholder="Please provide answer of above question"
                                        style={{ height: '150px' }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        </Grid>

                        <Divider sx={{ marginY: 2 }} />
                        <Box sx={{ display: "flex", justifyContent: "flex-end", margin: 1 }}>
                            <Button
                                onClick={handleCancelClick}
                                color="error"
                                variant="contained"
                                startIcon={<CancelTwoToneIcon />}
                                sx={{ marginRight: 2, color: "white" }}
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={handleUpdate}
                                color="success"
                                variant="contained"
                                startIcon={<SendTwoToneIcon />}
                                disabled={loading}
                                sx={{ color: 'white' }}
                            >
                                {loading ? "Updating..." : "Update Interview Question"}
                            </Button>
                        </Box>
                    </Grid>
                )}
            </Paper>
        </Box>

    );
};

export default UpdateInterviewQuestionPage;
