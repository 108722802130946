import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Grid, Paper, Typography, Button, Divider, List, ListItem } from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import PreviewTwoToneIcon from '@mui/icons-material/PreviewTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import qs from "qs";

const FileStoragePage = () => {
    const apiUrl = process.env.REACT_APP_API_URL; // Define in `.env` file
    const [files, setFiles] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [renameFile, setRenameFile] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    // Function to load files from the server
    const loadFiles = useCallback(async (page) => {
        try {
            const response = await axios.get(`${apiUrl}/portal/list_file`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('auth_token')}`,
                },
                params: { page },
            });

            const { data, last_page } = response.data.data;
            setFiles(data);
            setTotalPages(last_page);
        } catch (error) {
            console.log('Error', 'Failed to load files. Please try again.', 'error');
        }
    }, [apiUrl]);

    useEffect(() => {
        loadFiles(currentPage);
    }, [loadFiles, currentPage]);

    // Handle file selection
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
        setRenameFile(file?.name || ''); // Default rename to original file name
    };

    // Handle file upload
    const handleUpload = async () => {
        if (!selectedFile) {
            Swal.fire('Warning', 'Please select a file first!', 'warning');
            return;
        }

        const formData = new FormData();
        formData.append('file', selectedFile);
        formData.append('rename_file', renameFile);

        try {
            const response = await axios.post(`${apiUrl}/portal/store_file_upload`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${localStorage.getItem('auth_token')}`,
                },
            });

            Swal.fire('Success', response.data.message || 'File uploaded successfully!', 'success');
            setSelectedFile(null);
            setRenameFile('');
            setFiles([response.data.file, ...files]); // Prepend the newly uploaded file to the list
        } catch (error) {
            Swal.fire('Error', 'Failed to upload the file. Please try again.', 'error');
        }
    };

    // Handle file preview
    const handlePreview = (filePath) => {
        window.open(filePath, '_blank');
    };

    // Handle file deletion
    const handleDelete = async (fileId) => {
        Swal.fire({
            title: "Are you sure?",
            text: "This action cannot be undone!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                // If confirmed, proceed with delete
                deleteFile(fileId);
            }
        });
    };


    // Function to call API for deleting shop
    const deleteFile = async (fileId) => {
        const token = localStorage.getItem('auth_token');

        try {
            const response = await axios({
                method: "delete",
                url: `${apiUrl}/portal/delete_file`,
                data: qs.stringify({ file_id: fileId }),
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            });

            Swal.fire("Deleted!", response.data.message, "success");

            // Check if the current page is now empty
            const updatedFiles = files.filter(file => file.id !== fileId);
            setFiles(updatedFiles);

            // If the updated files length is 0, and we are not on the first page, load the previous page
            if (updatedFiles.length === 0 && currentPage > 1) {
                setCurrentPage(prevPage => prevPage - 1);
            } else {
                // If there are still files on the current page, reload the current page
                loadFiles(currentPage);
            }
        } catch (error) {
            console.error("Error deleting File:", error);
            Swal.fire("Error!", "An error occurred while deleting the file.", "error");
        }
    };

    // Handle page change
    const handlePageChange = (direction) => {
        setCurrentPage((prevPage) => {
            const newPage = direction === 'next' ? prevPage + 1 : prevPage - 1;
            return newPage >= 1 && newPage <= totalPages ? newPage : prevPage;
        });
    };

    return (
        <Grid item xs={12}>
            <Paper sx={{ padding: 2 }} elevation={5}>
                <Typography variant="h6" gutterBottom>
                    <UploadFileIcon sx={{ verticalAlign: 'middle', mr: 1 }} /> File Storage
                </Typography>
                <Button
                    variant="contained"
                    component="label"
                    fullWidth
                    startIcon={<UploadFileIcon />}
                >
                    Upload File
                    <input type="file" hidden onChange={handleFileChange} />
                </Button>

                {selectedFile && (
                    <div style={{ width: '100%', marginTop: '10px' }}>
                        <input
                            type="text"
                            placeholder="Rename file (optional)"
                            value={renameFile}
                            onChange={(e) => setRenameFile(e.target.value)}
                            style={{
                                width: '100%',
                                padding: '8px',
                                borderRadius: '4px',
                                border: '1px solid #ccc',
                                boxSizing: 'border-box',
                            }}
                        />
                    </div>
                )}

                <Button
                    variant="contained"
                    color="success"
                    fullWidth
                    onClick={handleUpload}
                    disabled={!selectedFile}
                    sx={{ mt: 1 }}
                >
                    Upload
                </Button>
                <Divider sx={{ marginY: 2 }} />
                <Typography variant="subtitle1">Stored Files :</Typography>
                {files.length > 0 ? (
                    <List>
                        {files.map((file) => (
                            <ListItem
                                key={file.id}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography variant="body2">{file.new_name}</Typography>
                                <div>
                                    <PreviewTwoToneIcon
                                        sx={{ marginLeft: 1, cursor: 'pointer' }}
                                        onClick={() => handlePreview(file.path)}
                                        color="warning"
                                    />
                                    <DeleteTwoToneIcon
                                        sx={{ marginLeft: 1, cursor: 'pointer' }}
                                        onClick={() => handleDelete(file.id)}
                                        color="error"
                                    />
                                </div>
                            </ListItem>
                        ))}
                    </List>
                ) : (
                    <Typography variant="body2" color="textSecondary" sx={{ textAlign: 'center', marginY: 2 }}>
                        No Files Found.
                    </Typography>
                )}
                <Divider sx={{ marginY: 2 }} />
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button
                        variant="outlined"
                        onClick={() => handlePageChange('prev')}
                        disabled={currentPage === 1}
                    >
                        Previous
                    </Button>
                    <Button
                        variant="outlined"
                        onClick={() => handlePageChange('next')}
                        disabled={currentPage === totalPages}
                    >
                        Next
                    </Button>
                </div>
            </Paper>
        </Grid>
    );
};

export default FileStoragePage;
