import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Paper, Typography, TextField, Button, Divider, List, ListItem, CircularProgress, IconButton } from '@mui/material';
import NotesIcon from '@mui/icons-material/Notes';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from 'axios';
import Swal from 'sweetalert2';
import qs from 'qs';

const NotePage = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();
    const [title, setTitle] = useState('');
    const [note, setNote] = useState('');
    const [notes, setNotes] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(false);

    // Function to load notes from the server
    const loadNotes = useCallback(async (page) => {
        try {
            setLoading(true);
            const response = await axios.get(`${apiUrl}/portal/list_note`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
                },
                params: { page },
            });

            const { data } = response.data; // Destructure the response data
            setNotes(data.data); // Set the notes array directly from the response data
            setTotalPages(data.last_page); // Set total pages from the response
        } catch (error) {
            console.log('Error', 'Failed to load notes. Please try again.', 'error');
        } finally {
            setLoading(false);
        }
    }, [apiUrl]);

    useEffect(() => {
        loadNotes(currentPage);
    }, [loadNotes, currentPage]);

    // Handle adding a new Note item
    const handleAddNote = async () => {
        if (!note.trim() || !title.trim()) return;

        const newNote = {
            title: title,
            note: note, // Adjust content structure
        };

        try {
            const response = await axios.post(
                `${apiUrl}/portal/create_note`,
                newNote,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
                    },
                }
            );

            const createdNote = response.data;
            setNotes((prevNotes) => [createdNote, ...prevNotes]); // Corrected to use prevNotes
            setTitle('');
            setNote('');
            loadNotes(currentPage);
        } catch (error) {
            Swal.fire('Error', 'Failed to add note. Please try again.', 'error');
        }
    };

    // Handle viewing a note
    const handleView = (noteId) => {
        navigate(`/portal/note/${noteId}`);
    };

    // Handle deleting a note
    const handleDelete = async (noteId) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'This action cannot be undone!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
        }).then((result) => {
            if (result.isConfirmed) {
                deleteNote(noteId);
            }
        });
    };

    // Function to delete a note sheet content
    const deleteNote = async (noteId) => {
        try {
            const response = await axios.delete(`${apiUrl}/portal/delete_note`, {
                data: qs.stringify({ note_id: noteId }),
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            });

            Swal.fire('Deleted!', response.data.message, 'success');

            // Check if the last note was deleted
            if (notes.length === 1) {
                setNotes([]); // Reset notes state to an empty array
            } else {
                loadNotes(currentPage);
            }
        } catch (error) {
            Swal.fire('Error', 'Failed to delete notes. Please try again.', 'error');
        }
    };

    // Handle page change (pagination)
    const handlePageChange = (direction) => {
        setCurrentPage((prevPage) => {
            const newPage = direction === 'next' ? prevPage + 1 : prevPage - 1;
            return newPage >= 1 && newPage <= totalPages ? newPage : prevPage;
        });
    };

    return (
        <Grid item xs={12}>
            <Paper sx={{ padding: 2 }} elevation={5}>
                <Typography variant="h6" gutterBottom>
                    <NotesIcon sx={{ verticalAlign: 'middle', mr: 1 }} /> Notes
                </Typography>
                <TextField
                    label="Add Title"
                    fullWidth
                    size="small"
                    sx={{ marginBottom: 2 }}
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                />
                <ReactQuill
                    theme="snow"
                    value={note}
                    onChange={setNote}
                    style={{ height: '100px' }}
                    placeholder="Write your note here..."
                />
                <Button style={{ marginTop: '70px' }} variant="contained" startIcon={<AddCircleOutlineIcon />} fullWidth onClick={handleAddNote}>
                    Add Note
                </Button>
                <Divider sx={{ marginY: 2 }} />
                <Typography variant="subtitle1">Saved Notes:</Typography>
                {loading ? (
                    <CircularProgress sx={{ display: 'block', margin: '0 auto' }} />
                ) : notes.length === 0 ? (
                    <Typography variant="body1" sx={{ textAlign: 'center' }}>
                        No Note List Found
                    </Typography>
                ) : (
                    <List>
                        {notes.map((note, index) => (
                            <ListItem
                                key={note.id}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center'
                                }}
                            >
                                <Typography
                                    variant="body1"
                                    sx={{
                                        flex: 1,
                                        wordBreak: 'break-word', // Ensure long text wraps properly
                                        overflow: 'hidden', // Prevent overflow
                                        whiteSpace: 'normal', // Allow text to wrap
                                    }}
                                >
                                    Note {index + 1}: {note.title}
                                </Typography>
                                <IconButton onClick={() => handleView(note.id)} color="warning">
                                    <VisibilityIcon />
                                </IconButton>
                                <IconButton onClick={() => handleDelete(note.id)} color="error">
                                    <DeleteTwoToneIcon />
                                </IconButton>
                            </ListItem>
                        ))}
                    </List>
                )}
                <Divider sx={{ marginY: 2 }} />
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button
                        variant="outlined"
                        onClick={() => handlePageChange('prev')}
                        disabled={currentPage === 1}
                    >
                        Previous
                    </Button>
                    <Button
                        variant="outlined"
                        onClick={() => handlePageChange('next')}
                        disabled={currentPage === totalPages}
                    >
                        Next
                    </Button>
                </div>
            </Paper>
        </Grid>
    );
};

export default NotePage;