import React, { useState } from "react";
import { Box, TextField, Typography, Grid, Button, Stack, Container } from "@mui/material";
import { PlayArrow, Code, Share } from "@mui/icons-material";
import beautify from "js-beautify";
import useCompilerApi from "./useCompilerApi"; // Custom hook for handling API calls

const CCompiler = () => {
  const [cCode, setCCode] = useState(`#include <stdio.h>\n\nint main() {\n    printf("Hello, World!\\n");\n    return 0;\n}`);
  const { output, isLoading, apiError, runCode } = useCompilerApi(); // Destructure the custom hook return values

  const handleRun = () => {
    runCode(cCode, "c"); // Call the API with the current code and language
  };

  const handleBeautify = () => {
    const beautifiedCode = beautify.js(cCode, { indent_size: 2 });
    setCCode(beautifiedCode);
  };

  const handleShare = () => {
    navigator.clipboard
      .writeText(cCode)
      .then(() => alert("Code copied to clipboard!"))
      .catch((err) => alert("Failed to copy code: " + err.message));
  };

  return (
    <Container maxWidth="xl">
      <Stack direction="row" spacing={2} sx={{ marginBottom: 2 }}>
        <Button
          variant="contained"
          color="success"
          onClick={handleRun}
          startIcon={<PlayArrow />}
          sx={{ textTransform: "none", color: "white" }}
          disabled={isLoading}
        >
          {isLoading ? "Running..." : "Run"}
        </Button>

        <Button
          variant="contained"
          color="secondary"
          onClick={handleBeautify}
          startIcon={<Code />}
          sx={{ textTransform: "none" }}
        >
          Beautify
        </Button>

        <Button
          variant="contained"
          color="warning"
          onClick={handleShare}
          startIcon={<Share />}
          sx={{ textTransform: "none", color: "white" }}
        >
          Share
        </Button>
      </Stack>

      {apiError && (
        <Typography color="error" variant="body1" sx={{ marginBottom: 2 }}>
          {apiError}
        </Typography>
      )}

      <Grid container spacing={2} sx={{ height: "100%" }}>
        {/* Code Input Area */}
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              height: "93%",
              padding: 2,
              bgcolor: "#282c34",
              color: "#fff",
              borderRadius: 2,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography variant="h6" sx={{ color: "lightgray", marginBottom: 1 }}>
              C Code
            </Typography>
            <TextField
              value={cCode}
              onChange={(e) => setCCode(e.target.value)}
              variant="outlined"
              multiline
              rows={20}
              fullWidth
              sx={{
                backgroundColor: "#1e1e1e",
                color: "#fff",
                flex: 1,
                "& .MuiInputBase-input": { color: "white" },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#4d4d4d",
                  },
                  "&:hover fieldset": {
                    borderColor: "#fff",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#fff",
                  },
                },
              }}
            />
          </Box>
        </Grid>

        {/* Console Output Area */}
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              height: "93%",
              padding: 2,
              backgroundColor: "#f5f5f5",
              borderRadius: 2,
              overflowY: "hidden",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography variant="h6" sx={{ marginBottom: 1 }}>
              Console Output
            </Typography>
            <Box
              sx={{
                height: "100%",
                padding: 1,
                bgcolor: "#000",
                color: "#fff",
                borderRadius: 2,
                overflowY: "auto",
                whiteSpace: "pre-wrap",
              }}
            >
              {isLoading ? "Compiling..." : output || "Output will appear here"}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default CCompiler;
