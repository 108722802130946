import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import SendTwoToneIcon from "@mui/icons-material/SendTwoTone";
import CancelTwoToneIcon from "@mui/icons-material/CancelTwoTone";
import CloudUploadTwoToneIcon from "@mui/icons-material/CloudUploadTwoTone";
import InputLabel from "@mui/material/InputLabel";

import { useNavigate } from "react-router-dom";
import { Autocomplete, Card, CardContent, CircularProgress } from '@mui/material';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import qs from "qs";

const UpdateLevelPage = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();
    const { levelId } = useParams();
    const [level, setLevel] = useState(null);
    const [name, setName] = useState('');
    const [categoryName, setCategoryName] = useState('');
    const [categories, setCategories] = useState([]);
    const [image, setImage] = useState("/admin/no_image.jpg");
    const [imageFile, setImageFile] = useState(null);
    const [nameError, setNameError] = useState('');
    const [categoryNameError, setCategoryNameError] = useState('');
    const [imageError, setImageError] = useState('');
    const [loading, setLoading] = useState(false);

    const handleBackClick = () => {
        navigate(-1);
    };

    const handleCancelClick = () => {
        navigate("/admin/level"); // Replace '/desired/path' with the path you want to navigate to on cancel
    };

    const handleUpdate = () => {
        setNameError('');
        setCategoryNameError('');
        setImageError('');
        setLoading(true);

        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('level_id', level);
        formData.append('level_name', name);
        formData.append('category', categoryName);
        formData.append('image', image ?? null);
        if (imageFile) {
            formData.append('image_file', imageFile); // Ensure this is a File object
        }

        axios.post(`${apiUrl}/admin/level/update_level`, formData, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
            },
        })
            .then(response => {
                const levelData = response.data;
                //console.log(userData);
                toast.success(levelData.message);
                setLoading(false);
                navigate('/admin/level'); // Adjust the path to where you want to navigate after success
            })
            .catch(error => {
                setLoading(false);
                if (error.response) {
                    if (error.response.data && error.response.data.errors) {
                        if (error.response.data.errors.level_name) {
                            setNameError(error.response.data.errors.level_name[0]);
                        }
                        if (error.response.data.errors.category) {
                            setCategoryNameError(error.response.data.errors.category[0]);
                        }
                        if (error.response.data.errors.image_file) {
                            setImageError(error.response.data.errors.image_file[0]);
                        }
                    } else if (error.response.data.message) {
                        toast.error(error.response.data.message);
                    }
                } else {
                    toast.error('Server error or network issue. Please try again later.');
                }
            });
    };


    const handleImageFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (file.type.startsWith('image/')) {
                setImage(URL.createObjectURL(file));
                setImageFile(file);
                setImageError('');
            } else {
                setImageError('The file must be an image.');
            }
        }
    };

    useEffect(() => {
        // Fetch user data based on levelId
        const token = localStorage.getItem('token');
        setLoading(true);
        axios.put(
            `${apiUrl}/admin/level/find_level`,
            qs.stringify({ level_id: levelId }), // Serialize data to x-www-form-urlencoded format
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/x-www-form-urlencoded', // Set the content type to x-www-form-urlencoded
                },
            }
        )
            .then((response) => {
                const levelData = response.data.data;
                setLevel(levelData.id);
                setName(levelData.name);
                setCategoryName(levelData.category_id);
                setImage(levelData.image ?? "/image/no_image.jpg");
            })
            .catch((error) => {
                console.error("Error fetching user data:", error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [levelId, apiUrl]);

    useEffect(() => {
        return () => {
            if (image.startsWith('blob:')) {
                URL.revokeObjectURL(image); // Clean up URL.createObjectURL()
            }
        };
    }, [image]);

    useEffect(() => {
        const fetchCategory = async () => {
            try {
                setLoading(true);
                const token = localStorage.getItem('token');
                const response = await axios.get(`${apiUrl}/admin/category/get_all_category`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                });
                setCategories(response.data.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching shops:', error);
                setLoading(false);
            } finally {
                setLoading(false);
            }
        };

        fetchCategory();
    }, [apiUrl]);

    return (
        <Box sx={{ display: "flex" }}>
            <ToastContainer />
            <Paper sx={{ width: "100%", overflow: "hidden", padding: "4px" }}>
                {loading && (
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "80vh" // Full height to center the loader
                        }}
                    >
                        <CircularProgress />
                    </Box>
                )}
                {!loading && (
                    <Grid>
                        <Box sx={{ display: "flex", alignItems: "center", marginBottom: 2 }}>
                            <Tooltip title="Return Back">
                                <ArrowBackRoundedIcon
                                    color="disabled"
                                    onClick={handleBackClick}
                                    sx={{ cursor: "pointer", marginRight: 1 }}
                                />
                            </Tooltip>
                            <Typography sx={{
                                marginRight: { xs: "0", sm: "10px", md: "700px" },
                                fontWeight: "bold",
                                whiteSpace: "nowrap",
                                flexGrow: 1,
                                textAlign: { xs: "center", md: "left" } // Center on smaller screens, left align on medium and larger screens
                            }} variant="h6">
                                Update Level
                            </Typography>
                        </Box>
                        <Divider sx={{ marginY: 2 }} />

                        <Grid container spacing={2} sx={{ marginBottom: 3, display: 'flex', alignItems: 'stretch' }}>
                            <Grid item xs={12} md={4.5} sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <InputLabel sx={{ fontSize: "1.2rem", fontWeight: "bold", paddingRight: '270px' }} shrink>
                                    Level Image :
                                </InputLabel>
                                <Card sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <CardContent>
                                        <Box sx={{ marginBottom: 1 }}>
                                            <img
                                                src={image}
                                                alt="Main_Image"
                                                style={{ borderRadius: "5%", width: 220, height: 150, objectFit: "cover", border: '2px dotted grey' }}
                                            />
                                        </Box>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                            <label htmlFor="profile-picture-upload">
                                                <input
                                                    style={{ display: 'none' }}
                                                    id="profile-picture-upload"
                                                    name="profile-picture-upload"
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={handleImageFileChange}
                                                />
                                                <Button
                                                    sx={{ backgroundColor: '#10d915', color: 'white', '&:hover': { backgroundColor: 'darkgrey' }, whiteSpace: 'nowrap' }}
                                                    variant="contained"
                                                    component="span"
                                                    startIcon={<CloudUploadTwoToneIcon />}
                                                >
                                                    Upload Image
                                                </Button>
                                            </label>
                                            {imageError && <Typography variant="body2" color="error">{imageError}</Typography>}
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} md={0.5} sx={{ display: 'flex', alignItems: 'stretch' }}>
                                <Divider orientation="vertical" variant="middle" flexItem />
                            </Grid>
                            <Grid item xs={12} md={7}>
                                <Grid container spacing={2} sx={{ marginBottom: 3, marginTop: 3 }}>
                                    <Grid item xs={12} md={12}>
                                        <InputLabel
                                            sx={{ fontSize: "1.2rem", fontWeight: "bold" }}
                                            shrink
                                        >
                                            Level Name :
                                        </InputLabel>
                                        <TextField
                                            placeholder="Enter Level Name"
                                            variant="outlined"
                                            type="text"
                                            fullWidth
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                            error={Boolean(nameError)}
                                            helperText={nameError}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} sx={{ marginBottom: 3 }}>
                                    <Grid item xs={12} md={12}>
                                        <InputLabel
                                            sx={{ fontSize: "1.2rem", fontWeight: "bold" }}
                                            shrink
                                        >
                                            Select Category :
                                        </InputLabel>
                                        <Autocomplete
                                            options={categories}
                                            getOptionLabel={(option) => option.name} // Display shop name
                                            getOptionSelected={(option, value) => option.id === value.id} // Manage the selected option
                                            loading={loading}
                                            value={categories.find(categories => categories.id === categoryName) || null} // Set the selected shop based on shop ID
                                            onChange={(event, newValue) => {
                                                // Store shop ID instead of shop name
                                                setCategoryName(newValue ? newValue.id : '');
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    placeholder="Choose Category Name"
                                                    variant="outlined"
                                                    fullWidth
                                                    error={Boolean(categoryNameError)}
                                                    helperText={categoryNameError}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Divider sx={{ marginY: 2 }} />
                        <Box sx={{ display: "flex", justifyContent: "flex-end", margin: 1 }}>
                            <Button
                                onClick={handleCancelClick}
                                color="error"
                                variant="contained"
                                startIcon={<CancelTwoToneIcon />}
                                sx={{ marginRight: 2, color: "white" }}
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={handleUpdate}
                                color="success"
                                variant="contained"
                                startIcon={<SendTwoToneIcon />}
                                disabled={loading}
                                sx={{ color: 'white' }}
                            >
                                {loading ? "Updating..." : "Update Level"}
                            </Button>
                        </Box>
                    </Grid>
                )}
            </Paper>
        </Box>
    );
};

export default UpdateLevelPage;
