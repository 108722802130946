import React, { useState } from 'react';
import axios from 'axios';
import Swal from "sweetalert2";
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Card, CardContent, Container, Typography, TextField, Button, Grid, Box } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import useMediaQuery from '@mui/material/useMediaQuery';
import 'react-toastify/dist/ReactToastify.css';
import "./css/LoginPage.css";

const theme = createTheme({
  palette: {
    primary: {
      main: "#9F63FF", // Your custom color
    },
  },
});

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [isLoading, setIsLoading] = useState(false); // State to track loading
  const isMobile = useMediaQuery("(max-width:600px)");
  const apiUrl = process.env.REACT_APP_API_URL;

  const navigate = useNavigate();
  const handleLogin = async (e) => {
    e.preventDefault();
    setEmailError('');
    setPasswordError('');
    setIsLoading(true); // Set loading to true on form submission

    try {
      const response = await axios.post(`${apiUrl}/portal/authenticate/login`, {
        email,
        password
      });

      toast.success(response.data.message);
      if (localStorage.getItem('auth_token') && localStorage.getItem('role_id') && localStorage.removeItem('user_name') && localStorage.removeItem('is_authenticate')) {
        // Remove the old values
        console.log("remove old data of auth");
        localStorage.removeItem('auth_token');
        localStorage.removeItem('role_id');
        localStorage.removeItem('user_name');
        localStorage.removeItem('is_authenticate');
      }
      localStorage.setItem('auth_token', response.data.auth_token);
      localStorage.setItem('role_id', response.data.role_id);
      localStorage.setItem('user_name', response.data.user_name);
      localStorage.setItem('is_authenticate', response.data.is_authenticate);

      if (localStorage.getItem('previous_page')) {
        navigate(localStorage.getItem('previous_page'));
      }else{
        navigate('/');
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        if (error.response.data.errors.email) {
          setEmailError(error.response.data.errors.email[0]);
        }
        if (error.response.data.errors.password) {
          setPasswordError(error.response.data.errors.password[0]);
        }
      } else {
        Swal.fire({
          icon: "warning",
          title: error.response.data.message,
          text: error.response.data.info,
          showConfirmButton: true,
          timer: 20000,
          confirmButtonColor: "#F56162",
          confirmButtonText: "OK",
        }).then(() => {
          window.location.href = "/login";
        });
      }
    } finally {
      setIsLoading(false); // Reset loading after request completes
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <ToastContainer />
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} order={isMobile ? 2 : 1}>
            <Card elevation={20} sx={{ maxWidth: '400px', width: '100%', borderRadius: 3 }}>
              <CardContent>
                <div>
                  <Typography variant="h5" gutterBottom>
                    Hello! Let's get started
                  </Typography>
                  <Typography variant="h6" gutterBottom>
                    <span style={{ textAlign: 'right' }}>Sign in to continue.</span>
                  </Typography>
                </div>
                <form onSubmit={handleLogin}>
                  <TextField
                    label="Email" // Label above the field
                    placeholder="Enter Email" // Placeholder inside the field
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    error={!!emailError}
                    helperText={emailError}
                    InputLabelProps={{
                      shrink: true, 
                    }}
                  />

                  <TextField
                    label="Password"
                    placeholder="Enter Password"
                    type="password"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    error={!!passwordError}
                    helperText={passwordError}
                    InputLabelProps={{
                      shrink: true, 
                    }}

                  />
                  {/* Disable button and show loader when isLoading is true */}
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    size="large"
                    style={{ marginTop: '1rem' }}
                    disabled={isLoading} // Disable button when loading
                  >
                    {isLoading ? 'Loading...' : 'Login'} {/* Change button text based on loading state */}
                  </Button>
                  <Typography variant="body1" align="center" style={{ marginTop: '1rem' }}>
                    <Link to="/forgotPassword">Forgot Password?</Link>
                  </Typography>
                  <Typography variant="body1" align="center">
                    Don't have an account? <Link to="/register">Sign Up</Link>
                  </Typography>
                </form>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6} order={isMobile ? 1 : 2}>
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
              <div className="half-circle-login" /> {/* Half-circle div */}
              <img src="/image/login.png" alt="login" style={{ maxWidth: '100%' }} />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
};

export default LoginPage;
