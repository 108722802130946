import React, { useState, useRef } from "react";
import { Box, TextField, Typography, Grid, Button, Stack, Container, } from "@mui/material";
import { PlayArrow, Code, Share } from "@mui/icons-material";
import beautify from "js-beautify";

const CssEditor = () => {
    const [htmlCode, setHtmlCode] = useState(`<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>CSS Editor</title>
</head>
<body>
  <h1>Hello, World!</h1>
  <p>Welcome to KnowledgeLift CSS Editor.</p>
</body>
</html>`);

    const [cssCode, setCssCode] = useState(`
h1 {
  color: #3498db;
  font-size: 36px;
  text-align: center;
}

p {
  color: #2ecc71;
  font-size: 18px;
  text-align: center;
  margin-top: 20px;
}`);

    const iframeRef = useRef(null);

    const handleRun = () => {
        if (iframeRef.current) {
            const iframeDocument = iframeRef.current.contentDocument || iframeRef.current.contentWindow.document;
            iframeDocument.open();
            iframeDocument.write(`
                ${htmlCode}
                <style>${cssCode}</style>
            `);
            iframeDocument.close();
        }
    };

    const handleBeautify = () => {
        const beautifiedCSS = beautify.css(cssCode, { indent_size: 2 });
        const beautifiedHTML = beautify.html(htmlCode, { indent_size: 2 });
        setCssCode(beautifiedCSS);
        setHtmlCode(beautifiedHTML);
    };

    const handleShare = () => {
        navigator.clipboard
            .writeText(`HTML:\n${htmlCode}\n\nCSS:\n${cssCode}`)
            .then(() => alert("HTML and CSS code copied to clipboard!"))
            .catch((err) => alert("Failed to copy code: " + err.message));
    };

    return (
        <Container maxWidth="xl">
            <Stack direction="row" spacing={2} sx={{ marginBottom: 2 }}>
                <Button
                    variant="contained"
                    color="success"
                    onClick={handleRun}
                    startIcon={<PlayArrow />}
                    sx={{ textTransform: "none", color: "white" }}
                >
                    Run
                </Button>

                <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleBeautify}
                    startIcon={<Code />}
                    sx={{ textTransform: "none" }}
                >
                    Beautify
                </Button>

                <Button
                    variant="contained"
                    color="warning"
                    onClick={handleShare}
                    startIcon={<Share />}
                    sx={{ textTransform: "none", color: "white" }}
                >
                    Share
                </Button>
            </Stack>
            <Grid container spacing={2} sx={{ height: "100%" }}>
                {/* HTML Code Input Area */}
                <Grid item xs={12} md={6}>
                    <Box
                        sx={{
                            height: "93%",
                            padding: 2,
                            bgcolor: "#282c34",
                            color: "#fff",
                            borderRadius: 2,
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <Typography variant="h6" sx={{ color: "lightgray", marginBottom: 1 }}>
                            HTML Code
                        </Typography>
                        <TextField
                            value={htmlCode}
                            onChange={(e) => setHtmlCode(e.target.value)}
                            variant="outlined"
                            multiline
                            rows={10}
                            fullWidth
                            sx={{
                                backgroundColor: "#1e1e1e",
                                color: "#fff",
                                flex: 1,
                                "& .MuiInputBase-input": { color: "white" },
                                "& .MuiOutlinedInput-root": {
                                    "& fieldset": {
                                        borderColor: "#4d4d4d",
                                    },
                                    "&:hover fieldset": {
                                        borderColor: "#fff",
                                    },
                                    "&.Mui-focused fieldset": {
                                        borderColor: "#fff",
                                    },
                                },
                            }}
                        />
                    </Box>
                </Grid>

                {/* CSS Code Input Area */}
                <Grid item xs={12} md={6}>
                    <Box
                        sx={{
                            height: "93%",
                            padding: 2,
                            bgcolor: "#282c34",
                            color: "#fff",
                            borderRadius: 2,
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <Typography variant="h6" sx={{ color: "lightgray", marginBottom: 1 }}>
                            CSS Code
                        </Typography>
                        <TextField
                            value={cssCode}
                            onChange={(e) => setCssCode(e.target.value)}
                            variant="outlined"
                            multiline
                            rows={10}
                            fullWidth
                            sx={{
                                backgroundColor: "#1e1e1e",
                                color: "#fff",
                                flex: 1,
                                "& .MuiInputBase-input": { color: "white" },
                                "& .MuiOutlinedInput-root": {
                                    "& fieldset": {
                                        borderColor: "#4d4d4d",
                                    },
                                    "&:hover fieldset": {
                                        borderColor: "#fff",
                                    },
                                    "&.Mui-focused fieldset": {
                                        borderColor: "#fff",
                                    },
                                },
                            }}
                        />
                    </Box>
                </Grid>

                {/* Rendered HTML + CSS Area */}
                <Grid item xs={12} md={12}>
                    <Box
                        sx={{
                            height: "93%",
                            padding: 2,
                            backgroundColor: "#f5f5f5",
                            borderRadius: 2,
                            overflowY: "hidden",
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <Typography variant="h6" sx={{ marginBottom: 1 }}>
                            Result
                        </Typography>
                        <iframe
                            ref={iframeRef}
                            title="Rendered HTML + CSS"
                            style={{
                                border: "none",
                                width: "100%",
                                height: "100%",
                                backgroundColor: "#fff",
                            }}
                        />
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
};

export default CssEditor;
