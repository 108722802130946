import React, { useState, useEffect } from 'react';
import { Grid, Typography, TextField, Button, Container } from '@mui/material';
import { Typewriter } from 'react-simple-typewriter';

const ShowFeaturePage = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [contentData, setContentData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [subscriptionMessage, setSubscriptionMessage] = useState(null);
  const [errorMessages, setErrorMessages] = useState([]);

  // Fetch content data
  useEffect(() => {
    const fetchContentData = async () => {
      try {
        const response = await fetch(`${apiUrl}/portal/landing-content`);
        const result = await response.json();
        if (result.status) {
          setContentData(result.data);
        }
      } catch (error) {
        console.error("Error fetching landing content:", error);
      }
    };
    fetchContentData();
  }, [apiUrl]);

  // Rotate content
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % contentData.length);
    }, 9000);
    return () => clearInterval(timer);
  }, [contentData.length]);

  // Auto-hide subscription message after 2 seconds
  useEffect(() => {
    if (subscriptionMessage) {
      const timer = setTimeout(() => {
        setSubscriptionMessage(null);
      }, 2000);

      return () => clearTimeout(timer); // Cleanup on unmount or when message changes
    }
  }, [subscriptionMessage]);

  const currentContent = contentData[currentIndex];

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  // Handle form submission
  const handleSubscribe = async () => {
    setIsSubmitting(true);
    setSubscriptionMessage(null);
    setErrorMessages([]);

    try {
      const response = await fetch(`${apiUrl}/portal/subscribe`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      const result = await response.json();

      if (response.ok) {
        setSubscriptionMessage(result.message);
        setEmail(''); // Reset the email field
      } else {
        setErrorMessages(result.errors?.email || ['An unexpected error occurred.']);
      }
    } catch (error) {
      console.error("Error subscribing:", error);
      setErrorMessages(['An unexpected error occurred. Please try again later.']);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Container maxWidth="xl" style={{ marginBottom: '3rem' }}>
      {currentContent && (
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={6} style={{ minHeight: '250px' }}>
            <Typography
              variant="h3"
              component="h1"
              fontWeight="bold"
              gutterBottom
              style={{ minHeight: '180px', maxHeight: '180px', overflow: 'hidden', alignItems: 'center' }}
            >
              <Typewriter
                key={currentIndex}
                words={[currentContent.title]}
                loop={false}
                cursor
                cursorStyle="|"
                typeSpeed={150}
                deleteSpeed={35}
                delaySpeed={2000}
              />
            </Typography>
            <Typography
              variant="body1"
              color="textSecondary"
              gutterBottom
              dangerouslySetInnerHTML={{ __html: currentContent.description }}
              style={{ minHeight: '100px', maxHeight: '100px', overflow: 'hidden' }}
            />
            <Typography variant="h6" component="p" fontWeight="bold" style={{ marginTop: '1rem' }}>
              Stay Updated with the Latest tutorials and updates
            </Typography>
            <Grid container spacing={1} alignItems="center" style={{ marginTop: '0.5rem' }}>
              <Grid item xs={8} sm={9}>
                <TextField
                  fullWidth
                  size="small"
                  placeholder="Enter your email address"
                  variant="outlined"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  error={!!errorMessages.length}
                  helperText={errorMessages.join(' ')}
                />
              </Grid>
              <Grid item xs={4} sm={3}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={handleSubscribe}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? 'Submitting...' : 'Subscribe'}
                </Button>
              </Grid>
            </Grid>
            {subscriptionMessage && (
              <Typography variant="body2" color="success.main" style={{ marginLeft: '1rem', marginTop: '0.5rem' }}>
                {subscriptionMessage}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} md={6} display="flex" justifyContent="center">
            <img
              src={currentContent.image}
              alt="Programming illustration"
              className={imageLoaded ? 'fade-in' : ''}
              style={{ maxWidth: '100%', height: 'auto' }}
              onLoad={handleImageLoad}
            />
          </Grid>
        </Grid>
      )}
    </Container>
  );
};

export default ShowFeaturePage;
