import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Chip from '@mui/material/Chip';
import Tooltip from "@mui/material/Tooltip";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import SendTwoToneIcon from "@mui/icons-material/SendTwoTone";
import CancelTwoToneIcon from "@mui/icons-material/CancelTwoTone";
import CloudUploadTwoToneIcon from "@mui/icons-material/CloudUploadTwoTone";
import InputLabel from "@mui/material/InputLabel";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from "sweetalert2";
import qs from "qs";
import { Autocomplete, CircularProgress } from "@mui/material";

const UpdateUserPage = () => {
    const navigate = useNavigate();
    const { userId } = useParams(); // Extract the userId from the route parameters
    const [user, setUser] = useState(null);
    const [name, setName] = useState("");
    const [roles, setRoles] = useState([]);
    const [roleName, setRoleName] = useState('');
    const [email, setEmail] = useState("");
    const [status, setStatus] = useState("");
    const [avatar, setAvatar] = useState("/avatar/no_avatar.png");
    const [avatarId, setAvatarId] = useState(null);
    const [selectedAvatarId, setSelectedAvatarId] = useState(null);

    const [nameError, setNameError] = useState('');
    const [roleNameError, setRoleNameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [statusError, setStatusError] = useState('');
    const [avatarError, setAvatarError] = useState('');
    const [avatarFile, setAvatarFile] = useState(null);
    const apiUrl = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(false);
    const [avatarList, setAvatarList] = useState([]);
    const [scrollIndex, setScrollIndex] = useState(0);

    const handleBackClick = () => {
        navigate(-1);
    };

    const handleCancelClick = () => {
        navigate("/admin/user"); // Replace '/desired/path' with the path you want to navigate to on cancel
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const token = localStorage.getItem('token');
            if (!token) return;

            try {
                // Fetch user data and avatars in parallel
                const [userResponse, avatarsResponse] = await Promise.all([
                    axios.put(
                        `${apiUrl}/admin/user/find_user`,
                        qs.stringify({ user_id: userId }),
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                                'Content-Type': 'application/x-www-form-urlencoded',
                            },
                        }
                    ),
                    axios.get(`${apiUrl}/admin/avatars`, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }),
                ]);

                // Handle user data
                const userData = userResponse.data.data;
                setUser(userData.id);
                setName(userData.name);
                setRoleName(userData.role_id);
                setEmail(userData.email);
                setStatus(userData.status);
                setAvatarId(userData.avatar_id);
                setAvatar(userData.avatar?.path ?? "/avatar/no_avatar.png");

                // Handle avatars list
                const fetchedAvatars = avatarsResponse.data.map(avatar => ({
                    id: avatar.id,
                    path: avatar.path,
                }));
                setAvatarList(fetchedAvatars);
            } catch (error) {
                console.error("Error fetching data:", error);
                toast.error('Failed to fetch data. Please try again later.');
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [userId, apiUrl]);

    useEffect(() => {
        const fetchRoles = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get(`${apiUrl}/admin/role/get_all_role`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                });
                setRoles(response.data.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching role:', error);
                setLoading(false);
            }
        };

        fetchRoles();
    }, [apiUrl]);

    const scrollLeft = () => {
        setScrollIndex(prevIndex => Math.max(prevIndex - 1, 0));
    };

    const scrollRight = () => {
        setScrollIndex(prevIndex => Math.min(prevIndex + 1, avatarList.length - 1));
    };

    const handleUpdate = () => {
        setNameError('');
        setRoleNameError('');
        setEmailError('');
        setStatusError('');
        setAvatarError('');
        setLoading(true);

        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('user_id', user);
        formData.append('name', name);
        formData.append('role_id', roleName);
        formData.append('email', email);
        formData.append('status', status);

        if (selectedAvatarId) {
            formData.append('main_avatar', selectedAvatarId); // Pass selected avatar ID
        } else if (avatarFile) {
            formData.append('main_avatar', avatarFile); // Pass uploaded avatar file
        }
        else {
            formData.append('main_avatar', avatarId); // Keep the current avatar
        }

        axios.post(`${apiUrl}/admin/user/update_user`, formData, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then(response => {
                const userData = response.data;
                //console.log(userData);
                toast.success(userData.message);
                setLoading(false);
                navigate('/admin/user'); // Adjust the path to where you want to navigate after success
            })
            .catch(error => {
                setLoading(false);
                if (error.response) {
                    if (error.response.data && error.response.data.errors) {
                        if (error.response.data.errors.name) {
                            setNameError(error.response.data.errors.name[0]);
                        }
                        if (error.response.data.errors.role_id) {
                            setRoleNameError(error.response.data.errors.role_id[0]);
                        }
                        if (error.response.data.errors.email) {
                            setEmailError(error.response.data.errors.email[0]);
                        }
                        if (error.response.data.errors.status) {
                            setStatusError(error.response.data.errors.status[0]);
                        }
                        if (error.response.data.errors.main_avatar) {
                            setAvatarError(error.response.data.errors.main_avatar[0]);
                        }
                    } else if (error.response.data.message) {
                        Swal.fire("Error!", error.response.data.message, "error");
                    }
                } else {
                    toast.error('Server error or network issue. Please try again later.');
                }
            });
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (file.type.startsWith('image/')) {
                setAvatar(URL.createObjectURL(file));
                setAvatarFile(file);
                setSelectedAvatarId(null);
                setAvatarError('');
            } else {
                setAvatarError('The file must be an image.');
            }
        }
    };

    const handleAvatarSelect = (avatarUrl, avatarId) => {
        setAvatar(avatarUrl);
        setSelectedAvatarId(avatarId);
        setAvatarFile(null); // Reset uploaded file when selecting an avatar
    };

    useEffect(() => {
        return () => {
            if (avatar.startsWith('blob:')) {
                URL.revokeObjectURL(avatar); // Clean up URL.createObjectURL()
            }
        };
    }, [avatar]);

    return (
        <Box sx={{ display: "flex" }}>
            <ToastContainer />
            {loading && (
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "80vh" // Full height to center the loader
                    }}
                >
                    <CircularProgress />
                </Box>
            )}
            {!loading && (
                <Paper sx={{ width: "100%", overflow: "hidden", padding: "4px" }}>
                    <Box sx={{ display: "flex", alignItems: "center", marginBottom: 2 }}>
                        <Tooltip title="Return Back">
                            <ArrowBackRoundedIcon
                                color="disabled"
                                onClick={handleBackClick}
                                sx={{ cursor: "pointer", marginRight: 1 }}
                            />
                        </Tooltip>
                        <Typography sx={{
                            marginRight: { xs: "0", sm: "10px", md: "700px" },
                            fontWeight: "bold",
                            whiteSpace: "nowrap",
                            flexGrow: 1,
                            textAlign: { xs: "center", md: "left" } // Center on smaller screens, left align on medium and larger screens
                        }} variant="h6">
                            Update User
                        </Typography>
                    </Box>
                    <Divider sx={{ marginY: 1 }} />

                    <Grid container spacing={2} sx={{ marginBottom: 1 }}>
                        <Grid item xs={12} md={3} sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                            <img src={avatar} alt="Avatar" style={{ borderRadius: "50%", width: 150, height: 150, objectFit: "cover", border: '1px solid grey' }} />
                            <label htmlFor="avatar-upload" style={{ marginTop: 10 }}>
                                <input
                                    style={{ display: 'none' }}
                                    id="avatar-upload"
                                    name="avatar-upload"
                                    type="file"
                                    accept="image/*"
                                    onChange={handleFileChange}
                                />
                                <Button
                                    sx={{ marginTop: 0.5, backgroundColor: '#10d915', color: 'white', '&:hover': { backgroundColor: '#10d915' } }}
                                    variant="contained"
                                    component="span"
                                    startIcon={<CloudUploadTwoToneIcon />}
                                >
                                    Upload Avatar
                                </Button>
                            </label>
                            {avatarError && <Typography variant="body2" color="error">{avatarError}</Typography>}
                            {/* New Box to contain the Divider and Chip */}
                            <Box sx={{ display: 'flex', alignItems: 'center', marginY: 1 }}>
                                <Divider sx={{ flexGrow: 1 }} />
                                <Chip label="------ OR Choose Avatar ------" size="small" sx={{ margin: "0 5px" }} />
                                <Divider sx={{ flexGrow: 1 }} />
                            </Box>
                            <Box sx={{ display: 'flex', overflowX: 'auto', paddingX: 2 }}>
                                <Button onClick={scrollLeft} sx={{ marginRight: 0.5, borderRadius: "50%" }}>
                                    <ArrowBackRoundedIcon />
                                </Button>
                                {avatarList.slice(scrollIndex, scrollIndex + 3).map((avatar, index) => (
                                    <img key={index} src={avatar.path} alt="Avatar" style={{ borderRadius: "50%", width: 60, height: 60, objectFit: "cover", border: '1px solid grey', marginRight: 2 }} onClick={() => handleAvatarSelect(avatar.path, avatar.id)} />
                                ))}
                                <Button onClick={scrollRight} sx={{ marginLeft: 0.5, borderRadius: "50%" }}>
                                    <ArrowBackRoundedIcon sx={{ transform: 'rotate(180deg)' }} />
                                </Button>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={9}>
                            <Grid container spacing={2} sx={{ marginBottom: 3 }}>
                                <Grid item xs={12} md={6}>
                                    <InputLabel
                                        sx={{ fontSize: "1.2rem", fontWeight: "bold" }}
                                        shrink
                                    >
                                        Name :
                                    </InputLabel>
                                    <TextField
                                        placeholder="Enter User Name"
                                        variant="outlined"
                                        type="text"
                                        fullWidth
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        error={!!nameError}
                                        helperText={nameError}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <InputLabel
                                        sx={{ fontSize: "1.2rem", fontWeight: "bold" }}
                                        shrink
                                    >
                                        Email :
                                    </InputLabel>
                                    <TextField
                                        placeholder="Enter Email Address"
                                        variant="outlined"
                                        type="email"
                                        fullWidth
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        error={!!emailError}
                                        helperText={emailError}
                                    />
                                </Grid>
                            </Grid>

                            {/* <Grid container spacing={2} sx={{ marginBottom: 3 }}>  
                            </Grid> */}
                            <Grid container spacing={2} sx={{ marginBottom: 3 }}>
                                <Grid item xs={12} md={6}>
                                    <InputLabel
                                        sx={{ fontSize: "1.2rem", fontWeight: "bold" }}
                                        shrink
                                    >
                                        Select Role :
                                    </InputLabel>
                                    <Autocomplete
                                        options={roles}
                                        getOptionLabel={(option) => option.name} // Display shop name
                                        getOptionSelected={(option, value) => option.id === value.id} // Manage the selected option
                                        loading={loading}
                                        value={roles.find(roles => roles.id === roleName) || null} // Set the selected shop based on shop ID
                                        onChange={(event, newValue) => {
                                            // Store shop ID instead of shop name
                                            setRoleName(newValue ? newValue.id : '');
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                placeholder="Choose Role Name"
                                                variant="outlined"
                                                fullWidth
                                                error={Boolean(roleNameError)}
                                                helperText={roleNameError}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <InputLabel
                                        sx={{ fontSize: "1.2rem", fontWeight: "bold" }}
                                        shrink
                                    >
                                        Status :
                                    </InputLabel>
                                    <TextField
                                        select
                                        placeholder="Select Status"
                                        variant="outlined"
                                        fullWidth
                                        value={status}
                                        onChange={(e) => setStatus(e.target.value)}
                                        error={!!statusError}
                                        helperText={statusError}
                                        SelectProps={{
                                            native: true,
                                        }}
                                    >
                                        <option selected value={1}>Active</option>
                                        <option value={2}>Pending</option>
                                        <option value={0}>Inactive</option>
                                    </TextField>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>

                    <Divider sx={{ marginY: 2 }} />
                    <Box sx={{ display: "flex", justifyContent: "flex-end", margin: 2 }}>
                        <Button
                            onClick={handleCancelClick}
                            color="error"
                            variant="contained"
                            startIcon={<CancelTwoToneIcon />}
                            sx={{ marginRight: 2, color: "white" }}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={handleUpdate}
                            color="success"
                            variant="contained"
                            startIcon={<SendTwoToneIcon />}
                            disabled={loading}
                            sx={{ color: "white" }}
                        >
                            {loading ? "Updating..." : "Update User"}
                        </Button>
                    </Box>
                </Paper>
            )}
        </Box>
    );
};

export default UpdateUserPage;
