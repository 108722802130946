import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import CloudUploadTwoToneIcon from "@mui/icons-material/CloudUploadTwoTone";
import SendTwoToneIcon from "@mui/icons-material/SendTwoTone";
import CancelTwoToneIcon from "@mui/icons-material/CancelTwoTone";
import InputLabel from "@mui/material/InputLabel";
import { useNavigate } from "react-router-dom";
import { Autocomplete, Card, CardContent } from '@mui/material';
import axios from "axios";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import qs from "qs";

const UpdateQuestionPage = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();
    const { questionId } = useParams();
    const [question, setQuestion] = useState(null);
    const [image, setImage] = useState("/image/no_image.jpg");
    const [soundPreview, setSoundPreview] = useState("");
    const [soundFile, setSoundFile] = useState(null);
    const [imageFile, setImageFile] = useState(null);
    const [quizzes, setQuizzes] = useState([]);
    const [quizTitle, setQuizTitle] = useState('');
    const [difficulty, setDifficulty] = useState("beginner");
    const [timeLimit, setTimeLimit] = useState('');
    const [questionOnText, setQuestionOnText] = useState('');
    const [imageError, setImageError] = useState('');
    const [soundError, setSoundError] = useState('');
    const [quizTitleError, setQuizTitleError] = useState('');
    const [difficultyError, setDifficultyError] = useState("");
    const [timeLimitError, setTimeLimitError] = useState('');
    const [questionOnTextError, setQuestionOnTextError] = useState('');
    const [loading, setLoading] = useState(false);

    const handleBackClick = () => {
        navigate(-1);
    };

    const handleCancelClick = () => {
        navigate("/admin/question");
    };

    useEffect(() => {
        // Fetch user data based on courseId
        const token = localStorage.getItem('token');
        setLoading(true);
        axios.put(
            `${apiUrl}/admin/question/find_question`,
            qs.stringify({ question_id: questionId }), // Serialize data to x-www-form-urlencoded format
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/x-www-form-urlencoded', // Set the content type to x-www-form-urlencoded
                },
            }
        )
            .then((response) => {
                const questionData = response.data.data;
                setQuestion(questionData.id);
                setQuizTitle(questionData.quiz_id);
                setDifficulty(questionData.difficulty);
                setTimeLimit(questionData.time_limit);
                setQuestionOnText(questionData.question_text);
                setImage(questionData.question_image ?? "/image/no_image.jpg");
                setSoundPreview(questionData.question_sound ?? "");
            })
            .catch((error) => {
                console.error("Error fetching user data:", error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [questionId, apiUrl]);

    const handleUpdate = () => {
        setQuizTitleError('');
        setDifficultyError('');
        setTimeLimitError('');
        setQuestionOnTextError('');
        setLoading(true);

        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('question_id', question);
        formData.append('quiz', quizTitle);
        formData.append('difficulty', difficulty);
        formData.append('time_limit', timeLimit);
        formData.append('question_text', questionOnText);
        formData.append('question_image', image ?? null);

        if (imageFile) {
            formData.append('question_image_file', imageFile);
        }

        if (soundFile) {
            formData.append('question_sound_file', soundFile);
        }

        axios.post(`${apiUrl}/admin/question/update_question`, formData, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
            },
        })
            .then(response => {
                const questionData = response.data;
                //console.log(userData);
                toast.success(questionData.message);
                setLoading(false);
                navigate('/admin/question'); // Adjust the path to where you want to navigate after success
            })
            .catch(error => {
                setLoading(false);
                if (error.response) {
                    if (error.response.data && error.response.data.errors) {
                        if (error.response.data.errors.quiz) {
                            setQuizTitleError(error.response.data.errors.quiz[0]);
                        }
                        if (error.response.data.errors.difficulty) {
                            setDifficultyError(error.response.data.errors.difficulty[0]);
                        }
                        if (error.response.data.errors.time_limit) {
                            setTimeLimitError(error.response.data.errors.time_limit[0]);
                        }
                        if (error.response.data.errors.question_text) {
                            setQuestionOnTextError(error.response.data.errors.question_text[0]);
                        }
                    } else if (error.response.data.message) {
                        toast.error(error.response.data.message);
                    }
                } else {
                    toast.error('Server error or network issue. Please try again later.');
                }
            });
    };

    const handleSoundFileChange = (event) => {
        const file = event.target.files[0];
        const validTypes = ['audio/mp3', 'audio/wav', 'audio/mpeg'];

        if (file) {
            if (validTypes.includes(file.type)) {
                setSoundFile(file);
                setSoundPreview(URL.createObjectURL(file));
                setSoundError('');
            } else {
                setSoundError('Invalid file type. Please upload an MP3 or WAV file.');
                setSoundFile(null);
                setSoundPreview("");
            }
        } else {
            setSoundError('No sound file selected.');
            setSoundFile(null);
            setSoundPreview("");
        }
    };

    const handleImageFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (file.type.startsWith('image/')) {
                setImage(URL.createObjectURL(file));
                setImageFile(file);
                setImageError('');
            } else {
                setImageError('The file must be an image.');
            }
        }
    };

    useEffect(() => {
        return () => {
            if (image.startsWith('blob:')) {
                URL.revokeObjectURL(image); // Clean up URL.createObjectURL()
            }
        };
    }, [image]);

    useEffect(() => {
        const fetchQuiz = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get(`${apiUrl}/admin/quiz/get_all_quiz`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                });
                setQuizzes(response.data.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching category:', error);
                setLoading(false);
            }
        };

        fetchQuiz();
    }, [apiUrl]);

    return (
        <Box sx={{ display: "flex" }}>
            <ToastContainer />
            <Paper sx={{ width: "100%", overflow: "hidden", padding: "4px" }}>
                <Grid>
                    <Box sx={{ display: "flex", alignItems: "center", marginBottom: 2 }}>
                        <Tooltip title="Return Back">
                            <ArrowBackRoundedIcon
                                color="disabled"
                                onClick={handleBackClick}
                                sx={{ cursor: "pointer", marginRight: 1 }}
                            />
                        </Tooltip>
                        <Typography sx={{
                            marginRight: { xs: "0", sm: "10px", md: "700px" },
                            fontWeight: "bold",
                            whiteSpace: "nowrap",
                            flexGrow: 1,
                            textAlign: { xs: "center", md: "left" } // Center on smaller screens, left align on medium and larger screens
                        }} variant="h6">
                            Add Question
                        </Typography>
                    </Box>
                    <Divider sx={{ marginY: 2 }} />

                    <Grid container spacing={2} sx={{ marginBottom: 3, display: 'flex', alignItems: 'stretch' }}>
                        <Grid item xs={12} md={4.5} sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <Grid container spacing={2} sx={{ marginBottom: 3 }}>
                                
                                <Grid item xs={12} md={12}>
                                    <InputLabel
                                        sx={{ fontSize: "1.2rem", fontWeight: "bold" }}
                                        shrink
                                    >
                                        Select Quiz Title :
                                    </InputLabel>
                                    <Autocomplete
                                        options={quizzes}
                                        getOptionLabel={(option) => option.title} // Display shop name
                                        getOptionSelected={(option, value) => option.id === value.id} // Manage the selected option
                                        loading={loading}
                                        value={quizzes.find(quizzes => quizzes.id === quizTitle) || null} // Set the selected shop based on shop ID
                                        onChange={(event, newValue) => {
                                            // Store shop ID instead of shop name
                                            setQuizTitle(newValue ? newValue.id : '');
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                placeholder="Choose Quiz Title"
                                                variant="outlined"
                                                fullWidth
                                                error={Boolean(quizTitleError)}
                                                helperText={quizTitleError}
                                            />
                                        )}
                                    />
                                </Grid>

                                <Grid item xs={12} md={12}>
                                <InputLabel
                                    sx={{ fontSize: "1.2rem", fontWeight: "bold" }}
                                    shrink
                                >
                                    Difficulty :
                                </InputLabel>
                                <TextField
                                    select
                                    placeholder="Choose Difficulty"
                                    variant="outlined"
                                    fullWidth
                                    value={difficulty}
                                    onChange={(e) => setDifficulty(e.target.value)}
                                    error={!!difficultyError}
                                    helperText={difficultyError}
                                    SelectProps={{
                                        native: true,
                                    }}
                                >
                                    <option selected value={'beginner'}>Beginner</option>
                                    <option value={'intermediate'}>Intermediate</option>
                                    <option value={'advance'}>Advance</option>
                                </TextField>
                                </Grid>

                                <Grid item xs={12} md={12}>
                                <InputLabel
                                    sx={{ fontSize: "1.2rem", fontWeight: "bold" }}
                                    shrink
                                >
                                    Time Limit :
                                </InputLabel>
                                <TextField
                                    placeholder="Enter Time Limit"
                                    variant="outlined"
                                    fullWidth
                                    value={timeLimit}
                                    onChange={(e) => setTimeLimit(e.target.value)}
                                    error={!!timeLimitError}
                                    helperText={timeLimitError}
                                />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={0.5} sx={{ display: 'flex', alignItems: 'stretch' }}>
                            <Divider orientation="vertical" variant="middle" flexItem />
                        </Grid>
                        <Grid item xs={12} md={7}>
                            {/* Question On Text Section */}
                            <Grid container spacing={2} sx={{ marginBottom: 3 }}>
                                <Grid item xs={12}>
                                    <InputLabel
                                        sx={{ fontSize: "1.2rem", fontWeight: "bold" }}
                                        shrink
                                    >
                                        Question On Text:
                                    </InputLabel>
                                    {questionOnTextError && (
                                        <Typography color="error" variant="caption">
                                            {questionOnTextError}
                                        </Typography>
                                    )}
                                    <ReactQuill
                                        value={questionOnText}
                                        onChange={setQuestionOnText}
                                        modules={{
                                            toolbar: [
                                                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                                                [{ 'font': [] }],
                                                [{ 'size': ['small', false, 'large', 'huge'] }],
                                                ['bold', 'italic', 'underline', 'strike'],
                                                [{ 'color': [] }, { 'background': [] }],
                                                [{ 'script': 'sub' }, { 'script': 'super' }],
                                                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                                [{ 'indent': '-1' }, { 'indent': '+1' }],
                                                [{ 'align': [] }],
                                                ['link', 'image', 'video'],
                                                ['blockquote', 'code-block'],
                                                ['clean']
                                            ],
                                        }}
                                        placeholder="Please Provide question on text here"
                                        style={{ height: '120px' }}
                                    />
                                </Grid>
                            </Grid>

                            {/* Divider between Question On Text and the next sections */}
                            <Divider sx={{ marginY: 3 }} />

                            {/* Question On Image and Question On Sound Section */}
                            <Grid container spacing={4} sx={{marginTop:4}}>
                                <Grid item xs={12} md={6}>
                                    <InputLabel sx={{ fontSize: "1.2rem", fontWeight: "bold" }} shrink>
                                        Question On Image:
                                    </InputLabel>
                                    <Card elevation={5} sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <CardContent>
                                            <Box sx={{ marginBottom: 1 }}>
                                                <img
                                                    src={image}
                                                    alt="Main_Image"
                                                    style={{ borderRadius: "5%", width: 150, height: 100, objectFit: "cover", border: '1.5px dotted grey' }}
                                                />
                                            </Box>
                                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                <label htmlFor="profile-picture-upload">
                                                    <input
                                                        style={{ display: 'none' }}
                                                        id="profile-picture-upload"
                                                        name="profile-picture-upload"
                                                        type="file"
                                                        accept="image/*"
                                                        onChange={handleImageFileChange}
                                                    />
                                                    <Button
                                                        sx={{ backgroundColor: '#10d915', color: 'white', '&:hover': { backgroundColor: 'darkgrey' }, whiteSpace: 'nowrap' }}
                                                        variant="contained"
                                                        component="span"
                                                        startIcon={<CloudUploadTwoToneIcon />}
                                                    >
                                                        Upload Image
                                                    </Button>
                                                </label>
                                                {imageError && <Typography variant="body2" color="error">{imageError}</Typography>}
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <InputLabel sx={{ fontSize: "1.2rem", fontWeight: "bold" }} shrink>
                                        Question On Sound:
                                    </InputLabel>
                                    <Card elevation={5} sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <CardContent>
                                            <Typography sx={{marginBottom:2}} variant="body2">Upload your sound file here.</Typography>
                                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                <label htmlFor="sound-upload">
                                                    <input
                                                        style={{ display: 'none' }}
                                                        id="sound-upload"
                                                        name="sound-upload"
                                                        type="file"
                                                        accept="audio/*"
                                                        onChange={handleSoundFileChange}
                                                    />
                                                    <Button
                                                        sx={{ backgroundColor: '#10d915', color: 'white', '&:hover': { backgroundColor: 'darkgrey' }, whiteSpace: 'nowrap',marginBottom: 3 }}
                                                        variant="contained"
                                                        component="span"
                                                        startIcon={<CloudUploadTwoToneIcon />}
                                                    >
                                                        Upload Sound
                                                    </Button>
                                                </label>
                                                {soundError && <Typography variant="body2" color="error">{soundError}</Typography>}
                                                {soundPreview && (
                                                    <audio controls style={{ marginTop: 3 }}>
                                                        <source src={soundPreview} type="audio/mpeg" />
                                                        Your browser does not support the audio element.
                                                    </audio>
                                                )}
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Divider sx={{ marginY: 2 }} />
                    <Box sx={{ display: "flex", justifyContent: "flex-end", margin: 1 }}>
                        <Button
                            onClick={handleCancelClick}
                            color="error"
                            variant="contained"
                            startIcon={<CancelTwoToneIcon />}
                            sx={{ marginRight: 2, color: "white" }}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={handleUpdate}
                            color="success"
                            variant="contained"
                            startIcon={<SendTwoToneIcon />}
                            disabled={loading}
                            sx={{ color: 'white' }}
                        >
                            {loading ? "Updating..." : "Update Question"}
                        </Button>
                    </Box>
                </Grid>
            </Paper>
        </Box>
    );
};

export default UpdateQuestionPage;
