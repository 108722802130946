import React, { useEffect, useState } from 'react';
import { Grid, Typography, Button, Container, Paper, Avatar } from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useNavigate } from 'react-router-dom'; 

const ProgrammingLanguagePage = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [tutorials, setTutorials] = useState([]);
  const [fetchAll, setFetchAll] = useState(false); 
  const navigate = useNavigate(); 

  // Fetch data from the API
  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = `${apiUrl}/portal/programming-language${fetchAll ? '?all_data=true' : ''}`;
        const response = await fetch(url);
        const result = await response.json();
        if (result.status) {
          setTutorials(fetchAll ? result.data : result.data.slice(0, 8)); // Show all or limit to 8 items
        } else {
          console.error(result.message);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [apiUrl, fetchAll]);

  // Button click handler to toggle data display
  const handleToggleFetchAll = () => {
    setFetchAll(!fetchAll); // Toggle between all data and limited data
  };

  // Handle Paper click to navigate to the specific subject page
  const handlePaperClick = (id) => {
    navigate(`/portal/subject/${id}`); // Navigate to the subject page with the specific id
  };

  return (
    <Container maxWidth="xl" style={{ marginTop: '3rem' }}>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12} md={3}>
          <Typography variant="h4" fontWeight="bold" gutterBottom>
            Choose what to learn
          </Typography>
          <Typography variant="body1" color="textSecondary" gutterBottom>
            Begin mastering top programming languages, frameworks, UI technologies, and AI-driven mobile app development to become a skilled Full Stack Developer.
          </Typography>
        </Grid>
        <Grid item xs={12} md={9}>
          <Grid container spacing={2}>
            {tutorials.map((item) => (
              <Grid item xs={6} md={4} key={item.id}>
                <Paper
                  elevation={5}
                  style={{
                    padding: '1rem',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '1rem',
                    cursor: 'pointer',
                    width: 'calc(100% - 30px)',
                    transition: 'transform 0.3s ease, box-shadow 0.3s ease', 
                  }}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.transform = 'translateY(-5px)'; 
                    e.currentTarget.style.boxShadow = '0px 6px 12px rgba(0, 0, 0, 0.3)'; 
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.transform = 'translateY(0)';
                    e.currentTarget.style.boxShadow = '0px 4px 10px rgba(0, 0, 0, 0.2)'; 
                  }}
                  onClick={() => handlePaperClick(item.id)} // Navigate to the specific subject page on click
                >
                  <Avatar
                    src={item.image || 'default-image.png'}
                    alt={item.name}
                    style={{ width: 45, height: 45, borderRadius: 2 }}
                  />
                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    color="primary"
                    noWrap
                    style={{
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {item.name}
                  </Typography>
                </Paper>
              </Grid>
            ))}
            <Grid item xs={6} md={4}>
              <Button
                fullWidth
                variant="outlined"
                color="primary"
                onClick={handleToggleFetchAll}
                style={{
                  height: '100%',
                  fontWeight: 'bold',
                  display: 'flex',
                  alignItems: 'center',
                  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
                  transition: 'box-shadow 0.3s ease',
                }}
              >
                <Avatar
                  alt="Tutorial Icon"
                  src="image/portal/language/more.gif"
                  style={{ marginRight: '1rem', width: 45, height: 45 }}
                />
                {fetchAll ? 'Show less' : 'View all tutorials'}
                {fetchAll ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ProgrammingLanguagePage;
