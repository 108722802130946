import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Grid, Typography, TextField, Button, Container, Avatar, Card,
  CardContent, CardActions, Autocomplete, Dialog, DialogContent,
  Divider
} from '@mui/material';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import axios from 'axios';
import Swal from "sweetalert2";

const quizCategories = [
  { title: 'Beginner Quizzes', description: 'Test your basic knowledge and understanding of topics.', icon: <EmojiObjectsIcon fontSize="large" />, level: 'Beginner', difficulty: 'beginner' },
  { title: 'Intermediate Quizzes', description: 'Challenge yourself with more in-depth questions.', icon: <EmojiObjectsIcon fontSize="large" />, level: 'Intermediate', difficulty: 'intermediate' },
  { title: 'Advanced Quizzes', description: 'Push your knowledge to the limit with tough questions.', icon: <EmojiObjectsIcon fontSize="large" />, level: 'Advanced', difficulty: 'advance' },
];

const ProvideQuizPage = () => {
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL || 'http://127.0.0.1:8000/api';
  const [imageLoaded, setImageLoaded] = useState(false);
  const [classOptions, setClassOptions] = useState([]);
  const [selectedClass, setSelectedClass] = useState('');
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [subjects, setSubjects] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [quizData, setQuizData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    axios.get(`${apiUrl}/portal/fetch_all_level`)
      .then((response) => {
        if (response.data.status) {
          const levels = response.data.data.map((level) => ({
            id: level.id,
            name: level.name,
          }));
          setClassOptions(levels);
        }
      })
      .catch((error) => {
        console.error('Error fetching class levels:', error);
      });
  }, [apiUrl]);

  useEffect(() => {
    if (selectedClass) {
      setSubjects([]);
      axios.get(`${apiUrl}/portal/get_subjects_by_Level`, { params: { level_id: selectedClass } })
        .then((response) => {
          if (response.data.status) {
            setSubjects(response.data.data);
          }
        })
        .catch((error) => {
          console.error('Error fetching subjects:', error);
        });
    }
  }, [selectedClass, apiUrl]);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  const handleStartQuiz = (difficulty) => {
    if (!selectedClass || !selectedSubject) {
      alert('Please select both class and subject.');
      return;
    }

    setLoading(true);
    axios.post(`${apiUrl}/portal/fetch_quiz_by_request`, {
      level_id: selectedClass,
      subject_id: selectedSubject.id,
      difficulty,
    })
      .then((response) => {
        if (response.data.status) {
          setQuizData(response.data.data);
          setOpenModal(true);
        } else {
          Swal.fire({
            icon: 'warning',
            title: 'No Quizzes Found',
            text: 'No quizzes found. Please try another combination.',
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: 'warning',
          title: 'No Quizzes Found',
          text: error.response.data.message,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setQuizData(null);
    // Redirect to the quiz test page using quizData.id
    if (quizData && quizData.id) {
      if (localStorage.getItem('is_authenticate') === 'true') {
        navigate(`/portal/quiz-test/${quizData.id}`);
      } else {
        localStorage.setItem('previous_page', `/portal/quiz-test/${quizData.id}`);
        navigate('/login');
      }
    }
  };

  return (
    <Container maxWidth="xl" style={{ marginTop: '4rem' }}>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12}>
          <Typography variant="h4" fontWeight="bold" textAlign="center" gutterBottom>
            Quizzes For All Levels of Learners
          </Typography>
          <Typography variant="subtitle1" color="textSecondary" textAlign="center" gutterBottom>
            Fun and engaging quizzes designed to test your knowledge, from beginner to advanced levels.
          </Typography>
        </Grid>
        <Grid item xs={12} md={3} display="flex" flexDirection="column" alignItems="center">
          <img
            src={'image/portal/quizz.png'}
            alt="Tutorials Segmented"
            className={imageLoaded ? 'fade-in' : ''}
            style={{ maxWidth: '100%', height: 'auto', marginBottom: '0.5rem' }}
            onLoad={handleImageLoad}
          />
          <Typography variant="h6" fontWeight="bold" align="center">
            Class and Topic-based Quizzes
          </Typography>
          <Typography variant="body2" color="textSecondary" align="center" style={{ marginTop: '0.5rem' }}>
            Explore quiz questions organized by class levels and specific topics to enhance your learning experience.
          </Typography>
        </Grid>
        <Grid item xs={12} md={9}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                options={classOptions}
                getOptionLabel={(option) => option.name}
                value={selectedClass ? classOptions.find(item => item.id === selectedClass) : null}
                onChange={(event, newValue) => {
                  setSelectedClass(newValue ? newValue.id : '');
                }}
                renderInput={(params) => <TextField {...params} label="Select Class" size="small" variant="outlined" />}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                options={subjects}
                getOptionLabel={(option) => option.name}
                value={selectedSubject}
                onChange={(event, newValue) => setSelectedSubject(newValue)}
                renderInput={(params) => <TextField {...params} label="Select Subject" size="small" variant="outlined" />}
              />
            </Grid>
            {quizCategories.map((quiz, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card elevation={5} style={{ height: '100%' }}>
                  <CardContent style={{ textAlign: 'center' }}>
                    <Avatar style={{ margin: '0 auto', backgroundColor: '#0f85d9', width: 60, height: 60 }}>
                      {quiz.icon}
                    </Avatar>
                    <Typography variant="h6" style={{ marginTop: '0.5rem', fontWeight: 'bold' }}>
                      {quiz.title}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" style={{ margin: '0.5rem 0' }}>
                      {quiz.description}
                    </Typography>
                    <Typography variant="body2" color="primary" style={{ marginBottom: '0.5rem', fontWeight: 'bold' }}>
                      Level: {quiz.level}
                    </Typography>
                  </CardContent>
                  <CardActions style={{ justifyContent: 'center' }}>
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={() => handleStartQuiz(quiz.difficulty)}
                      disabled={loading}
                    >
                      {loading ? 'Loading...' : 'Start Quiz'}
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      {/* Modal for Quiz Start */}
      <Dialog open={openModal} onClose={handleCloseModal} maxWidth="sm" fullWidth>
        <DialogContent
          style={{
            padding: 0,
            overflow: 'hidden', // Prevent overflow in the dialog content
          }}
        >
          {/* Header Image Section */}
          <div style={{ position: 'relative', width: '100%' }}>
            <img
              src={'/image/quiz_team.png'} // Replace with the correct image path
              alt="Quiz Header"
              style={{
                width: '100%',
                height: 'auto',
                borderTopLeftRadius: '4px',
                borderTopRightRadius: '4px',
              }}
            />
          </div>

          {/* Quiz Title Section */}
          <Typography
            variant="h6"
            style={{
              textAlign: 'center',
              fontWeight: 'bold',
              color: '#5c5b58',
              marginLeft: '1rem',
              marginRight: '1rem', // Add margin to prevent text from overflowing
              wordWrap: 'break-word', // Ensure long text wraps correctly
            }}
          >
            {`${quizData?.title} for ${quizData?.level?.name} Of ${quizData?.subject?.name}` || ''}
          </Typography>

          {/* Details Section */}
          <div
            style={{
              padding: '1rem',
              textAlign: 'center',
              boxSizing: 'border-box', // Ensure padding is included in width calculations
            }}
          >
            <Typography variant="subtitle1" style={{ fontWeight: 'bold', marginBottom: '0.5rem' }}>
              From: <span style={{ color: 'green' }}>{quizData?.created_at ? new Date(quizData.created_at).toLocaleDateString() : 'N/A'}</span>
            </Typography>

            <Grid container justifyContent="center" spacing={3}>
              <Grid item>
                <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                  10 {/* Replace with actual data if available in the API */}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Questions
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                  150 sec {/* Replace with actual data if available in the API */}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Duration
                </Typography>
              </Grid>
              <Grid item>
                <Divider orientation="vertical" flexItem style={{ backgroundColor: 'black', height: '40px' }} />
              </Grid>
              <Grid item>
                <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                  Difficulty
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {quizData?.difficulty || 'N/A'} {/* Dynamically display the difficulty */}
                </Typography>
              </Grid>
            </Grid>
          </div>

          {/* Play Button Section */}
          <div style={{ textAlign: 'center', marginBottom: '1rem' }}>
            <Button
              variant="contained"
              color="error"
              size="large"
              style={{
                fontWeight: 'bold',
                borderRadius: '20px',
                padding: '0.75rem 2rem',
                fontSize: '1rem',
              }}
              onClick={handleCloseModal} // Replace with your start quiz logic
            >
              Play
            </Button>
          </div>
        </DialogContent>
      </Dialog>

    </Container>
  );
};

export default ProvideQuizPage;
