import React, { useState } from "react";
import { Box, Button, Container, Grid, Stack } from "@mui/material";
import { FaHtml5, FaCss3Alt, FaPython, FaJava } from "react-icons/fa";
import { SiCloudera, SiCplusplus, SiCsharp } from "react-icons/si";
import { TbBrandJavascript } from "react-icons/tb";
import { FaReact } from "react-icons/fa";
import { FaGolang } from "react-icons/fa6";
import { IoLogoNodejs } from "react-icons/io";
import { SiPhp } from "react-icons/si";
import HtmlEditor from "./HtmlEditor";
import CssEditor from "./CssEditor";
import JavascriptEditor from "./JavascriptEditor";
import CCompiler from "./CCompiler";
import CppCompiler from "./CppCompiler";
import CSharpCompiler from "./CSharpCompiler";
import PythonCompiler from "./PythonCompiler";
import JavaCompiler from "./JavaCompiler";
import GoLangCompiler from "./GoLangCompiler";
import NodeJSCompiler from "./NodeJSCompiler";
import ReactCodeEditor from "./ReactCodeEditor";
import PHPCompiler from "./PHPCompiler";

const CompilerUIPage = () => {
  const [selectedLanguage, setSelectedLanguage] = useState("c");

  // Dynamically render the compiler component based on the selected language
  const renderCompiler = () => {
    switch (selectedLanguage) {
      case "html":
        return <HtmlEditor />;
      case "css":
        return <CssEditor />;
      case "javascript":
        return <JavascriptEditor />;
      case "c":
        return <CCompiler />;
      case "cpp":
        return <CppCompiler />;
      case "csharp":
        return <CSharpCompiler />;
      case "python":
        return <PythonCompiler />;
      case "java":
        return <JavaCompiler />;
      case "php":
          return <PHPCompiler />;
      case "golang":
        return <GoLangCompiler />;
      case "nodejs":
        return <NodeJSCompiler />;
      case "react":
        return <ReactCodeEditor />;
      default:
        return null;
    }
  };

  return (
    <Container maxWidth="xl">
      <Stack direction="row" spacing={1} sx={{ marginBottom: 2 }}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={1}>
            <Box display="flex" flexDirection="column" gap={1} mt={1}>
              <Button
                variant={selectedLanguage === "html" ? "contained" : "outlined"}
                color="primary"
                onClick={() => setSelectedLanguage("html")}
                sx={{
                  width: 60,
                  height: 60,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 1,
                }}
              >
                <FaHtml5 size={30} />
              </Button>
              <Button
                variant={selectedLanguage === "css" ? "contained" : "outlined"}
                color="primary"
                onClick={() => setSelectedLanguage("css")}
                sx={{
                  width: 60,
                  height: 60,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 1,
                }}
              >
                <FaCss3Alt size={30} />
              </Button>
              <Button
                variant={selectedLanguage === "javascript" ? "contained" : "outlined"}
                color="primary"
                onClick={() => setSelectedLanguage("javascript")}
                sx={{
                  width: 60,
                  height: 60,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 1,
                }}
              >
                <TbBrandJavascript size={30} />
              </Button>
              <Button
                variant={selectedLanguage === "c" ? "contained" : "outlined"}
                color="primary"
                onClick={() => setSelectedLanguage("c")}
                sx={{
                  width: 60,
                  height: 60,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 1,
                }}
              >
                <SiCloudera size={30} />
              </Button>
              <Button
                variant={selectedLanguage === "cpp" ? "contained" : "outlined"}
                color="primary"
                onClick={() => setSelectedLanguage("cpp")}
                sx={{
                  width: 60,
                  height: 60,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 1,
                }}
              >
                <SiCplusplus size={30} />
              </Button>
              <Button
                variant={selectedLanguage === "csharp" ? "contained" : "outlined"}
                color="primary"
                onClick={() => setSelectedLanguage("csharp")}
                sx={{
                  width: 60,
                  height: 60,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 1,
                }}
              >
                <SiCsharp size={30} />
              </Button>
              <Button
                variant={selectedLanguage === "python" ? "contained" : "outlined"}
                color="primary"
                onClick={() => setSelectedLanguage("python")}
                sx={{
                  width: 60,
                  height: 60,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 1,
                }}
              >
                <FaPython size={30} />
              </Button>
              <Button
                variant={selectedLanguage === "java" ? "contained" : "outlined"}
                color="primary"
                onClick={() => setSelectedLanguage("java")}
                sx={{
                  width: 60,
                  height: 60,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 1,
                }}
              >
                <FaJava size={30} />
              </Button>
              <Button
                variant={selectedLanguage === "php" ? "contained" : "outlined"}
                color="primary"
                onClick={() => setSelectedLanguage("php")}
                sx={{
                  width: 60,
                  height: 60,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 1,
                }}
              >
                <SiPhp size={30} />
              </Button>
              <Button
                variant={selectedLanguage === "golang" ? "contained" : "outlined"}
                color="primary"
                onClick={() => setSelectedLanguage("golang")}
                sx={{
                  width: 60,
                  height: 60,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 1,
                }}
              >
                <FaGolang size={30} />
              </Button>
              <Button
                variant={selectedLanguage === "nodejs" ? "contained" : "outlined"}
                color="primary"
                onClick={() => setSelectedLanguage("nodejs")}
                sx={{
                  width: 60,
                  height: 60,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 1,
                }}
              >
                <IoLogoNodejs size={30} />
              </Button>
              <Button
                variant={selectedLanguage === "react" ? "contained" : "outlined"}
                color="primary"
                onClick={() => setSelectedLanguage("react")}
                sx={{
                  width: 60,
                  height: 60,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 1,
                }}
              >
                <FaReact size={30} />
              </Button>
            </Box>
          </Grid>

          <Grid item xs={12} md={11}>
            {/* Render the compiler component dynamically */}
            {renderCompiler()}
          </Grid>
        </Grid>
      </Stack>
    </Container>
  );
};

export default CompilerUIPage;
