import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Layout from '../Theme/Layout';
import LandingPage from '../Home/LandingPage';
import NotFoundPage from './NotFoundPage';
import LoginPage from '../Authenticate/LoginPage';
import RegisterPage from '../Authenticate/RegisterPage';
import ForgotPasswordPage from '../Authenticate/ForgotPasswordPage';
import ResetPasswordPage from '../Authenticate/ResetPasswordPage';
import TopicDetailsPage from '../Topic/TopicDetailsPage';
import InterviewQuestionAnswerPage from '../Interview/InterviewQuestionAnswerPage';
import HtmlEditor from '../Compiler/HtmlEditor';
import CssEditor from '../Compiler/CssEditor';
import JavascriptEditor from '../Compiler/JavascriptEditor';
import CCompiler from '../Compiler/CCompiler';
import CppCompiler from '../Compiler/CppCompiler';
import CSharpCompiler from '../Compiler/CSharpCompiler';
import JavaCompiler from '../Compiler/JavaCompiler';
import PythonCompiler from '../Compiler/PythonCompiler';
import CodeExecutor from '../Compiler/CodeExecutor';
import QuizTestPage from '../Quiz/QuizTestPage';
import QuizResultPage from '../Quiz/QuizResultPage';
import WorkspacePage from '../Workspace/WorkspacePage';
import GetNotePage from '../Workspace/GetNotePage';
import CompilerUIPage from '../Compiler/CompilerUIPage';



const PortalRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<Layout><LandingPage /></Layout>} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/forgotPassword" element={<ForgotPasswordPage />} />
            <Route path="/reset_password" element={<ResetPasswordPage />} />
            <Route path="/portal/interview-question/:subjectId" element={<Layout><InterviewQuestionAnswerPage /></Layout>} />
            <Route path="/portal/subject/:subjectId/:topicId?" element={<Layout><TopicDetailsPage /></Layout>} />
            <Route path="/portal/html-editor" element={<Layout><HtmlEditor /></Layout>} />
            <Route path="/portal/css-editor" element={<Layout><CssEditor /></Layout>} />
            <Route path="/portal/javascript-editor" element={<Layout><JavascriptEditor /></Layout>} />
            <Route path="/portal/compiler" element={<Layout><CompilerUIPage /></Layout>} />
            <Route path="/portal/c-compiler" element={<Layout><CCompiler /></Layout>} />
            <Route path="/portal/cpp-compiler" element={<Layout><CppCompiler /></Layout>} />
            <Route path="/portal/csharp-compiler" element={<Layout><CSharpCompiler /></Layout>} />
            <Route path="/portal/java-compiler" element={<Layout><JavaCompiler /></Layout>} />
            <Route path="/portal/python-compiler" element={<Layout><PythonCompiler /></Layout>} />
            <Route path="/portal/code-executor" element={<Layout><CodeExecutor /></Layout>} />
            <Route path="/portal/quiz-test/:quizId" element={<Layout><QuizTestPage /></Layout>} />
            <Route path="/portal/quiz-result/:resultId" element={<Layout><QuizResultPage /></Layout>} />
            <Route path="/portal/work-space/:featureId" element={<Layout><WorkspacePage /></Layout>} />
            <Route path="/portal/note/:noteId" element={<Layout><GetNotePage /></Layout>} />
            <Route path="*" element={<NotFoundPage />} />
        </Routes>
    );
};

export default PortalRoutes;