import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import MenuItem from "@mui/material/MenuItem";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ExtensionOffTwoToneIcon from '@mui/icons-material/ExtensionOffTwoTone';
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import Tooltip from "@mui/material/Tooltip";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import qs from "qs";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Avatar from '@mui/material/Avatar';
import { CircularProgress, Grid } from "@mui/material";


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: "#efebf2",
  color: "black",
  fontSize: "14px",
  fontWeight: "bold", // Add this line to make text bold
  "&:first-of-type": {
    borderTopLeftRadius: "5px",
    borderBottomLeftRadius: "5px",
  },
  "&:last-of-type": {
    borderTopRightRadius: "5px",
    borderBottomRightRadius: "5px",
  },
}));

const SubjectPage = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortBy, setSortBy] = useState("id");
  const [rangeStart, setRangeStart] = useState(1);
  const [rangeEnd, setRangeEnd] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [loading, setLoading] = useState(false);

  // Function to fetch Subjects
  const fetchSubject = useCallback((page, perPage, sortOrder, sortBy) => {
    setLoading(true); // Set loading state to true when fetching data
    const token = localStorage.getItem("token");
    axios
      .get(
        `${apiUrl}/admin/subject/fetch_all_subject?page=${page}&per_page=${perPage}&sort_order=${sortOrder}&sort_by=${sortBy}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        const subjectData = response.data.data;
        setRows(subjectData.data);
        setRangeStart(subjectData.from);
        setRangeEnd(subjectData.to);
        setTotalRows(subjectData.total);
        setTotalPages(Math.ceil(subjectData.total / perPage));
      })
      .catch((error) => {
        if (error.response.data.message) {
          Swal.fire("Error!", error.response.data.message, "error");
        } else {
          toast.error("Failed to fetch Shops");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [apiUrl]);

  useEffect(() => {
    fetchSubject(currentPage, perPage, sortOrder, sortBy);
  }, [currentPage, perPage, sortOrder, sortBy, fetchSubject]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handlePerPageChange = (event) => {
    const perPageValue = event.target.value;
    setPerPage(perPageValue); // Update perPage state
    setCurrentPage(1);
    fetchSubject(1, perPageValue, sortOrder, sortBy);
  };

  const handleSortOrderChange = (event) => {
    const newSortOrder = event.target.value;
    setSortOrder(newSortOrder);
    fetchSubject(1, perPage, newSortOrder, sortBy); // Fetch subject with the updated sort order and sort by values
  };

  const handleSortByChange = (event) => {
    const newSortBy = event.target.value;
    setSortBy(newSortBy);
    fetchSubject(1, perPage, sortOrder, newSortBy); // Fetch subject with the updated sort by value
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleAddButtonClick = () => {
    navigate("/admin/add-subject"); // Navigate to '/add-shop' route when Add button is clicked
  };

  const handleEditButtonClick = (subjectId) => {
    navigate(`/admin/update-subject/${subjectId}`); // Navigate to UpdateShopPage with the shop ID
  };

  const handleDeleteButtonClick = (subjectId) => {
    // Show SweetAlert confirmation dialog
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this subject!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        // If confirmed, proceed with delete
        deleteSubject(subjectId);
      }
    });
  };

  // Function to call API for deleting shop
  const deleteSubject = (subjectId) => {
    const token = localStorage.getItem("token");

    axios({
      method: "delete",
      url: `${apiUrl}/admin/subject/delete_subject`,
      data: qs.stringify({ subject_id: subjectId }), // Serialize data to x-www-form-urlencoded format
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/x-www-form-urlencoded", // Set the content type to x-www-form-urlencoded
      },
    })
      .then((response) => {
        Swal.fire("Deleted!", response.data.message, "success");
        fetchSubject(currentPage, perPage, sortOrder, sortBy); // Refresh subject list
      })
      .catch((error) => {
        console.error("Error deleting subject:", error);
        Swal.fire(
          "Error!",
          "An error occurred while deleting the subject.",
          "error"
        );
      });
  };

  return (
    <Box sx={{ display: "flex" }}>
      <ToastContainer />
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        {loading && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "80vh" // Full height to center the loader
            }}
          >
            <CircularProgress />
          </Box>
        )}
        {!loading && (
          <Grid>
            <TableContainer sx={{ maxHeight: 460 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0.4,
                  marginTop: 0.5,
                }}
              >
                <Tooltip title="Return Back">
                  <ArrowBackRoundedIcon
                    color="disabled"
                    onClick={handleBackClick}
                  />
                </Tooltip>
                <Typography
                  sx={{
                    marginRight: { xs: "0", sm: "10px", md: "700px" },
                    fontWeight: "bold",
                    whiteSpace: "nowrap",
                    flexGrow: 1,
                    textAlign: { xs: "center", md: "left" } // Center on smaller screens, left align on medium and larger screens
                  }}
                  variant="h6"
                >
                  Subject List
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Tooltip title="Add New Subject">
                    <Button
                      variant="contained"
                      color="success"
                      size="large"
                      startIcon={<AddCircleOutlineIcon />}
                      onClick={handleAddButtonClick}
                      sx={{ color: "white" }}
                    >
                      Add
                    </Button>
                  </Tooltip>
                  <Box>
                    <TextField
                      id="outlined-basic"
                      label="Search"
                      size="small"
                      variant="outlined"
                      sx={{ marginLeft: "10px", marginRight: "5px" }}
                    />
                  </Box>
                </Box>
              </Box>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Subject Name</StyledTableCell>
                    <StyledTableCell align="center">Course Title</StyledTableCell>
                    <StyledTableCell align="center">Description</StyledTableCell>
                    <StyledTableCell align="center">Image</StyledTableCell>
                    <StyledTableCell align="center">Updated At</StyledTableCell>
                    <StyledTableCell align="center">Action</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>{row.name || "N/A"}</TableCell> {/* Update this line */}
                      <TableCell align="center">
                        <Tooltip title={row.course?.title || "Not Assign"}>
                          {row.course?.title
                            ? (row.course?.title.length > 20 ? `${row.course?.title.substring(0, 20)}...` : row.course.title)
                            : <ExtensionOffTwoToneIcon />}
                        </Tooltip>
                      </TableCell>
                      <TableCell align="center">
                        <Tooltip title={row.content || "Not Available"}>
                          {row.content ? (row.content.length > 20 ? `${row.content.substring(0, 20)}...` : row.content) : "-"}
                        </Tooltip>
                      </TableCell>
                      <TableCell align="center">
                        <Tooltip title="Image">
                          <Avatar src={row.image} alt="Image" sx={{ width: 55, height: 55, borderRadius: 2, marginLeft: 2 }} />
                        </Tooltip>
                      </TableCell>
                      <TableCell align="center">
                        {new Date(row.updated_at).toLocaleDateString(
                          "en-US",
                          {
                            day: "numeric",
                            month: "long",
                            year: "numeric",
                          }
                        )}{" "}
                        {new Date(row.updated_at).toLocaleTimeString(
                          "en-US",
                          {
                            hour: "2-digit",
                            minute: "2-digit",
                            second: "2-digit",
                            hour12: true, // Use 24-hour format
                          }
                        )}
                      </TableCell>
                      <TableCell align="center">
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "8px", // Add gap for spacing between buttons
                          }}
                        >
                          <Tooltip title="Edit Subject">
                            <Button
                              variant="outlined"
                              color="secondary"
                              size="small"
                              sx={{
                                borderRadius: "50%",
                                minWidth: "32px",
                                minHeight: "32px",
                                width: "32px",
                                height: "32px",
                                padding: 0, // Remove padding for better icon alignment
                              }}
                              onClick={() => handleEditButtonClick(row.id)}
                            >
                              <EditTwoToneIcon fontSize="small" />
                            </Button>
                          </Tooltip>
                          <Tooltip title="Delete Subject">
                            <Button
                              variant="outlined"
                              color="error"
                              size="small"
                              sx={{
                                borderRadius: "50%",
                                minWidth: "32px",
                                minHeight: "32px",
                                width: "32px",
                                height: "32px",
                                padding: 0, // Remove padding for better icon alignment
                              }}
                              onClick={() => handleDeleteButtonClick(row.id)}
                            >
                              <DeleteTwoToneIcon fontSize="small" />
                            </Button>
                          </Tooltip>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                paddingTop: 0.7,
                paddingBottom: 0.2,
                marginTop: "5px",
                marginBottom: "5px",
              }}
            >
              {/* Left side content */}
              <Box
                sx={{ display: "flex", alignItems: "center", marginLeft: "10px" }}
              >
                <React.Fragment>
                  <TextField
                    select
                    label="Sort Order"
                    value={sortOrder}
                    onChange={handleSortOrderChange}
                    sx={{ width: 100 }}
                    size="small"
                  >
                    {["desc", "asc"].map((option) => (
                      <MenuItem key={option} value={option}>
                        {option.toUpperCase()}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    select
                    label="Sort By"
                    value={sortBy}
                    onChange={handleSortByChange}
                    sx={{ marginLeft: "10px", width: 100 }}
                    size="small"
                  >
                    {["id", "created_at", "updated_at"].map((option) => (
                      <MenuItem key={option} value={option}>
                        {option.toUpperCase()}
                      </MenuItem>
                    ))}
                  </TextField>
                </React.Fragment>
              </Box>
              {/* Right side content */}
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <React.Fragment>
                  <Typography sx={{ marginRight: "10px" }} variant="body2">
                    Rows per page:{" "}
                  </Typography>
                  <TextField
                    select
                    value={perPage}
                    onChange={handlePerPageChange}
                    sx={{ marginRight: "10px" }}
                    size="small"
                  >
                    {[10, 20, 50].map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                  <Typography
                    sx={{ marginRight: "10px" }}
                    variant="body2"
                  >{`${rangeStart} - ${rangeEnd} of ${totalRows}`}</Typography>
                  <Pagination
                    sx={{ marginRight: "10px" }}
                    shape="rounded"
                    color="primary"
                    count={totalPages}
                    page={currentPage}
                    onChange={handlePageChange}
                    renderItem={(item) => (
                      <PaginationItem
                        component="button"
                        onClick={() => handlePageChange(null, item.page)}
                        {...item}
                      />
                    )}
                  />
                </React.Fragment>
              </Box>
            </Box>
          </Grid>
        )}
      </Paper>
    </Box>
  );
};

export default SubjectPage;
