import React, { useState } from 'react';
import axios from 'axios';

const CodeExecutor = () => {
  const [code, setCode] = useState('');
  const [language, setLanguage] = useState('py'); // Default to Python
  const [input, setInput] = useState('');
  const [output, setOutput] = useState('');
  const [error, setError] = useState('');

  const handleExecute = async () => {
    try {
      const response = await axios.post('https://api.codex.jaagrav.in', {
        code,
        language,
        input,
      }, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });
      setOutput(response.data.output);
      setError(response.data.error);
    } catch (err) {
      setError('An error occurred while executing the code.');
      console.error(err);
    }
  };

  return (
    <div>
      <h1>Code Executor</h1>
      <textarea
        rows="10"
        cols="50"
        value={code}
        onChange={(e) => setCode(e.target.value)}
        placeholder="Enter your code here"
      />
      <br />
      <select value={language} onChange={(e) => setLanguage(e.target.value)}>
        <option value="py">Python</option>
        <option value="java">Java</option>
        <option value="cpp">C++</option>
        <option value="c">C</option>
        <option value="js">NodeJS</option>
        <option value="go">GoLang</option>
        <option value="cs">C#</option>
      </select>
      <br />
      <input
        type="text"
        value={input}
        onChange={(e) => setInput(e.target.value)}
        placeholder="Input (if required)"
      />
      <br />
      <button onClick={handleExecute}>Execute</button>
      <h2>Output:</h2>
      <pre>{output}</pre>
      {error && <p style={{ color: 'red' }}>{error}</p>}
    </div>
  );
};

export default CodeExecutor;