import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import qs from "qs";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import SendTwoToneIcon from "@mui/icons-material/SendTwoTone";
import CancelTwoToneIcon from "@mui/icons-material/CancelTwoTone";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import { CircularProgress, Grid,} from "@mui/material";

const CompareActiveLogPage = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const { activeLogId } = useParams();
  const [ActiveLogAttributes, setActiveLogAttributes] = useState([]);
  const [ActiveLogOld, setActiveLogOld] = useState([]);
  const [ActiveLogData, setActiveLogData] = useState([]);
  const [logId, setLogId] = useState("");
  const [userData, setUserData] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const fetchActiveLog = () => {
      const token = localStorage.getItem("token");
      axios
        .put(
          `${apiUrl}/admin/active_log/find_active_log`,
          qs.stringify({ activelog_id: activeLogId }),
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((response) => {
          const activelogByIdData = response.data;
          setActiveLogAttributes(activelogByIdData.data.attributes);
          setActiveLogOld(activelogByIdData.data.old);
          setActiveLogData(activelogByIdData.data.logs.causer_id);
          setLogId(activelogByIdData.data.logs.id);
        })
        .catch((error) => {
          console.error("Error fetching user data:", error);
        }).finally(() => {
          setLoading(false);
        });
    };

    const fetchUser = () => {
      setLoading(true);
      const token = localStorage.getItem("token");
      axios
        .get(`${apiUrl}/admin/user/fetch_all_user`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          const userData = response.data.data.data.map((user) => ({
            label: user.name,
            value: user.id,
          }));
          setUserData(userData);
        })
        .catch((error) => {
          console.error("Error fetching user data:", error);
        }).finally(() => {
          setLoading(false);
        });
    };

    fetchActiveLog();
    fetchUser();
  }, [activeLogId, apiUrl]);

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleCancelClick = () => {
    navigate("/admin/active-log");
  };

  const handleUserSelect = (event, value) => {
    setSelectedUser(value);
  };

  const handleSubmit = () => {
    if (selectedUser) {
      Swal.fire({
        title: "Are you sure?",
        text: "This activelog will be assigned to the selected user. This action cannot be undone.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, assign it!",
        cancelButtonText: "No, cancel!",
      }).then((result) => {
        if (result.isConfirmed) {
          submitData();
        }
      });
    } else {
      console.error("Please select a user.");
    }
  };

  const submitData = () => {
    const token = localStorage.getItem("token");
    const formUpdateData = new FormData();
    formUpdateData.append("log_id", logId);
    formUpdateData.append("user_id", selectedUser.value); // Use selected user's ID

    setLoading(true);

    axios
      .post(
        `${apiUrl}/admin/active_log/update_active_log`,
        formUpdateData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        console.log("Response from server:", response.data);
        const subCategoryUpdateData = response.data;
        toast.success(subCategoryUpdateData.message);
        setLoading(false);
        navigate("/admin/active-log");
      })
      .catch((error) => {
        setLoading(false);
        if (
          error.response &&
          error.response.data &&
          error.response.data.errors
        ) {
        } else {
          console.error("Server error:", error.response.data.message);
        }
      });
  };

  return (
        <Box sx={{ display: "flex" }}>
          <ToastContainer />
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
            {loading && (
              <Box 
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "80vh" // Full height to center the loader
                }}
              >
                <CircularProgress />
              </Box>
            )}
            {!loading && (
            <Grid>
              <Box sx={{ display: "flex", alignItems: "center", marginBottom: 2 }}>
                <Tooltip title="Return Back">
                  <ArrowBackRoundedIcon
                    color="disabled"
                    onClick={handleBackClick}
                    sx={{ cursor: "pointer", marginRight: 1 }}
                  />
                </Tooltip>
                <Typography sx={{
                  marginRight: { xs: "0", sm: "10px", md: "700px" },
                  fontWeight: "bold",
                  whiteSpace: "nowrap",
                  flexGrow: 1,
                  textAlign: { xs: "center", md: "left" } // Center on smaller screens, left align on medium and larger screens
                }} variant="h6">
                  Compare Active Log
                </Typography>
              </Box>
              <Divider sx={{ marginY: 2 }} />
              {ActiveLogData === null ? (
                <div>
                  <Autocomplete
                    sx={{ padding: 1.2 }}
                    options={userData}
                    getOptionLabel={(option) => option.label}
                    renderInput={(params) => (
                      <TextField {...params} label="Choose User" />
                    )}
                    onChange={handleUserSelect}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "10px",
                    }}
                  >
                    <Card sx={{ flex: 1, border: "1px solid #ccc" }} elevation={10}>
                      <Typography
                        variant="h6"
                        sx={{
                          textAlign: "center",
                          borderBottom: "1px solid #ccc",
                          paddingBottom: "5px",
                          marginBottom: "10px",
                        }}
                      >
                        Attributes
                      </Typography>
                      <TableContainer>
                        <Table>
                          <TableBody>
                            {Object.entries(ActiveLogAttributes).map(
                              ([key, value]) => (
                                <TableRow key={key}>
                                  <TableCell>{key}</TableCell>
                                  <TableCell>
                                    <Tooltip title={value}>
                                      <span>{value}</span>
                                    </Tooltip>
                                  </TableCell>
                                </TableRow>
                              )
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Card>
                    <Card
                      sx={{ flex: 1, border: "1px solid #ccc", marginLeft: "10px" }} elevation={10}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          textAlign: "center",
                          borderBottom: "1px solid #ccc",
                          paddingBottom: "5px",
                          marginBottom: "10px",
                        }}
                      >
                        Old Attributes
                      </Typography>
                      <TableContainer>
                        <Table>
                          <TableBody>
                            {Object.entries(ActiveLogOld).map(([key, value]) => (
                              <TableRow key={key}>
                                <TableCell>{key}</TableCell>
                                <TableCell>
                                  <Tooltip title={value}>
                                    <span>{value}</span>
                                  </Tooltip>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Card>
                  </Box>
                </div>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "10px",
                  }} 
                >
                  <Card sx={{ flex: 1, border: "1px solid #ccc" }} elevation={10}>
                    <Typography
                      variant="h6"
                      sx={{
                        textAlign: "center",
                        borderBottom: "1px solid #ccc",
                        paddingBottom: "5px",
                        marginBottom: "10px",
                      }}
                    >
                      Attributes
                    </Typography>
                    <TableContainer>
                      <Table>
                        <TableBody>
                          {Object.entries(ActiveLogAttributes).map(
                            ([key, value]) => (
                              <TableRow key={key}>
                                <TableCell>{key}</TableCell>
                                <TableCell>
                                  <Tooltip title={value}>
                                    <span>{value}</span>
                                  </Tooltip>
                                </TableCell>
                              </TableRow>
                            )
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Card>
                  <Card
                    sx={{ flex: 1, border: "1px solid #ccc", marginLeft: "10px" }} elevation={10}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        textAlign: "center",
                        borderBottom: "1px solid #ccc",
                        paddingBottom: "5px",
                        marginBottom: "10px",
                      }}
                    >
                      Old Attributes
                    </Typography>
                    <TableContainer>
                      <Table>
                        <TableBody>
                          {Object.entries(ActiveLogOld).map(([key, value]) => (
                            <TableRow key={key}>
                              <TableCell>{key}</TableCell>
                              <TableCell>
                                <Tooltip title={value}>
                                  <span>{value}</span>
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Card>
                </Box>
              )}
              <Divider sx={{ marginY: 2 }} />
              <Box
                sx={{ display: "flex", justifyContent: "flex-end", margin: 2 }}
              >
                <Button
                  onClick={handleCancelClick}
                  color="error"
                  variant="contained"
                  startIcon={<CancelTwoToneIcon />}
                  sx={{ marginRight: 2,color:"white" }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleSubmit}
                  color="success"
                  variant="contained"
                  startIcon={<SendTwoToneIcon />}
                  disabled={loading}
                  sx={{ color:"white" }}
                >
                  {loading ? "Updating..." : "Update Active Log"}
                </Button>
              </Box>
             </Grid>
            )}
            </Paper>
        </Box>
  );
};

export default CompareActiveLogPage;
