import React, { useState, useEffect, useCallback } from 'react';
import {Grid, Paper, Typography, TextField, Button, Divider, List, ListItem, CircularProgress, IconButton, Box,
} from '@mui/material';
import StorageIcon from '@mui/icons-material/Storage';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteForeverTwoToneIcon from '@mui/icons-material/DeleteForeverTwoTone';
import axios from 'axios';
import Swal from 'sweetalert2';
import qs from 'qs';

const CheatSheetPage = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const [cheatSheetText, setCheatSheetText] = useState('');
    const [cheatSheetTitle, setCheatSheetTitle] = useState('');
    const [cheatSheets, setCheatSheets] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(false);

    // Function to load cheatSheets from the server
    const loadCheatSheets = useCallback(async (page) => {
        try {
            setLoading(true);
            const response = await axios.get(`${apiUrl}/portal/list_cheat_sheet`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
                },
                params: { page },
            });

            const { data } = response.data; // Destructure the response data
            setCheatSheets(data); // Set the cheat sheets directly from the response data
            setTotalPages(data.length > 0 ? 1 : 0); // Adjust pagination logic as needed
        } catch (error) {
            console.log('Error', 'Failed to load cheatSheets. Please try again.', 'error');
        } finally {
            setLoading(false);
        }
    }, [apiUrl]);

    useEffect(() => {
        loadCheatSheets(currentPage);
    }, [loadCheatSheets, currentPage]);

    // Handle adding a new cheat sheet item
    const handleAddCheatSheet = async () => {
        if (!cheatSheetText.trim() || !cheatSheetTitle.trim()) return;

        const newCheatSheet = {
            title: cheatSheetTitle,
            content: cheatSheetText, // Adjust content structure
        };

        try {
            const response = await axios.post(
                `${apiUrl}/portal/store_cheat_sheet`,
                newCheatSheet,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
                    },
                }
            );

            const createdCheatSheet = response.data;
            setCheatSheets((prevCheatSheets) => [createdCheatSheet, ...prevCheatSheets]);
            setCheatSheetText('');
            setCheatSheetTitle('');
            loadCheatSheets(currentPage);
        } catch (error) {
            Swal.fire('Error', 'Failed to add cheat sheet. Please try again.', 'error');
        }
    };

    // Handle deleting a cheat sheet
    const handleDelete = async (cheatSheetId) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'This action cannot be undone!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
        }).then((result) => {
            if (result.isConfirmed) {
                deleteCheatSheet(cheatSheetId);
            }
        });
    };

    // Function to delete a cheat sheet content
    const deleteCheatSheet = async (cheatSheetId) => {
        try {
            const response = await axios.delete(`${apiUrl}/portal/delete_cheat_sheet`, {
                data: qs.stringify({ cheatSheet_id: cheatSheetId }),
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            });

            Swal.fire('Deleted!', response.data.message, 'success');
            loadCheatSheets(currentPage);
        } catch (error) {
            Swal.fire('Error', 'Failed to delete cheat sheet. Please try again.', 'error');
        }
    };

    // Handle page change (pagination)
    const handlePageChange = (direction) => {
        setCurrentPage((prevPage) => {
            const newPage = direction === 'next' ? prevPage + 1 : prevPage - 1;
            return newPage >= 1 && newPage <= totalPages ? newPage : prevPage;
        });
    };

    return (
        <Grid item xs={12}>
            <Paper sx={{ padding: 2 }} elevation={5}>
                <Typography variant="h6" gutterBottom>
                    <StorageIcon sx={{ verticalAlign: 'middle', mr: 1 }} /> CheatSheet
                </Typography>
                <TextField
                    label="Add Title"
                    fullWidth
                    size="small"
                    sx={{ marginBottom: 2 }}
                    value={cheatSheetTitle}
                    onChange={(e) => setCheatSheetTitle(e.target.value)}
                />
                <TextField
                    label="Add a new cheat sheet item"
                    fullWidth
                    multiline
                    rows={2}
                    sx={{ marginBottom: 2 }}
                    value={cheatSheetText}
                    onChange={(e) => setCheatSheetText(e.target.value)}
                />
                <Button variant="contained" startIcon={<AddCircleOutlineIcon />} fullWidth onClick={handleAddCheatSheet}>
                    Add Cheat Sheet Item
                </Button>
                <Divider sx={{ marginY: 2 }} />
                <Typography variant="subtitle1">Saved Cheat Sheet Items:</Typography>
                {loading ? (
                    <CircularProgress sx={{ display: 'block', margin: '0 auto' }} />
                ) : cheatSheets.length === 0 ? (
                    <Typography variant="body1" sx={{ textAlign: 'center' }}>
                        No Cheat Sheet Data Found
                    </Typography>
                ) : (
                    <List>
                        {cheatSheets.map((cheatSheet) => (
                            <ListItem
                                key={cheatSheet.title} // Use title as key since id is not available
                                sx={{
                                    display: 'flex',
                                    alignItems: 'flex-start',
                                    padding: '5px',
                                    borderBottom: '1px solid #e0e0e0',
                                }}
                            >
                                <Box
                                    sx={{
                                        flex: 1,
                                        backgroundColor: '#eceeee',
                                        padding: 1,
                                        borderRadius: 2,
                                    }}
                                >
                                    <Typography
                                        variant="h6"
                                        gutterBottom
                                        sx={{
                                            marginBottom: '1px',
                                            fontSize: '1rem',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        {cheatSheet.title}
                                    </Typography>
                                    {cheatSheet.contents.map((content) => (
                                        <Box
                                            key={content.id} // Use content id for key
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                padding: '5px',
                                                marginBottom: '5px',
                                                border: '1px solid #ddd',
                                                borderRadius: 2,
                                            }}
                                        >
                                            <Typography variant="body2" color="textSecondary">
                                                {content.content} {/* Access content property */}
                                            </Typography>
                                            <IconButton
                                                onClick={() => handleDelete(content.id)}
                                                color="error"
                                                sx={{ marginLeft: 1 }}
                                            >
                                                <DeleteForeverTwoToneIcon />
                                            </IconButton>
                                        </Box>
                                    ))}
                                </Box>
                            </ListItem>
                        ))}
                    </List>
                )}
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button
                        variant="outlined"
                        onClick={() => handlePageChange('prev')}
                        disabled={currentPage === 1}
                    >
                        Previous
                    </Button>
                    <Button
                        variant="outlined"
                        onClick={() => handlePageChange('next')}
                        disabled={currentPage === totalPages}
                    >
                        Next
                    </Button>
                </div>
            </Paper>
        </Grid>
    );
};

export default CheatSheetPage;