import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import SendTwoToneIcon from "@mui/icons-material/SendTwoTone";
import CancelTwoToneIcon from "@mui/icons-material/CancelTwoTone";
import CloudUploadTwoToneIcon from "@mui/icons-material/CloudUploadTwoTone";
import InputLabel from "@mui/material/InputLabel";
import { useNavigate } from "react-router-dom";
import { Card, CardContent, CircularProgress } from '@mui/material';
import axios from "axios";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import qs from "qs";

const UpdateLandingContentPage = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();
    const { landingContentId } = useParams();
    const [landingContent, setLandingContent] = useState(null);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [image, setImage] = useState("/image/no_image.jpg");
    const [imageFile, setImageFile] = useState(null);
    const [titleError, setTitleError] = useState('');
    const [descriptionError, setDescriptionError] = useState('');
    const [imageError, setImageError] = useState('');
    const [loading, setLoading] = useState(false);
    const handleBackClick = () => {
        navigate(-1);
    };

    const handleCancelClick = () => {
        navigate("/admin/landing-content"); // Replace '/desired/path' with the path you want to navigate to on cancel
    };

    const handleUpdate = () => {
        setTitleError('');
        setDescriptionError('');
        setImageError('');
        setLoading(true);

        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('landingContent_id', landingContent);
        formData.append('title', title);
        formData.append('description', description);
        formData.append('image', image ?? null);
        if (imageFile) {
            formData.append('image_file', imageFile); // Ensure this is a File object
        }

        axios.post(`${apiUrl}/admin/landing_content/update_landing_content`, formData, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
            },
        })
            .then(response => {
                const landingContentData = response.data;
                //console.log(userData);
                toast.success(landingContentData.message);
                setLoading(false);
                navigate('/admin/landing-content'); // Adjust the path to where you want to navigate after success
            })
            .catch(error => {
                setLoading(false);
                if (error.response) {
                    if (error.response.data && error.response.data.errors) {
                        if (error.response.data.errors.title) {
                            setTitleError(error.response.data.errors.title[0]);
                        }
                        if (error.response.data.errors.description) {
                            setDescriptionError(error.response.data.errors.description[0]);
                        }
                        if (error.response.data.errors.image_file) {
                            setImageError(error.response.data.errors.image_file[0]);
                        }
                    } else if (error.response.data.message) {
                        toast.error(error.response.data.message);
                    }
                } else {
                    toast.error('Server error or network issue. Please try again later.');
                }
            });
    };


    const handleImageFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (file.type.startsWith('image/')) {
                setImage(URL.createObjectURL(file));
                setImageFile(file);
                setImageError('');
            } else {
                setImageError('The file must be an image.');
            }
        }
    };

    useEffect(() => {
        // Fetch user data based on landingContentId
        const token = localStorage.getItem('token');
        setLoading(true);
        axios.put(
            `${apiUrl}/admin/landing_content/find_landing_content`,
            qs.stringify({ landingContent_id: landingContentId }), // Serialize data to x-www-form-urlencoded format
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/x-www-form-urlencoded', // Set the content type to x-www-form-urlencoded
                },
            }
        )
            .then((response) => {
                const landingContentData = response.data.data;
                setLandingContent(landingContentData.id);
                setTitle(landingContentData.title);
                setDescription(landingContentData.description);
                setImage(landingContentData.image ?? "/image/no_image.jpg");
            })
            .catch((error) => {
                console.error("Error fetching user data:", error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [landingContentId, apiUrl]);

    useEffect(() => {
        return () => {
            if (image.startsWith('blob:')) {
                URL.revokeObjectURL(image); // Clean up URL.createObjectURL()
            }
        };
    }, [image]);

    return (
        <Box sx={{ display: "flex" }}>
            <ToastContainer />
            <Paper sx={{ width: "100%", overflow: "hidden", padding: "4px" }}>
                {loading && (
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "80vh" // Full height to center the loader
                        }}
                    >
                        <CircularProgress />
                    </Box>
                )}
                {!loading && (
                    <Grid>
                        <Box sx={{ display: "flex", alignItems: "center", marginBottom: 2 }}>
                            <Tooltip title="Return Back">
                                <ArrowBackRoundedIcon
                                    color="disabled"
                                    onClick={handleBackClick}
                                    sx={{ cursor: "pointer", marginRight: 1 }}
                                />
                            </Tooltip>
                            <Typography sx={{
                                marginRight: { xs: "0", sm: "10px", md: "700px" },
                                fontWeight: "bold",
                                whiteSpace: "nowrap",
                                flexGrow: 1,
                                textAlign: { xs: "center", md: "left" } // Center on smaller screens, left align on medium and larger screens
                            }} variant="h6">
                                Update Landing Content
                            </Typography>
                        </Box>
                        <Divider sx={{ marginY: 2 }} />

                        <Grid container spacing={2} sx={{ marginBottom: 3, display: 'flex', alignItems: 'stretch' }}>
                            <Grid item xs={12} md={4.5} sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <InputLabel sx={{ fontSize: "1.2rem", fontWeight: "bold", paddingRight: '200px' }} shrink>
                                    Landing Content  Image :
                                </InputLabel>
                                <Card sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <CardContent>
                                        <Box sx={{ marginBottom: 1 }}>
                                            <img
                                                src={image}
                                                alt="Main_Image"
                                                style={{ borderRadius: "5%", width: 220, height: 150, objectFit: "cover", border: '2px dotted grey' }}
                                            />
                                        </Box>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                            <label htmlFor="profile-picture-upload">
                                                <input
                                                    style={{ display: 'none' }}
                                                    id="profile-picture-upload"
                                                    name="profile-picture-upload"
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={handleImageFileChange}
                                                />
                                                <Button
                                                    sx={{ backgroundColor: '#10d915', color: 'white', '&:hover': { backgroundColor: 'darkgrey' }, whiteSpace: 'nowrap' }}
                                                    variant="contained"
                                                    component="span"
                                                    startIcon={<CloudUploadTwoToneIcon />}
                                                >
                                                    Upload Image
                                                </Button>
                                            </label>
                                            {imageError && <Typography variant="body2" color="error">{imageError}</Typography>}
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} md={0.5} sx={{ display: 'flex', alignItems: 'stretch' }}>
                                <Divider orientation="vertical" variant="middle" flexItem />
                            </Grid>
                            <Grid item xs={12} md={7}>
                                <Grid container spacing={2} sx={{ marginBottom: 3 }}>
                                    <Grid item xs={12} md={12}>
                                        <InputLabel
                                            sx={{ fontSize: "1.2rem", fontWeight: "bold" }}
                                            shrink
                                        >
                                            Landing Content Title :
                                        </InputLabel>
                                        <TextField
                                            placeholder="Enter Landing Content Title"
                                            variant="outlined"
                                            type="text"
                                            fullWidth
                                            value={title}
                                            onChange={(e) => setTitle(e.target.value)}
                                            error={Boolean(titleError)}
                                            helperText={titleError}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} sx={{ marginBottom: 3 }}>
                                    <Grid item xs={12} md={12} sx={{ marginBottom: 3 }}>
                                        <InputLabel
                                            sx={{ fontSize: "1.2rem", fontWeight: "bold" }}
                                            shrink
                                        >
                                            Category Description :
                                        </InputLabel>
                                        {descriptionError && (
                                            <Typography color="error" variant="caption">
                                                {descriptionError}
                                            </Typography>
                                        )}
                                        <ReactQuill
                                            value={description}
                                            onChange={setDescription}
                                            modules={{
                                                toolbar: [
                                                    [{ 'header': [1, 2, 3, 4, 5, 6, false] }], // Header levels
                                                    [{ 'font': [] }], // Font selection
                                                    [{ 'size': ['small', false, 'large', 'huge'] }], // Font size selection
                                                    ['bold', 'italic', 'underline', 'strike'], // Text formatting
                                                    [{ 'color': [] }, { 'background': [] }], // Text color and background
                                                    [{ 'script': 'sub' }, { 'script': 'super' }], // Subscript / superscript
                                                    [{ 'list': 'ordered' }, { 'list': 'bullet' }], // Ordered/bullet lists
                                                    [{ 'indent': '-1' }, { 'indent': '+1' }], // Indent/outdent
                                                    [{ 'align': [] }], // Text alignment
                                                    ['link', 'image', 'video'], // Link, image, and video options
                                                    ['blockquote', 'code-block'], // Blockquote and code block
                                                    ['clean'] // Remove formatting
                                                ],
                                            }}
                                            placeholder="Please provide Landing Content Description"
                                            style={{ height: '150px' }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Divider sx={{ marginY: 2 }} />
                        <Box sx={{ display: "flex", justifyContent: "flex-end", margin: 1 }}>
                            <Button
                                onClick={handleCancelClick}
                                color="error"
                                variant="contained"
                                startIcon={<CancelTwoToneIcon />}
                                sx={{ marginRight: 2, color: "white" }}
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={handleUpdate}
                                color="success"
                                variant="contained"
                                startIcon={<SendTwoToneIcon />}
                                disabled={loading}
                                sx={{ color: 'white' }}
                            >
                                {loading ? "Updating..." : "Update Category"}
                            </Button>
                        </Box>
                    </Grid>
                )}
            </Paper>
        </Box>
    );
};

export default UpdateLandingContentPage;
