import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import SendTwoToneIcon from "@mui/icons-material/SendTwoTone";
import CancelTwoToneIcon from "@mui/icons-material/CancelTwoTone";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Checkbox from "@mui/material/Checkbox";
import Swal from "sweetalert2";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import qs from "qs";
import { CircularProgress } from "@mui/material";

const UpdateRolePage = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const { roleId } = useParams();
  const [id, setId] = useState(null);
  const [scoreCount, setScoreCount] = useState(0);
  const [name, setName] = useState("");
  const [role, setRole] = useState("");
  const [roles, setRoles] = useState([]);
  const [rows, setRows] = useState([]);

  const [nameError, setNameError] = useState("");
  const [roleError, setRollError] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchRoles = async () => {
      setLoading(true); // Set loading to true at the start
      const token = localStorage.getItem("token");

      try {
        const response = await axios.get(`${apiUrl}/admin/role/fetch_all_role`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setRoles(response.data.data.data);
      } catch (error) {
        if (error.response && error.response.data && error.response.data.message) {
          Swal.fire("Error!", error.response.data.message, "error");
        } else {
          toast.error("Failed to fetch roles");
        }
      } finally {
        setLoading(false); // Set loading to false after fetching or error
      }
    };
    fetchRoles();
  }, [apiUrl]);

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleCancelClick = () => {
    navigate("/admin/role");
  };

  useEffect(() => {
    // Fetch user data based on userId
    setLoading(true);
    const token = localStorage.getItem("token");
    axios
      .put(
        `${apiUrl}/admin/role/find_role`,
        qs.stringify({ role_id: roleId }),
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        const data = response.data.data;
        setScoreCount(response.data.data.totalChecked);
        setRows(data.privileges);
        setId(data.role.id);
        setName(data.role.name);
        setRole(data.role.parent_id);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  }, [roleId, apiUrl]);

  // Function to fetch privileges by role
  const fetchPrivilegesByRole = (roleId) => {
    setLoading(true); // Set loading state to true when fetching data
    const token = localStorage.getItem("token");
    axios
      .get(
        `${apiUrl}/admin/role/find_privileges?role_id=${roleId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        const privilegeData = response.data.data;
        setScoreCount(response.data.totalChecked);
        setRows(privilegeData);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleSubmit = () => {
    setNameError("");
    setRollError("");
    setLoading(true);

    const token = localStorage.getItem("token");
    const formData = new FormData();
    formData.append("id", id);
    formData.append("name", name);
    formData.append("parent_id", role);
    formData.append("score_count", scoreCount);

    // Collect checked privileges
    const checkedPrivileges = [];
    Object.keys(rows).forEach((group) => {
      rows[group].forEach((privilege) => {
        if (privilege.checked === 1) {
          checkedPrivileges.push(privilege.id);
        }
      });
    });
    formData.append("privileges", JSON.stringify(checkedPrivileges));

    axios
      .post(`${apiUrl}/admin/role/update_role`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        const userData = response.data;
        toast.success(userData.message);
        setLoading(false);
        navigate("/admin/role"); // Adjust the path to where you want to navigate after success
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          if (error.response.data && error.response.data.errors) {
            if (error.response.data.errors.name) {
              setNameError(error.response.data.errors.name[0]);
            }
          } else if (error.response.data.message) {
            toast.error(error.response.data.message);
          }
        } else {
          toast.error("Server error or network issue. Please try again later.");
        }
      });
  };

  const handleCheckboxChange = (group, privilegeId) => {
    setRows((prevRows) => {
      const updatedRows = { ...prevRows };
      const privilegeIndex = updatedRows[group].findIndex(
        (privilege) => privilege.id === privilegeId
      );
      if (privilegeIndex !== -1) {
        const updatedPrivilege = { ...updatedRows[group][privilegeIndex] };
        updatedPrivilege.checked = updatedPrivilege.checked === 1 ? 0 : 1;
        updatedRows[group][privilegeIndex] = updatedPrivilege;

        // Update score count
        setScoreCount((prevCount) =>
          updatedPrivilege.checked === 1 ? prevCount + 1 : prevCount - 1
        );
      }
      return updatedRows;
    });
  };

  const handleRoleChange = (e) => {
    const selectedRole = e.target.value;

    if (selectedRole === role) {
      // If the selected role is the same as the current role, do nothing
      return;
    }

    Swal.fire({
      title: "Override Selected Privileges",
      text: "Selecting a new role will override your previously selected privileges. Do you want to continue?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, proceed",
    }).then((result) => {
      if (result.isConfirmed) {
        setRole(selectedRole);
        fetchPrivilegesByRole(selectedRole);
      }
    });
  };

  return (
        <Box sx={{ display: "flex" }}>
          <ToastContainer />
          {loading && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "80vh" // Full height to center the loader
                }}
              >
                <CircularProgress />
              </Box>
            )}
            {!loading && (
            <Paper sx={{ width: "100%", overflow: "hidden", padding: "4px" }}>
              <Box sx={{ display: "flex", alignItems: "center", marginBottom: 2 }}>
                <Tooltip title="Return Back">
                  <ArrowBackRoundedIcon
                    color="disabled"
                    onClick={handleBackClick}
                    sx={{ cursor: "pointer", marginRight: 1 }}
                  />
                </Tooltip>
                <Typography sx={{
                  marginRight: { xs: "0", sm: "10px", md: "700px" },
                  fontWeight: "bold",
                  whiteSpace: "nowrap",
                  flexGrow: 1,
                  textAlign: { xs: "center", md: "left" } // Center on smaller screens, left align on medium and larger screens
                }} variant="h6">
                  Update Role
                </Typography>
              </Box>
              <Divider sx={{ marginY: 2 }} />

              <Grid container spacing={2} sx={{ marginBottom: 3 }}>
                <Grid item xs={12} md={12}>
                  <Grid container spacing={2} sx={{ marginBottom: 3 }}>
                    <Grid item xs={12} md={4}>
                      <InputLabel
                        sx={{ fontSize: "1.2rem", fontWeight: "bold" }}
                        shrink
                      >
                        Name :
                      </InputLabel>
                      <TextField
                        placeholder="Enter Role Name"
                        variant="outlined"
                        type="text"
                        fullWidth
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        error={!!nameError}
                        helperText={nameError}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <InputLabel
                        sx={{ fontSize: "1.2rem", fontWeight: "bold" }}
                        shrink
                      >
                        Parent Role :
                      </InputLabel>
                      <TextField
                        select
                        fullWidth
                        label="Select Parent Role"
                        value={role}
                        onChange={handleRoleChange}
                        error={Boolean(roleError)}
                        helperText={roleError}
                      >
                        {roles.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <InputLabel
                        sx={{ fontSize: "1.2rem", fontWeight: "bold" }}
                        shrink
                      >
                        Role Score:
                      </InputLabel>
                      <TextField
                        placeholder="Role Score Count"
                        type="number"
                        variant="outlined"
                        fullWidth
                        value={scoreCount}
                        InputProps={{ readOnly: true }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Grid container spacing={2}>
                    {Object.keys(rows).map((group) => (
                      <Grid item xs={12} md={3} key={group}>
                        <Card elevation={5}>
                          <CardContent>
                            <Typography
                              variant="body1"
                              component="div"
                              sx={{ fontSize: "1.2rem", fontWeight: "bold" }}
                            >
                              {group} {/* Display the group name */}
                            </Typography>
                            <Divider sx={{ marginY: 1 }} />
                            {rows[group].map((privilege) => (
                              <Box
                                key={privilege.id}
                                display="flex"
                                alignItems="center"
                              >
                                <Checkbox
                                  checked={privilege.checked === 1}
                                  onChange={() =>
                                    handleCheckboxChange(group, privilege.id)
                                  }
                                />
                                <Typography>{privilege.name}</Typography>
                              </Box>
                            ))}
                          </CardContent>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>

              <Divider sx={{ marginY: 2 }} />
              <Box
                sx={{ display: "flex", justifyContent: "flex-end", margin: 2 }}
              >
                <Button
                  onClick={handleCancelClick}
                  color="error"
                  variant="contained"
                  startIcon={<CancelTwoToneIcon />}
                  sx={{ marginRight: 2,color:"white" }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleSubmit}
                  color="success"
                  variant="contained"
                  startIcon={<SendTwoToneIcon />}
                  disabled={loading}
                  sx={{ color:"white" }}
                >
                  {loading ? "Updating..." : "Update Role"}
                </Button>
              </Box>
            </Paper>
          )}
        </Box>
  );
};

export default UpdateRolePage;
