import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

//Portal Route
import PortalRoutes from './components/Portal/Route/PortalRoutes';
// Admin Route
import AdminRoutes from './components/Admin/Route/AdminRoutes';

const App = () => {
  return (
    <Routes>
      {/* Portal Route Path */}
      <Route path="/*" element={<PortalRoutes />} />
      {/* Admin Route Path */}
      <Route path="/admin/*" element={<AdminRoutes />} />
    </Routes>
  );
};

// Main App component wrapped in Router
const MainApp = () => (
  <Router>
    <App />
  </Router>
);

export default MainApp;
