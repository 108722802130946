import React, { useState, useEffect } from 'react';
import Header from './Header';
import Footer from './Footer';
import Theme from './Theme';
import { Box, IconButton, Tooltip } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

const Layout = ({ children }) => {
    const [showScroll, setShowScroll] = useState(false);

    // Scroll to the top when the Layout or its children change
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [children]);

    // Handle scroll visibility
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 200) {
                setShowScroll(true);
            } else {
                setShowScroll(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    // Scroll to top function
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <ThemeProvider theme={Theme}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh', // Ensure the layout fills the entire viewport height
                }}
            >
                <Header />
                <Box
                    component="main"
                    sx={{
                        flex: 1, // Allow main content to expand and push the footer to the bottom
                        pt: 4,
                        pb: 2,
                        px: 2,
                    }}
                >
                    {children}
                </Box>
                <Footer />

                {/* Scroll to Top Button with Tooltip */}
                {showScroll && (
                    <Tooltip title="Scroll To Top" arrow>
                        <IconButton
                            onClick={scrollToTop}
                            sx={{
                                position: 'fixed',
                                bottom: 16,
                                right: 16,
                                backgroundColor: 'primary.main',
                                color: 'white',
                                '&:hover': { backgroundColor: 'primary.dark' },
                                width: 48,
                                height: 48,
                                boxShadow: 5,
                            }}
                        >
                            <ArrowUpwardIcon />
                        </IconButton>
                    </Tooltip>
                )}
            </Box>
        </ThemeProvider>
    );
};

export default Layout;
