import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import MenuItem from "@mui/material/MenuItem";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import Tooltip from "@mui/material/Tooltip";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import { Typography, TextField, Button, Grid, Box } from "@mui/material";
import { Card, CardContent, CircularProgress } from '@mui/material';
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import qs from "qs";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: "#efebf2",
  color: "black",
  fontSize: "14px",
  fontWeight: "bold", // Add this line to make text bold
  "&:first-of-type": {
    borderTopLeftRadius: "5px",
    borderBottomLeftRadius: "5px",
  },
  "&:last-of-type": {
    borderTopRightRadius: "5px",
    borderBottomRightRadius: "5px",
  },
}));

const RolePage = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortBy, setSortBy] = useState("id");
  const [rangeStart, setRangeStart] = useState(1);
  const [rangeEnd, setRangeEnd] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Function to fetch categories
  const fetchRole = useCallback((page, perPage, sortOrder, sortBy) => {
    setLoading(true);
    const token = localStorage.getItem("token");
    axios
      .get(
        `${apiUrl}/admin/role/fetch_all_role?page=${page}&per_page=${perPage}&sort_order=${sortOrder}&sort_by=${sortBy}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        const activeLogData = response.data.data;
        setRows(activeLogData.data);
        setRangeStart(activeLogData.from);
        setRangeEnd(activeLogData.to);
        setTotalRows(activeLogData.total);
        setTotalPages(Math.ceil(activeLogData.total / perPage));
      })
      .catch((error) => {
        if (error.response.data.message) {
          Swal.fire("Error!", error.response.data.message, "error");
        } else {
          toast.error("Failed to fetch roles");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [apiUrl]);

  useEffect(() => {
    fetchRole(currentPage, perPage, sortOrder, sortBy);
  }, [currentPage, perPage, sortOrder, sortBy, fetchRole]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handlePerPageChange = (event) => {
    const perPageValue = event.target.value;
    setPerPage(perPageValue); // Update perPage state
    setCurrentPage(1);
    fetchRole(1, perPageValue, sortOrder, sortBy);
  };

  const handleSortOrderChange = (event) => {
    const newSortOrder = event.target.value;
    setSortOrder(newSortOrder);
    fetchRole(1, perPage, newSortOrder, sortBy); // Fetch categories with the updated sort order and sort by values
  };

  const handleSortByChange = (event) => {
    const newSortBy = event.target.value;
    setSortBy(newSortBy);
    fetchRole(1, perPage, sortOrder, newSortBy); // Fetch categories with the updated sort by value
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleAddButtonClick = () => {
    navigate("/admin/add-role"); // Navigate to '/add-role' route when Add button is clicked
  };

  const handleEditButtonClick = (roleId) => {
    navigate(`/admin/update-role/${roleId}`); // Navigate to UpdateRolePage with the role ID
  };

  const handleDeleteButtonClick = (roleId) => {
    // Show SweetAlert confirmation dialog
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this role!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        // If confirmed, proceed with delete
        deleteRole(roleId);
      }
    });
  };

  // Function to call API for deleting role
  const deleteRole = (roleId) => {
    const token = localStorage.getItem("token");

    axios({
      method: "delete",
      url: `${apiUrl}/admin/role/delete_role`,
      data: qs.stringify({ role_id: roleId }), // Serialize data to x-www-form-urlencoded format
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/x-www-form-urlencoded", // Set the content type to x-www-form-urlencoded
      },
    })
      .then((response) => {
        Swal.fire("Deleted!", response.data.message, "success");
        fetchRole(currentPage, perPage, sortOrder, sortBy); // Refresh role list
      })
      .catch((error) => {
        console.error("Error deleting role:", error);
        Swal.fire(
          "Error!",
          "An error occurred while deleting the role.",
          "error"
        );
      });
  };

  return (
    <Box sx={{ display: "flex" }}>
      <ToastContainer />
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        {loading && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "80vh" // Full height to center the loader
            }}
          >
            <CircularProgress />
          </Box>
        )}
        {!loading && (
          <Grid container>
            <Grid item xs={12} md={12} order={1}>
              <TableContainer sx={{ maxHeight: { xs: 725, sm: 600, md: 500, lg: 460, xl: 500 } }}>
                <Grid
                  container
                  spacing={1}
                  sx={{
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: 0.4,
                    marginTop: 0.5,
                  }}
                >
                  {/* ArrowBack icon and Role List text */}
                  <Grid
                    item
                    xs={12}
                    sm={8}
                    md={7}
                    container
                    justifyContent={{ xs: "center", sm: "flex-start" }}
                    alignItems="center"
                    spacing={1}
                  >
                    <Grid item>
                      <Tooltip title="Return Back">
                        <ArrowBackRoundedIcon color="disabled" onClick={handleBackClick} />
                      </Tooltip>
                    </Grid>
                    <Grid item>
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          whiteSpace: "nowrap",
                          textAlign: { xs: "center", sm: "left" }, // Center on extra-small screens, left align on small and larger
                        }}
                        variant="h6"
                      >
                        Role List
                      </Typography>
                    </Grid>
                  </Grid>

                  {/* Add button and Search TextField */}
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    md={5}
                    container
                    justifyContent={{ xs: "flex-start", sm: "flex-end" }}
                    alignItems="center"
                    spacing={1}
                    sx={{ flexDirection: { xs: "column", sm: "row" } }} // Column for mobile, row for larger screens
                  >
                    <Grid item xs={12} sm="auto" order={{ xs: 1, sm: 1 }}>
                      <Tooltip title="Add New Role">
                        <Button
                          variant="contained"
                          color="success"
                          size="large"
                          startIcon={<AddCircleOutlineIcon />}
                          onClick={handleAddButtonClick}
                          sx={{ width: { xs: "100%", sm: "auto", color: "white" } }} // Full width on mobile
                        >
                          Add
                        </Button>
                      </Tooltip>
                    </Grid>

                    <Grid item xs={12} sm="auto" order={{ xs: 2, sm: 2 }}>
                      <TextField
                        id="outlined-basic"
                        label="Search"
                        size="small"
                        variant="outlined"
                        sx={{ marginLeft: { xs: 0, sm: "10px" }, marginTop: { xs: "10px", sm: 0 }, width: { xs: "100%", sm: "auto" } }} // Adjusting margin and width for responsiveness
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {!isMobile && (
                  <Grid item xs={12}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>Role</StyledTableCell>
                          <StyledTableCell align="center">Parent Role</StyledTableCell>
                          <StyledTableCell align="center">Total Users</StyledTableCell>
                          <StyledTableCell align="center">Updated At</StyledTableCell>
                          <StyledTableCell align="center">Action</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map((row) => (
                          <TableRow key={row.id}>
                            <TableCell>{row.name}</TableCell>
                            <TableCell align="center">{row.parent_id ? row.parent_id : "-"}</TableCell>
                            <TableCell align="center">
                              {Array.isArray(row.users) ? row.users.length : row.users} Users
                            </TableCell>
                            <TableCell align="center">
                              {new Date(row.updated_at).toLocaleDateString(
                                "en-US",
                                {
                                  day: "numeric",
                                  month: "long",
                                  year: "numeric",
                                }
                              )}{" "}
                              {new Date(row.updated_at).toLocaleTimeString(
                                "en-US",
                                {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                  hour12: true, // Use 24-hour format
                                }
                              )}
                            </TableCell>
                            <TableCell align="center">
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  gap: "8px", // Add gap for spacing between buttons
                                }}
                              >
                                <Tooltip title="Edit Role">
                                  <Button
                                    variant="outlined"
                                    color="secondary"
                                    size="small"
                                    sx={{
                                      borderRadius: "50%",
                                      minWidth: "32px",
                                      minHeight: "32px",
                                      width: "32px",
                                      height: "32px",
                                      padding: 0, // Remove padding for better icon alignment
                                    }}
                                    onClick={() => handleEditButtonClick(row.id)}
                                  >
                                    <EditTwoToneIcon fontSize="small" />
                                  </Button>
                                </Tooltip>
                                <Tooltip title="Delete Role">
                                  <Button
                                    variant="outlined"
                                    color="error"
                                    size="small"
                                    sx={{
                                      borderRadius: "50%",
                                      minWidth: "32px",
                                      minHeight: "32px",
                                      width: "32px",
                                      height: "32px",
                                      padding: 0, // Remove padding for better icon alignment
                                    }}
                                    onClick={() => handleDeleteButtonClick(row.id)}
                                  >
                                    <DeleteTwoToneIcon fontSize="small" />
                                  </Button>
                                </Tooltip>
                              </Box>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Grid>
                )}
                {isMobile && (
                  <Grid item xs={12}>
                    {loading ? (
                      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                        <CircularProgress />
                      </div>
                    ) : (
                      rows.map((row) => (
                        <Card key={row.id} style={{ marginBottom: '10px' }}>
                          <CardContent>
                            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                              <Typography variant="body2" color="textSecondary">
                                Role
                              </Typography>
                              <Typography variant="body2" color="textPrimary" align="left">
                                {row.name}
                              </Typography>
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                              <Typography variant="body2" color="textSecondary">
                                Parent Role
                              </Typography>
                              <Typography variant="body2" color="textPrimary" align="right">
                                {row.parent_id ? row.parent_id : "-"}
                              </Typography>
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                              <Typography variant="body2" color="textSecondary">
                                Total Users
                              </Typography>
                              <Typography variant="body2" color="textPrimary" align="right">
                                {Array.isArray(row.users) ? row.users.length : row.users} Users
                              </Typography>
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                              <Typography variant="body2" color="textSecondary">
                                Updated At
                              </Typography>
                              <Typography variant="body2" color="textPrimary" align="right">
                                {new Date(row.updated_at).toLocaleDateString(
                                  "en-US",
                                  {
                                    day: "numeric",
                                    month: "long",
                                    year: "numeric",
                                  }
                                )}{" "}
                                {new Date(row.updated_at).toLocaleTimeString(
                                  "en-US",
                                  {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    second: "2-digit",
                                    hour12: true, // Use 24-hour format
                                  }
                                )}
                              </Typography>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                              <Typography variant="body2" color="textPrimary">
                                Actions
                              </Typography>
                              <Typography variant="body2" color="textPrimary" align="right">
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    gap: "8px", // Add gap for spacing between buttons
                                  }}
                                >
                                  <Tooltip title="Edit Role">
                                    <Button
                                      variant="outlined"
                                      color="secondary"
                                      size="small"
                                      sx={{
                                        borderRadius: "50%",
                                        minWidth: "32px",
                                        minHeight: "32px",
                                        width: "32px",
                                        height: "32px",
                                        padding: 0, // Remove padding for better icon alignment
                                      }}
                                      onClick={() => handleEditButtonClick(row.id)}
                                    >
                                      <EditTwoToneIcon fontSize="small" />
                                    </Button>
                                  </Tooltip>
                                  <Tooltip title="Delete Role">
                                    <Button
                                      variant="outlined"
                                      color="error"
                                      size="small"
                                      sx={{
                                        borderRadius: "50%",
                                        minWidth: "32px",
                                        minHeight: "32px",
                                        width: "32px",
                                        height: "32px",
                                        padding: 0, // Remove padding for better icon alignment
                                      }}
                                      onClick={() => handleDeleteButtonClick(row.id)}
                                    >
                                      <DeleteTwoToneIcon fontSize="small" />
                                    </Button>
                                  </Tooltip>
                                </Box>
                              </Typography>
                              {/* You can customize this section with buttons or other elements based on the actions available */}
                            </div>
                          </CardContent>
                        </Card>
                      ))
                    )}
                  </Grid>
                )}
              </TableContainer>
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              order={2}
              sx={{
                paddingTop: 0.7,
                paddingBottom: 0.2,
                marginTop: "5px",
                marginBottom: "5px",
              }}
            >
              {/* Container for both sections */}
              <Grid
                container
                spacing={1}
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {/* Sort Order and Sort By Fields */}
                <Grid item xs={12} sm={6} md={6}>
                  <Box sx={{ display: "flex", alignItems: "center", marginLeft: "10px" }}>
                    <React.Fragment>
                      <TextField
                        select
                        label="Sort Order"
                        value={sortOrder}
                        onChange={handleSortOrderChange}
                        sx={{ width: 100 }}
                        size="small"
                      >
                        {["desc", "asc"].map((option) => (
                          <MenuItem key={option} value={option}>
                            {option.toUpperCase()}
                          </MenuItem>
                        ))}
                      </TextField>
                      <TextField
                        select
                        label="Sort By"
                        value={sortBy}
                        onChange={handleSortByChange}
                        sx={{ marginLeft: "10px", width: 100 }}
                        size="small"
                      >
                        {["id", "created_at", "updated_at"].map((option) => (
                          <MenuItem key={option} value={option}>
                            {option.toUpperCase()}
                          </MenuItem>
                        ))}
                      </TextField>
                    </React.Fragment>
                  </Box>
                </Grid>
                {/* Pagination and Rows per page controls */}
                <Grid item xs={12} sm={6} md={6}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: { xs: "flex-start", sm: "flex-end" },
                      marginLeft: "10px",
                      marginTop: { xs: "10px", sm: "0" }, // Adds spacing for mobile
                    }}
                  >
                    <React.Fragment>
                      <Typography sx={{ marginRight: "10px" }} variant="body2">
                        Rows per page:{" "}
                      </Typography>
                      <TextField
                        select
                        value={perPage}
                        onChange={handlePerPageChange}
                        sx={{ marginRight: "10px" }}
                        size="small"
                      >
                        {[10, 20, 50].map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </TextField>
                      <Typography sx={{ marginRight: "10px" }} variant="body2">
                        {`${rangeStart} - ${rangeEnd} of ${totalRows}`}
                      </Typography>
                      <Pagination
                        sx={{ marginRight: "10px" }}
                        shape="rounded"
                        color="primary"
                        count={totalPages}
                        page={currentPage}
                        onChange={handlePageChange}
                        renderItem={(item) => (
                          <PaginationItem
                            component="button"
                            onClick={() => handlePageChange(null, item.page)}
                            {...item}
                          />
                        )}
                      />
                    </React.Fragment>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Paper>
    </Box>
  );
};

export default RolePage;
