import React, { useState } from 'react';
import { Grid, Typography, Button, Container, Paper, Avatar } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { FaHtml5 } from "react-icons/fa";
import { FaCss3Alt } from "react-icons/fa";
import { SiCloudera } from "react-icons/si";
import { SiCplusplus } from "react-icons/si";
import { SiCsharp } from "react-icons/si";
import { FaJava } from "react-icons/fa";
import { FaPython } from "react-icons/fa";
import { TbBrandJavascript } from "react-icons/tb";
import { useNavigate } from 'react-router-dom';

const CompilerEditorPage = () => {
    const navigate = useNavigate();
    const [imageLoaded, setImageLoaded] = useState(false);

    const handleImageLoad = () => {
        setImageLoaded(true);
    };

    // Static data for tutorials
    const tutorials = [
        { id: 1, name: 'HTML Editor', icon: <FaHtml5 />, path: '/portal/html-editor' },
        { id: 2, name: 'CSS Editor', icon: <FaCss3Alt />, path: '/portal/css-editor' },
        { id: 3, name: 'JavaScript Editor', icon: <TbBrandJavascript />, path: '/portal/javascript-editor' },
        { id: 4, name: 'C Compiler', icon: <SiCloudera />, path: '/portal/c-compiler' },
        { id: 5, name: 'C++ Compiler', icon: <SiCplusplus />, path: '/portal/cpp-compiler' },
        { id: 6, name: 'C# Compiler', icon: <SiCsharp />, path: '/portal/csharp-compiler' },
        { id: 7, name: 'Java Compiler', icon: <FaJava />, path: '/portal/java-compiler' },
        { id: 8, name: 'Python Compiler', icon: <FaPython />, path: '/portal/python-compiler' },

    ];

    const [fetchAll, setFetchAll] = useState(false);

    const handleToggleFetchAll = () => {
        setFetchAll(!fetchAll);
        navigate('/portal/compiler'); // Navigate to '/portal/compiler'
    };

    // Handle Paper click to navigate to the specific subject page
    const handlePaperClick = (path) => {
        navigate(path); // Navigate to the specific path
    };

    return (
        <Container maxWidth="xl" style={{ marginTop: '3em' }}>
            <Typography variant="h4" fontWeight="bold" textAlign="center" gutterBottom>
                Take advantage of our Compiler and Editor.
            </Typography>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} md={9}>
                    <Grid container spacing={2}>
                        {tutorials.map((item) => (
                            <Grid item xs={6} md={4} key={item.id}>
                                <Paper
                                    elevation={5}
                                    style={{
                                        padding: '1rem',
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '1rem',
                                        cursor: 'pointer',
                                        width: 'calc(100% - 30px)',
                                        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                                    }}
                                    onMouseEnter={(e) => {
                                        e.currentTarget.style.transform = 'translateY(-5px)';
                                        e.currentTarget.style.boxShadow = '0px 6px 12px rgba(0, 0, 0, 0.3)';
                                    }}
                                    onMouseLeave={(e) => {
                                        e.currentTarget.style.transform = 'translateY(0)';
                                        e.currentTarget.style.boxShadow = '0px 4px 10px rgba(0, 0, 0, 0.2)';
                                    }}
                                    onClick={() => handlePaperClick(item.path)}
                                >
                                    <Avatar
                                        style={{
                                            width: 45,
                                            height: 45,
                                            backgroundColor: '#f50057',
                                            color: '#fff',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        {item.icon}
                                    </Avatar>
                                    <Typography
                                        variant="subtitle1"
                                        fontWeight="bold"
                                        color="primary"
                                        noWrap
                                        style={{
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden',
                                            whiteSpace: 'nowrap',
                                        }}
                                    >
                                        {item.name}
                                    </Typography>
                                </Paper>
                            </Grid>
                        ))}
                        <Grid item xs={6} md={4}>
                            <Button
                                fullWidth
                                variant="outlined"
                                color="primary"
                                onClick={handleToggleFetchAll}
                                style={{
                                    height: '100%',
                                    fontWeight: 'bold',
                                    display: 'flex',
                                    alignItems: 'center',
                                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
                                    transition: 'box-shadow 0.4s ease',
                                }}
                            >
                                <Avatar
                                    alt="Tutorial Icon"
                                    src="image/portal/compiler/compiler.gif"
                                    style={{ marginRight: '1rem', width: 45, height: 45 }}
                                />
                                {fetchAll ? 'Show less' : 'View all'}
                                {fetchAll ? <ArrowBackIcon /> : <ArrowForwardIcon />}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={3} display="flex" flexDirection="column" alignItems="center">
                    <img
                        src={'image/portal/compiler/compiler_pic.png'}
                        alt="compiler"
                        className={imageLoaded ? 'fade-in' : ''}
                        style={{ maxWidth: '100%', height: 'auto' }}
                        onLoad={handleImageLoad}
                    />
                    <Typography variant="body2" color="textSecondary" align="center">
                        Experience our free Compiler and Editor to write, test, and refine your code effortlessly in an intuitive environment.
                    </Typography>
                </Grid>
            </Grid>
        </Container>
    );
};

export default CompilerEditorPage;
