import React, { useState } from 'react';
import Sidebar from './Sidebar';
import Header from './Header';
import Theme from './Theme';
import PrivateRoute from '../Route/PrivateRoute';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ThemeProvider } from "@mui/material/styles";

const MainContent = styled(Box)(({ theme }) => ({
    flexGrow: 1,
    padding: theme.spacing(0.5),
    paddingTop: theme.spacing(9), // Adjust padding top to accommodate header height
    display: "flex",
    flexDirection: "column",
    opacity: 0, // Initial state for fade-in
    transform: "translateY(20px)", // Initial state for slide-up
    transition: "opacity 0.5s ease, transform 0.5s ease", // Transition for smooth animation

    // Adding an animation effect with a delay to ensure smooth loading
    animation: "fadeSlideIn 0.5s ease forwards",

    // Keyframes for the animation
    "@keyframes fadeSlideIn": {
        "0%": {
            opacity: 0,
            transform: "translateY(20px)"
        },
        "100%": {
            opacity: 1,
            transform: "translateY(0)"
        }
    }
}));

const MainLayout = ({ children }) => {
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [sidebarCollapsed, setSidebarCollapsed] = useState(true);
    const [fullScreen, setFullScreen] = useState(false);

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    const toggleSidebarCollapse = () => {
        setSidebarCollapsed(!sidebarCollapsed);
    };

    const toggleFullScreen = () => {
        setFullScreen(!fullScreen);
    };
    return (
        <div
            style={{
                display: 'flex',
                minHeight: '100vh',
                overflow: fullScreen ? 'hidden' : 'auto',
            }}
        >
            {fullScreen ? null : (
                <Sidebar open={sidebarOpen} collapsed={sidebarCollapsed} />
            )}
            <div
                style={{
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    width: fullScreen ? '100vw' : 'auto',
                }}
            >
                <Header
                    toggleSidebar={toggleSidebar}
                    toggleSidebarCollapse={toggleSidebarCollapse}
                    sidebarCollapsed={sidebarCollapsed}
                    toggleFullScreen={toggleFullScreen}
                />

                <PrivateRoute>
                    <ThemeProvider theme={Theme}>
                        <MainContent>
                            {children}
                        </MainContent>
                    </ThemeProvider>
                </PrivateRoute>
            </div>
        </div>
    );
};

export default MainLayout;