import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { Box, Button, Grid, Typography, Paper, List, ListItem, ListItemText, MenuItem, FormControl, Divider, TextField } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const InterviewQuestionAnswerPage = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const { subjectId } = useParams();
    const navigate = useNavigate();
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [showAnswers, setShowAnswers] = useState(true);
    const [questionsData, setQuestionsData] = useState([]);
    const [subjectName, setSubjectName] = useState('');
    const [topicHeadings, setTopicHeadings] = useState([]); // State for dynamic topic headings
    const [hoveredItem, setHoveredItem] = useState(null); // Track hovered item
    const [paginationInfo, setPaginationInfo] = useState({
        next_page_url: null,
        prev_page_url: null,
    });

    // Fetch Topic Headings dynamically
    const fetchTopicHeadings = useCallback(async () => {
        try {
            const response = await fetch(`${apiUrl}/portal/get_Topic_heading?subject_id=${subjectId}`);
            const data = await response.json();
            if (data.status) {
                setTopicHeadings(data.data); // Store the headings in state
            }
        } catch (error) {
            console.error('Failed to fetch topic headings:', error);
        }
    }, [subjectId, apiUrl]);

    // Wrapping fetchQuestions in useCallback
    const fetchQuestions = useCallback(async (page, perPage) => {
        try {
            const response = await fetch(`${apiUrl}/portal/fetch_interview_question?subject_id=${subjectId}&page=${page}&per_page=${perPage}`);
            const data = await response.json();
            if (data.status) {
                setQuestionsData(data.data.data);
                setPaginationInfo({
                    next_page_url: data.data.next_page_url,
                    prev_page_url: data.data.prev_page_url,
                });
                if (data.data.data.length > 0) {
                    setSubjectName(data.data.data[0].subject_name);
                }
            }
        } catch (error) {
            console.error('Failed to fetch questions:', error);
        }
    }, [subjectId, apiUrl]);

    useEffect(() => {
        fetchQuestions(currentPage, perPage);
        fetchTopicHeadings(); // Fetch the topic headings when the component mounts
    }, [fetchQuestions, fetchTopicHeadings, currentPage, perPage]);

    const handlePerPageChange = (event) => {
        setPerPage(event.target.value);
        setCurrentPage(1);
    };

    const handleNextPage = () => {
        if (paginationInfo.next_page_url) {
            setCurrentPage((prevPage) => prevPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (paginationInfo.prev_page_url && currentPage > 1) {
            setCurrentPage((prevPage) => prevPage - 1);
        }
    };

    const toggleAnswers = () => {
        setShowAnswers((prevShow) => !prevShow);
    };

    const handleItemClick = (subjectId, topicId) => {
        // Redirect to the subject page using the item ID
        navigate(`/portal/subject/${subjectId}/${topicId}`);
    };

    return (
        <Grid container>
            {/* Sidebar */}
            <Grid item xs={12} sm={3} md={2.5} sx={{ backgroundColor: '#9F63FF', color: 'white', height: 'auto', borderRadius: 2, marginTop: 2 }}>
                <Typography fontWeight="bold" gutterBottom variant="h5" textAlign="center" sx={{ color: 'white', marginTop: 2 }}>
                    {subjectName} Tutorial
                </Typography>
                <List sx={{ maxHeight: '500px', overflowY: 'auto' }}>
                    {topicHeadings.map((item) => (
                        <ListItem
                            button
                            key={item.id}
                            onMouseEnter={() => setHoveredItem(item.id)} // Set hovered item
                            onMouseLeave={() => setHoveredItem(null)}    // Reset hovered item
                            onClick={() => handleItemClick(subjectId, item.id)}     // Handle click to redirect
                        >
                            <ListItemText
                                primary={`${item.subject_name} - ${item.heading}`}
                                sx={{ color: 'white' }}
                            />
                            {hoveredItem === item.id && (
                                <ArrowForwardIcon sx={{ color: 'white', marginLeft: 'auto' }} />
                            )}
                        </ListItem>
                    ))}
                </List>
            </Grid>

            {/* Content */}
            <Grid item xs={12} sm={9} md={9.5} sx={{ padding: '10px' }}>
                <Typography variant="h4" fontWeight="bold" textAlign="center" gutterBottom sx={{ marginTop: 0.5 }}>
                    {subjectName} Interview Questions and Answers
                </Typography>
                <Divider sx={{ marginBottom: '5px' }} />

                <Grid container spacing={2} justifyContent="flex-end" alignItems="center" sx={{ marginTop: 0.1 }}>
                    <Grid item xs={12} sm={6} md={2}>
                        <FormControl fullWidth>
                            <TextField
                                id="page-label"
                                select
                                label="Per Questions"
                                value={perPage}
                                size='small'
                                onChange={handlePerPageChange}
                            >
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={20}>20</MenuItem>
                                <MenuItem value={30}>30</MenuItem>
                                <MenuItem value={50}>50</MenuItem>
                            </TextField>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Button
                            variant="outlined"
                            color={showAnswers ? 'error' : 'success'}
                            onClick={toggleAnswers}
                        >
                            {showAnswers ? "Hide Answers" : "Show Answers"}
                            {showAnswers ? <VisibilityOffIcon sx={{ ml: 1 }} /> : <VisibilityIcon sx={{ ml: 1 }} />}
                        </Button>
                    </Grid>
                </Grid>

                <Grid item xs={12} sx={{ marginTop: '20px' }}>
                    <Box>
                        {/* Navigation buttons */}
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                            <Button
                                variant="contained"
                                color="success"
                                startIcon={<ArrowLeftIcon />}
                                onClick={handlePreviousPage}
                                disabled={!paginationInfo.prev_page_url}
                            >
                                Previous
                            </Button>
                            <Button
                                variant="contained"
                                color="success"
                                endIcon={<ArrowRightIcon />}
                                onClick={handleNextPage}
                                disabled={!paginationInfo.next_page_url}
                            >
                                Next
                            </Button>
                        </Box>

                        {/* Dynamic questions content */}
                        {questionsData.map((q, index) => (
                            <Paper elevation={3} sx={{ padding: '10px', marginBottom: '5px' }} key={q.id}>
                                <Typography variant="h6" gutterBottom>
                                    {`Question ${index + 1}: ${q.question}`}
                                </Typography>
                                {showAnswers && (
                                    <Typography paragraph>
                                        Ans: {q.answer}
                                    </Typography>
                                )}
                            </Paper>
                        ))}

                        {/* Navigation buttons at the bottom */}
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                            <Button
                                variant="contained"
                                color="success"
                                startIcon={<ArrowLeftIcon />}
                                onClick={handlePreviousPage}
                                disabled={!paginationInfo.prev_page_url}
                            >
                                Previous
                            </Button>
                            <Button
                                variant="contained"
                                color="success"
                                endIcon={<ArrowRightIcon />}
                                onClick={handleNextPage}
                                disabled={!paginationInfo.next_page_url}
                            >
                                Next
                            </Button>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default InterviewQuestionAnswerPage;
