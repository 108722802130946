// Header.js
import React, { useEffect, useState } from 'react';
import { AppBar, Toolbar, IconButton, InputBase, Box, Typography, Divider, Slide, Tooltip } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen'
import { styled, alpha } from "@mui/material/styles";
import OpenWithIcon from '@mui/icons-material/OpenWith';
import CloseIcon from '@mui/icons-material/Close';
import CropFreeIcon from '@mui/icons-material/CropFree';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import SearchIcon from '@mui/icons-material/Search';
import { Avatar } from '@mui/material';

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius * 2,
  backgroundColor: alpha(theme.palette.common.black, 0.05),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.black, 0.1),
  },
  marginLeft: 0,
  width: "100%",
  border: `1px solid ${alpha(theme.palette.common.black, 0.15)}`,
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "40ch",
    },
  },
}));

const Header = ({
  toggleSidebar,
  toggleSidebarCollapse,
  sidebarCollapsed,
  toggleFullScreen,
}) => {
  const [showHeader, setShowHeader] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMenuOpenHide, setIsMenuOpenHide] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);

  useEffect(() => {
    // Trigger the animation when the component mounts
    setShowHeader(true);
  }, []);

  const handleMenuToggle = () => {
    setIsMenuOpen((prev) => !prev); // Toggle the icon state
    toggleSidebarCollapse(); // Call the sidebar collapse function
  };

  const handleMenuHideToggle = () => {
    setIsMenuOpenHide((prev) => !prev); // Toggle the icon state
    toggleFullScreen(); // Call the sidebar collapse function
  };

  const handleFullscreenToggle = () => {
    if (!isFullscreen) {
      document.documentElement.requestFullscreen().catch((error) => {
        console.error("Error attempting to enter fullscreen mode:", error);
      });
    } else {
      if (document.fullscreenElement) { // Check if fullscreen mode is active
        document.exitFullscreen().catch((error) => {
          console.error("Error attempting to exit fullscreen mode:", error);
        });
      }
    }
    setIsFullscreen((prev) => !prev); // Toggle fullscreen state
  };


  return (
    <Slide direction="down" in={showHeader} timeout={3000}>
      <AppBar
        position="fixed"
        color="transparent"
        elevation={0}
        sx={{
          zIndex: 1201,
          boxShadow: '0 6px 10px rgba(0, 0, 0, 0.3)',
          backdropFilter: 'blur(15px)',
          borderBottomLeftRadius: '15px',
          borderBottomRightRadius: '15px',
        }}
      >
        <Toolbar sx={{ justifyContent: 'space-between', padding: '0 16px' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography
              variant="h5"
              noWrap
              style={{ fontWeight: "bold", color: "#9F63FF" }}
            >
              Knowledge
              <span style={{ color: "#2eee07" }}>Lift</span>
            </Typography>
            <Box
              sx={{
                marginLeft: 3,
                display: 'flex',
                alignItems: 'center',
                border: '1px solid',
                borderColor: 'divider',
                borderRadius: 2,
                bgcolor: 'background.paper',
                color: 'text.secondary',
                '& svg': {
                  m: 1,
                  // Apply a scale and fade-in animation
                  animation: 'fadeScale 0.5s ease-in-out',
                  transition: 'transform 0.3s ease, opacity 0.3s ease',
                },
                // Keyframes for the fade and scale animation
                '@keyframes fadeScale': {
                  '0%': {
                    opacity: 0,
                    transform: 'scale(0.8)',
                  },
                  '100%': {
                    opacity: 1,
                    transform: 'scale(1)',
                  },
                },
              }}
            >
              <Tooltip title={isMenuOpen ? "Close Sidebar Menu" : "Open Sidebar Menu"}>
                {isMenuOpen ? (
                  <MenuOpenIcon
                    onClick={handleMenuToggle}
                    sx={{ color: "#2eee07", animation: isMenuOpen ? 'fadeScale 0.5s ease' : '' }}
                  />
                ) : (
                  <MenuIcon
                    onClick={handleMenuToggle}
                    sx={{ color: "#9F63FF", animation: !isMenuOpen ? 'fadeScale 0.5s ease' : '' }}
                  />
                )}
              </Tooltip>
              <Divider orientation="vertical" variant="middle" flexItem />
              <Tooltip title={isMenuOpenHide ? "Appear Sidebar Menu" : "Hide Sidebar Menu"}>
                {isMenuOpenHide ? (
                  <CloseIcon
                    onClick={handleMenuHideToggle}
                    sx={{ color: "#2eee07", animation: isMenuOpenHide ? 'fadeScale 0.5s ease' : '' }}
                  />
                ) : (
                  <OpenWithIcon
                    onClick={handleMenuHideToggle}
                    sx={{ color: "#9F63FF", animation: !isMenuOpenHide ? 'fadeScale 0.5s ease' : '' }}
                  />
                )}
              </Tooltip>
              <Divider orientation="vertical" variant="middle" flexItem />
              <Tooltip title={isFullscreen ? "Exit Fullscreen" : "Enter Fullscreen"}>
                {isFullscreen ? (
                  <FullscreenExitIcon
                    onClick={handleFullscreenToggle}
                    sx={{ color: "#2eee07", animation: isFullscreen ? 'fadeScale 0.5s ease' : '' }}
                  />
                ) : (
                  <CropFreeIcon
                    onClick={handleFullscreenToggle}
                    sx={{ color: "#9F63FF", animation: !isFullscreen ? 'fadeScale 0.5s ease' : '' }}
                  />
                )}
              </Tooltip>
            </Box>
          </Box>

          {/* Centered Search Bar */}
          {/* Centered Search Bar */}
          <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', maxWidth: '400px', width: '100%' }}>
              <Search
                style={{ backgroundColor: 'white', marginLeft: '110px' }} // Adjust the margin as needed
                sx={{
                  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                  '&:hover': {
                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',
                    transform: 'scale(1.02)',
                  },
                  '&:focus-within': {
                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.3)',
                    transform: 'scale(1.02)',
                  },
                }}
              >
                <SearchIconWrapper sx={{ color: '#5b5858' }}>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Search"
                  inputProps={{ "aria-label": "search" }}
                />
              </Search>
            </Box>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton color="inherit" sx={{ marginRight: 4 }}>
              <Avatar
                alt="Avatar"
                src="/image/avatar2.png" // Replace with your avatar image path
                sx={{
                  width: 40,
                  height: 40,
                  transition: 'transform 0.3s ease-in-out', // Smooth transition
                  '&:hover': {
                    transform: 'scale(1.1)', // Slightly increase size on hover
                  },
                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)', // Optional: Add subtle shadow
                }}
              />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
    </Slide>
  );
};

export default Header;
