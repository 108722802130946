import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Container, Grid, Typography, Box, CircularProgress } from "@mui/material";
import axios from "axios";

const GetNotePage = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const { noteId } = useParams();
    const [note, setNote] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchNote = async () => {
            try {
                setLoading(true);

                const headers = {
                    Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
                    "Content-Type": "application/x-www-form-urlencoded",
                };

                const response = await axios.put(
                    `${apiUrl}/portal/find_note`,
                    {
                        note_id: noteId,
                    },
                    { headers } // Pass headers here
                );

                console.log(response);

                if (response.data.status) {
                    setNote(response.data.data);
                } else {
                    setError("Failed to retrieve note.");
                }
            } catch (err) {
                setError("An error occurred while fetching the note.");
            } finally {
                setLoading(false);
            }
        };

        fetchNote();
    }, [apiUrl, noteId]);

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
                <Typography variant="h6" color="error">
                    {error}
                </Typography>
            </Box>
        );
    }

    return (
        <Container maxWidth="xl" sx={{ mt: 2, mb: 4 }}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography
                        variant="h4"
                        gutterBottom
                        textAlign="center"
                        sx={{ fontWeight: "bold" }}
                    >
                        {note.title}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Box
                        sx={{
                            margin: "0 auto", // Center the box horizontally
                            border: "1px solid #ddd",
                            borderRadius: 2,
                            padding: 2,
                            boxShadow: 1,
                            backgroundColor: "#f9f9f9",
                            overflowX: "auto", // Add horizontal scrollbar when content exceeds width
                        }}
                    >
                        <Typography
                            variant="body1"
                            component="div"
                            dangerouslySetInnerHTML={{ __html: note.note }}
                        />
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
};

export default GetNotePage;
