import React, { useEffect, useState } from 'react';
import { Container, Grid, Typography, Card, CardContent, CardActions, Button } from '@mui/material';
import { useParams, useNavigate } from "react-router-dom";
import axios from 'axios';


const calculateResult = (correctAnswers, totalQuestions) => {
    const scorePercentage = (correctAnswers / totalQuestions) * 100;

    if (scorePercentage >= 80) return 'Excellent';
    if (scorePercentage >= 50) return 'Good';
    return 'Average';
};

const QuizResultPage = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();
    const { resultId } = useParams();
    const [quizResult, setQuizResult] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchQuizResult = async () => {
          try {
            const response = await axios.put(
              `${apiUrl}/portal/fetch_quiz_result`,
              {
                quiz_result_id: resultId,
              },
              {
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${localStorage.getItem('auth_token')}`,
                },
              }
            );
      
            if (response.data.status) {
              setQuizResult(response.data.data);
            } else {
              console.error('Failed to fetch quiz result:', response.data.message);
            }
          } catch (error) {
            console.error('Error fetching quiz result:', error);
          } finally {
            setLoading(false);
          }
        };
      
        fetchQuizResult();
      }, [resultId, apiUrl]);      

    const handleRetakeQuiz = () => {
        navigate(-1);
    };
    const handleBackHome = () => {
        navigate('/');
    };

    if (loading) {
        return (
            <Container maxWidth="md" style={{ marginTop: '2rem', textAlign: 'center' }}>
                <Typography variant="h6">Loading quiz results...</Typography>
            </Container>
        );
    }

    if (!quizResult) {
        return (
            <Container maxWidth="md" style={{ marginTop: '2rem', textAlign: 'center' }}>
                <Typography variant="h6" color="error">
                    Failed to load quiz results. Please try again.
                </Typography>
            </Container>
        );
    }

    const {
        total_questions: totalQuestions,
        correct_answers: correctAnswers,
        wrong_answers: wrongAnswers,
        unanswered_questions: unansweredQuestions,
        total_time_taken: answeredTiming,
    } = quizResult;

    const result = calculateResult(correctAnswers, totalQuestions);

    return (
        <Container maxWidth="xl">
            <Typography variant="h4" align="center" gutterBottom style={{ fontWeight: 'bold' }}>
                Quiz Results
            </Typography>

            <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={4}>
                    <Card elevation={5}>
                        <CardContent>
                            <Typography variant="h6" align="center">Total Questions</Typography>
                            <Typography variant="h5" align="center" style={{ fontWeight: 'bold' }}>
                                {totalQuestions}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <Card elevation={5}>
                        <CardContent>
                            <Typography variant="h6" align="center">Answered Taken Time</Typography>
                            <Typography variant="h5" align="center" style={{ fontWeight: 'bold' }}>
                                {answeredTiming} Sec.
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <Card elevation={5}>
                        <CardContent>
                            <Typography variant="h6" align="center">Correct Answered</Typography>
                            <Typography variant="h5" align="center" style={{ fontWeight: 'bold', color: 'green' }}>
                                {correctAnswers}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <Card elevation={5}>
                        <CardContent>
                            <Typography variant="h6" align="center">Wrong Answers</Typography>
                            <Typography variant="h5" align="center" style={{ fontWeight: 'bold', color: '#d32f2f' }}>
                                {wrongAnswers}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <Card elevation={5}>
                        <CardContent>
                            <Typography variant="h6" align="center">Unanswered Questions</Typography>
                            <Typography variant="h5" align="center" style={{ fontWeight: 'bold', color: '#ff9800' }}>
                                {unansweredQuestions}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <Card elevation={5}>
                        <CardContent>
                            <Typography variant="h6" align="center">Your Result</Typography>
                            <Typography
                                variant="h5"
                                align="center"
                                style={{
                                    fontWeight: 'bold',
                                    color: result === 'Excellent' ? '#4caf50' : result === 'Good' ? '#ffeb3b' : '#f44336',
                                }}
                            >
                                {result}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            <CardActions style={{ justifyContent: 'center', marginTop: '2rem' }}>
                <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={handleBackHome}
                >
                    Home
                </Button>
                <Button
                    variant="contained"
                    color="success"
                    size="large"
                    onClick={handleRetakeQuiz}
                    sx={{ color: 'white' }}
                >
                    Retake Quiz
                </Button>
            </CardActions>
        </Container>
    );
};

export default QuizResultPage;
