import React, { useState, useRef } from "react";
import { Box, TextField, Typography, Grid, Button, Stack, Container } from "@mui/material";
import { PlayArrow, Code, Share } from "@mui/icons-material"; // Import icons
import * as Babel from "@babel/standalone";

const ReactCodeEditor = () => {
  const [reactCode, setReactCode] = useState(`
    const App = () => (
        <div>
            <h1>Hello, React!</h1>
            <p>Dynamic React rendering in the browser!</p>
        </div>
    );
    ReactDOM.render(<App />, document.getElementById("root"));
  `);

  const iframeRef = useRef(null);

  const handleRun = () => {
    if (iframeRef.current) {
      try {
        const transpiledCode = Babel.transform(reactCode, {
          presets: ["react", "env"],
        }).code;

        // Inject the transpiled code into the iframe
        const iframeDocument =
          iframeRef.current.contentDocument || iframeRef.current.contentWindow.document;

        iframeDocument.open();
        iframeDocument.write(`
          <div id="root"></div>
          <script crossorigin src="https://unpkg.com/react/umd/react.development.js"></script>
          <script crossorigin src="https://unpkg.com/react-dom/umd/react-dom.development.js"></script>
          <script>
            ${transpiledCode}
          </script>
        `);
        iframeDocument.close();
      } catch (err) {
        console.error("Error transpiling code:", err);
        alert("Error transpiling code. Check your syntax.");
      }
    }
  };

  const handleBeautify = () => {
    // Beautify the React code (simple indent size)
    const beautifiedCode = reactCode.split("\n").map(line => line.trim()).join("\n");
    setReactCode(beautifiedCode);
  };

  const handleShare = () => {
    navigator.clipboard
      .writeText(reactCode)
      .then(() => alert("Code copied to clipboard!"))
      .catch((err) => alert("Failed to copy code: " + err.message));
  };

  return (
    <Container maxWidth="xl">
      <Stack direction="row" spacing={2} sx={{ marginBottom: 2 }}>
        {/* Run Button */}
        <Button
          variant="contained"
          color="success"
          onClick={handleRun}
          startIcon={<PlayArrow />}
          sx={{ textTransform: "none", color: "white" }}
        >
          Run
        </Button>

        {/* Beautify Button */}
        <Button
          variant="contained"
          color="secondary"
          onClick={handleBeautify}
          startIcon={<Code />}
          sx={{ textTransform: "none" }}
        >
          Beautify
        </Button>

        {/* Share Button */}
        <Button
          variant="contained"
          color="warning"
          onClick={handleShare}
          startIcon={<Share />}
          sx={{ textTransform: "none", color: "white" }}
        >
          Share
        </Button>
      </Stack>

      <Grid container spacing={2} sx={{ height: "100%" }}>
        {/* Code Input Area */}
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              height: "93%",
              padding: 2,
              bgcolor: "#282c34",
              color: "#fff",
              borderRadius: 2,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography variant="h6" sx={{ color: "lightgray", marginBottom: 1 }}>
              React Code
            </Typography>
            <TextField
              value={reactCode}
              onChange={(e) => setReactCode(e.target.value)}
              variant="outlined"
              multiline
              rows={20}
              fullWidth
              sx={{
                backgroundColor: "#1e1e1e",
                color: "#fff",
                flex: 1,
                "& .MuiInputBase-input": { color: "white" },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#4d4d4d",
                  },
                  "&:hover fieldset": {
                    borderColor: "#fff",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#fff",
                  },
                },
              }}
            />
          </Box>
        </Grid>

        {/* Rendered React Output Area */}
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              height: "93%",
              padding: 2,
              backgroundColor: "#f5f5f5",
              borderRadius: 2,
              overflowY: "hidden",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography variant="h6" sx={{ marginBottom: 1 }}>
              Result
            </Typography>
            <iframe
              ref={iframeRef}
              title="Rendered React"
              style={{
                border: "none",
                width: "100%",
                height: "100%",
                backgroundColor: "#fff",
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ReactCodeEditor;
