import React, { useState, useEffect, useCallback } from 'react';
import { Grid, Typography, Button, Container, Card, CardContent, CardActions, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, CircularProgress, Dialog, DialogTitle, DialogContent, DialogContentText, Box } from '@mui/material';
import { useParams, useNavigate } from "react-router-dom";
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CircularProgressWithLabel = ({ value }) => {
  return (
    <div style={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" value={value} />
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(value)}s`}
        </Typography>
      </div>
    </div>
  );
};

const QuizTestPage = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const { quizId } = useParams();
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingQuestions, setLoadingQuestions] = useState(true);
  const [timeLeft, setTimeLeft] = useState(0);
  const [correctAnswersCount, setCorrectAnswersCount] = useState(0);
  const [wrongAnswersCount, setWrongAnswersCount] = useState(0);
  const [openModal, setOpenModal] = useState(false);  // For modal
  const [resultId, setResultId] = useState(null);  // For result redirection
  const [quizStartTime, setQuizStartTime] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const response = await fetch(`${apiUrl}/portal/questions/${quizId}`);
        const data = await response.json();
        setQuestions(data.questions);
        setLoadingQuestions(false);
  
        if (data.questions.length > 0) {
          setTimeLeft(data.questions[0].timeLimit);
          setQuizStartTime(Date.now()); // Record the start time
        }
      } catch (error) {
        console.error('Error fetching questions:', error);
        setLoadingQuestions(false);
      }
    };
  
    fetchQuestions();
  }, [apiUrl, quizId]);  

  // Handle quiz completion
  const handleQuizCompletion = useCallback(async () => {
    const totalQuestions = questions.length;
    const unanswered = totalQuestions - (correctAnswersCount + wrongAnswersCount);
    const totalTimeTaken = Math.round((Date.now() - quizStartTime) / 1000); // Calculate total time taken

    try {
      setLoading(true);
      const response = await fetch(`${apiUrl}/portal/quiz-completion`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('auth_token')}`,
        },
        body: JSON.stringify({
          quiz_id: quizId,
          total_questions: totalQuestions,
          correct_answers: correctAnswersCount,
          wrong_answers: wrongAnswersCount,
          unanswered_questions: unanswered,
          total_time_taken: totalTimeTaken, // Send total time taken
        }),
      });
  
      const data = await response.json();
      setResultId(data.data.id);
  
      if (response.ok) {
        setOpenModal(true);
      } else {
        toast.error(`Failed to complete quiz: ${data.message}`);
      }
    } catch (error) {
      console.error('Error completing quiz:', error);
      toast.error('An error occurred while submitting the quiz.');
    } finally {
      setLoading(false);
    }
  }, [apiUrl, quizId, correctAnswersCount, wrongAnswersCount, questions.length, quizStartTime]);
  



  // Now, include `handleQuizCompletion` in the dependency array of `handleNext`
  const handleNext = useCallback(() => {
    if (questions.length === 0) {
      return;
    }
    toast.dismiss();

    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex((prev) => prev + 1);
      setSelectedOption('');
      setIsSubmitted(false); // Reset for the next question
      setTimeLeft(questions[currentQuestionIndex + 1].timeLimit);
    } else {
      handleQuizCompletion(); // When all questions are done, submit the quiz result
    }
  }, [currentQuestionIndex, questions, handleQuizCompletion]);

  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(timer);
    } else if (timeLeft === 0) {
      handleNext(); // Now this won't trigger the warning
    }
  }, [timeLeft, handleNext]);


  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleSubmit = () => {
    if (selectedOption === '' || isSubmitted) return; // Prevent double submissions

    setLoading(true);
    setIsSubmitted(true);

    setTimeout(() => {
      const correctAnswer = questions[currentQuestionIndex]?.correctAnswer;
      const isCorrect = selectedOption === correctAnswer;

      // Check if the answer is correct
      if (isCorrect) {
        setCorrectAnswersCount((prevCount) => prevCount + 1);
        toast.success("Correct Answer");
      } else {
        setWrongAnswersCount((prevCount) => prevCount + 1);
        toast.error("Wrong Answer");
      }

      setLoading(false);
    }, 1000);
  };


  const renderOptionMedia = (option) => {
    return (
      <div style={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}>
        {/* Mandatory Text */}
        <Typography
          variant="body1"
          style={{ marginRight: '8px' }}
          dangerouslySetInnerHTML={{ __html: option.text }}
        />

        {/* Optional Image */}
        {option.image && (
          <img
            src={option.image}
            alt="Option Media"
            style={{ width: '50px', height: '50px', objectFit: 'cover', marginRight: '8px' }}
          />
        )}

        {/* Optional Sound */}
        {option.sound && <AudioPlayer src={option.sound} style={{ width: '150px' }} />}
      </div>
    );
  };

  const renderQuestionMedia = (media) => {
    return (
      <>
        {media.image && (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '1rem' }}>
            <img
              src={media.image}
              alt="Question Media"
              style={{
                maxWidth: '100%',
                maxHeight: '300px',
                objectFit: 'contain',
                border: '1px solid #ccc',
                borderRadius: '8px',
                padding: '5px',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
              }}
            />
          </div>
        )}

        {media.sound && <AudioPlayer src={media.sound} style={{ marginBottom: '1rem' }} />}
      </>
    );
  };

  const handleModalClose = () => {
    setOpenModal(false);
    navigate(`/portal/quiz-result/${resultId}`);  // Redirect to results page
  };


  if (loadingQuestions) {
    return (
      <Container maxWidth="lg" style={{ marginTop: '2rem', textAlign: 'center' }}>
        <CircularProgress />
        <Typography variant="h6" style={{ marginTop: '1rem' }}>
          Loading questions...
        </Typography>
      </Container>
    );
  }

  if (!questions.length) {
    return (
      <Container maxWidth="lg" style={{ marginTop: '2rem', textAlign: 'center' }}>
        <Typography variant="h6">No questions available.</Typography>
      </Container>
    );
  }

  const currentQuestion = questions[currentQuestionIndex];

  return (
    <Container maxWidth="xl">
      <ToastContainer />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h4" align="center" gutterBottom style={{ fontWeight: 'bold' }}>
            Quiz Test: Question {currentQuestionIndex + 1} of {questions.length}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Card elevation={5}>
            <CardContent>
              <Typography variant="h5" fontWeight="bold" gutterBottom>
                <div dangerouslySetInnerHTML={{ __html: currentQuestion.question }} />
              </Typography>

              {/* Display question media */}
              {currentQuestion.questionMedia && renderQuestionMedia(currentQuestion.questionMedia)}

              <FormControl component="fieldset">
                <FormLabel component="legend">Choose an answer</FormLabel>
                <RadioGroup value={selectedOption} onChange={handleOptionChange}>
                  {currentQuestion.questionMedia.options.map((option, index) => (
                    <FormControlLabel
                      key={index}
                      value={option.text}
                      control={<Radio />}
                      label={renderOptionMedia(option)}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </CardContent>

            <CardActions style={{ justifyContent: 'center' }}>
              <CircularProgressWithLabel value={(timeLeft / currentQuestion.timeLimit) * 100} />
              {loading ? (
                <Button
                  variant="contained"
                  color="success"
                  sx={{ color: 'white', display: 'flex', alignItems: 'center' }}
                  disabled
                >
                  <CircularProgress size={20} sx={{ marginRight: '8px' }} /> {/* Spinner */}
                  Submitting...
                </Button>
              ) : (
                <>
                  <Button
                    variant="contained"
                    color="success"
                    sx={{ color: 'white' }}
                    onClick={handleSubmit}
                    disabled={isSubmitted}
                  >
                    Submit
                  </Button>
                  {isSubmitted && (
                    <Button
                      variant="contained"
                      sx={{ color: 'white' }}
                      color="primary"
                      onClick={handleNext}
                    >
                      Next Question
                    </Button>
                  )}
                </>
              )}
            </CardActions>
          </Card>
        </Grid>
      </Grid>
      {/* Quiz finished modal */}
      <Dialog open={openModal} onClose={handleModalClose}>
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 2,
    }}
  >
    {/* Centered Congratulation GIF */}
    <img
      src="/image/portal/confetti.gif"
      alt="Congratulations"
      style={{
        width: '100px',
        height: '100px',
        marginBottom: '16px',
      }}
    />
    
    {/* Dialog Title */}
    <DialogTitle align="center">Quiz Completed</DialogTitle>
    
    {/* Dialog Content */}
    <DialogContent>
      <DialogContentText align="center">
        You have successfully completed the quiz! Click the button below to see your results.
      </DialogContentText>
    </DialogContent>

    {/* Centered Button */}
    <Button
      onClick={handleModalClose}
      variant="contained"
      sx={{ color: 'white', mt: 2 }}
      color="primary"
    >
      Show Results
    </Button>
  </Box>
</Dialog>
    </Container>
  );
};

export default QuizTestPage;
