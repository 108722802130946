import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

// Admin Route imports
import LoginPage from '../Auth/LoginPage';
import RegisterPage from '../Auth/RegisterPage';
import ForgotPasswordPage from '../Auth/ForgotPasswordPage';
import ResetPasswordPage from '../Auth/ResetPasswordPage';
import PermissionPage from '../Permit/PermissionPage';
import PrivateRoute from './PrivateRoute';
import DashboardPage from '../Home/DashboardPage';
import MainLayout from '../Template/MainLayout';
import NotFoundPage from './NotFoundPage';
import UserPage from '../Account/User/UserPage';
import AddUserPage from '../Account/User/AddUserPage';
import UpdateUserPage from '../Account/User/UpdateUserPage';
import RolePage from '../Account/Role/RolePage';
import AddRolePage from '../Account/Role/AddRolePage';
import UpdateRolePage from '../Account/Role/UpdateRolePage';
import PrivilegePage from '../Account/Privilege/PrivilegePage';
import AddPrivilegePage from '../Account/Privilege/AddPrivilegePage';
import UpdatePrivilegePage from '../Account/Privilege/UpdatePrivilegePage';
import ActiveLogPage from '../Account/ActiveLog/ActiveLogPage';
import CompareActiveLogPage from '../Account/ActiveLog/CompareActiveLogPage';
import ShareActiveLogPage from '../Account/ActiveLog/ShareActiveLogPage';
import CategoryPage from '../Course/Category/CategoryPage';
import AddCategoryPage from '../Course/Category/AddCategoryPage';
import UpdateCategoryPage from '../Course/Category/UpdateCategoryPage';
import LevelPage from '../Course/Level/LevelPage';
import AddLevelPage from '../Course/Level/AddLevelPage';
import UpdateLevelPage from '../Course/Level/UpdateLevelPage';
import SubCategoryPage from '../Course/SubCategory/SubCategoryPage';
import AddSubCategoryPage from '../Course/SubCategory/AddSubCategoryPage';
import UpdateSubCategoryPage from '../Course/SubCategory/UpdateSubCategoryPage';
import CoursePage from '../Course/Course/CoursePage';
import AddCoursePage from '../Course/Course/AddCoursePage';
import UpdateCoursePage from '../Course/Course/UpdateCoursePage';
import SubjectPage from '../Subject/Subject/SubjectPage';
import AddSubjectPage from '../Subject/Subject/AddSubjectPage';
import UpdateSubjectPage from '../Subject/Subject/UpdateSubjectPage';
import TopicPage from '../Subject/Topic/TopicPage';
import AddTopicPage from '../Subject/Topic/AddTopicPage';
import UpdateTopicPage from '../Subject/Topic/UpdateTopicPage';
import InterviewQuestionPage from '../Subject/InterviewQuestion/InterviewQuestionPage';
import AddInterviewQuestionPage from '../Subject/InterviewQuestion/AddInterviewQuestionPage';
import UpdateInterviewQuestionPage from '../Subject/InterviewQuestion/UpdateInterviewQuestionPage';
import LandingContentPage from '../Content/Landing/LandingContentPage';
import AddLandingContentPage from '../Content/Landing/AddLandingContentPage';
import UpdateLandingContentPage from '../Content/Landing/UpdateLandingContentPage';
import QuizPage from '../Quiz/Quiz/QuizPage';
import AddQuizPage from '../Quiz/Quiz/AddQuizPage';
import UpdateQuizPage from '../Quiz/Quiz/UpdateQuizPage';
import QuestionPage from '../Quiz/Question/QuestionPage';
import AddQuestionPage from '../Quiz/Question/AddQuestionPage';
import UpdateQuestionPage from '../Quiz/Question/UpdateQuestionPage';
import OptionPage from '../Quiz/Option/OptionPage';
import AddOptionPage from '../Quiz/Option/AddOptionPage';
import UpdateOptionPage from '../Quiz/Option/UpdateOptionPage';
import QuizResultPage from '../Quiz/Result/QuizResultPage';

const AdminRoutes = () => {
    return (
        <Routes>
            {/* Redirect from /admin to /admin/dashboard */}
            <Route path="/" element={<Navigate to="/admin/login" replace />} />

            {/* Admin Public Routes */}
            <Route path="/login" element={<LoginPage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/forgotPassword" element={<ForgotPasswordPage />} />
            <Route path="/reset_password" element={<ResetPasswordPage />} />

            {/* Admin Private Routes */}
            <Route path="/dashboard" element={<MainLayout><DashboardPage /></MainLayout>} />
            <Route path="/permission" element={<PrivateRoute><PermissionPage /></PrivateRoute>} />

            {/* User Management Routes */}
            <Route path="/user" element={<MainLayout><UserPage /></MainLayout>} />
            <Route path="/add-user" element={<MainLayout><AddUserPage /></MainLayout>} />
            <Route path="/update-user/:userId" element={<MainLayout><UpdateUserPage /></MainLayout>} />

            {/* Role Management Routes */}
            <Route path="/role" element={<MainLayout><RolePage /></MainLayout>} />
            <Route path="/add-role" element={<MainLayout><AddRolePage /></MainLayout>} />
            <Route path="/update-role/:roleId" element={<MainLayout><UpdateRolePage /></MainLayout>} />

            {/* Privilege Management Routes */}
            <Route path="/privilege" element={<MainLayout><PrivilegePage /></MainLayout>} />
            <Route path="/add-privilege" element={<MainLayout><AddPrivilegePage /></MainLayout>} />
            <Route path="/update-privilege/:privilegeId" element={<MainLayout><UpdatePrivilegePage /></MainLayout>} />

            {/* Active Log Routes */}
            <Route path="/active-log" element={<MainLayout><ActiveLogPage /></MainLayout>} />
            <Route path="/compare-active-log/:activeLogId" element={<MainLayout><CompareActiveLogPage /></MainLayout>} />
            <Route path="/share-active-log/:activeLogId" element={<MainLayout><ShareActiveLogPage /></MainLayout>} />

            {/* Category Routes */}
            <Route path="/category" element={<MainLayout><CategoryPage /></MainLayout>} />
            <Route path="/add-category" element={<MainLayout><AddCategoryPage /></MainLayout>} />
            <Route path="/update-category/:categoryId" element={<MainLayout><UpdateCategoryPage /></MainLayout>} />

            {/* Level Routes */}
            <Route path="/level" element={<MainLayout><LevelPage /></MainLayout>} />
            <Route path="/add-level" element={<MainLayout><AddLevelPage /></MainLayout>} />
            <Route path="/update-level/:levelId" element={<MainLayout><UpdateLevelPage /></MainLayout>} />

            {/* Subcategory Routes */}
            <Route path="/subcategory" element={<MainLayout><SubCategoryPage /></MainLayout>} />
            <Route path="/add-subcategory" element={<MainLayout><AddSubCategoryPage /></MainLayout>} />
            <Route path="/update-subcategory/:subCategoryId" element={<MainLayout><UpdateSubCategoryPage /></MainLayout>} />

            {/* Course Routes */}
            <Route path="/course" element={<MainLayout><CoursePage /></MainLayout>} />
            <Route path="/add-course" element={<MainLayout><AddCoursePage /></MainLayout>} />
            <Route path="/update-course/:courseId" element={<MainLayout><UpdateCoursePage /></MainLayout>} />

            {/* Course Routes */}
            <Route path="/subject" element={<MainLayout><SubjectPage /></MainLayout>} />
            <Route path="/add-subject" element={<MainLayout><AddSubjectPage /></MainLayout>} />
            <Route path="/update-subject/:subjectId" element={<MainLayout><UpdateSubjectPage /></MainLayout>} />

            {/* Topic Routes */}
            <Route path="/topic" element={<MainLayout><TopicPage /></MainLayout>} />
            <Route path="/add-topic" element={<MainLayout><AddTopicPage /></MainLayout>} />
            <Route path="/update-topic/:topicId" element={<MainLayout><UpdateTopicPage /></MainLayout>} />

            {/*  Interview_question Routes */}
            <Route path="/interview-question" element={<MainLayout><InterviewQuestionPage /></MainLayout>} />
            <Route path="/add-interview-question" element={<MainLayout><AddInterviewQuestionPage /></MainLayout>} />
            <Route path="/update-interview-question/:interviewQuestionId" element={<MainLayout><UpdateInterviewQuestionPage /></MainLayout>} />

            {/*  Landing_Content Routes */}
            <Route path="/landing-content" element={<MainLayout><LandingContentPage /></MainLayout>} />
            <Route path="/add-landing-content" element={<MainLayout><AddLandingContentPage /></MainLayout>} />
            <Route path="/update-landing-content/:landingContentId" element={<MainLayout><UpdateLandingContentPage /></MainLayout>} />

            {/*  Quiz Routes */}
            <Route path="/quiz" element={<MainLayout><QuizPage /></MainLayout>} />
            <Route path="/add-quiz" element={<MainLayout><AddQuizPage/></MainLayout>} />
            <Route path="/update-quiz/:quizId" element={<MainLayout><UpdateQuizPage /></MainLayout>} />

            {/*  Quiz Routes */}
            <Route path="/question" element={<MainLayout><QuestionPage /></MainLayout>} />
            <Route path="/add-question" element={<MainLayout><AddQuestionPage/></MainLayout>} />
            <Route path="/update-question/:questionId" element={<MainLayout><UpdateQuestionPage /></MainLayout>} />

             {/* Option Routes */}
             <Route path="/option" element={<MainLayout><OptionPage /></MainLayout>} />
             <Route path="/add-option" element={<MainLayout><AddOptionPage/></MainLayout>} />
             <Route path="/update-option/:optionId" element={<MainLayout><UpdateOptionPage /></MainLayout>} />

             {/* Quiz Result Routes */}
             <Route path="/quiz-result" element={<MainLayout><QuizResultPage /></MainLayout>} />

            {/* Catch-all for 404 */}
            <Route path="*" element={<NotFoundPage />} />
        </Routes>
    );
};

export default AdminRoutes;
