import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Typography, Button, Container, Paper, Avatar } from '@mui/material';
import NoteIcon from '@mui/icons-material/Note';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import ListAltIcon from '@mui/icons-material/ListAlt';
import SaveIcon from '@mui/icons-material/Save';


const features = [
  { id: 1, title: 'Create Own Notes', icon: <NoteIcon fontSize="large" />, description: 'Write and manage your personal notes' },
  { id: 2, title: 'Save Questions', icon: <QuestionAnswerIcon fontSize="large" />, description: 'Store important questions for reference' },
  { id: 3, title: 'Store Cheat Sheets', icon: <ListAltIcon fontSize="large" />, description: 'Keep your cheat sheets handy for quick access' },
  { id: 4, title: 'Save File', icon: <SaveIcon fontSize="large" />, description: 'Upload and save important files securely' },
];

const PersonalWorkplacePage = () => {
  const navigate = useNavigate(); // Initialize the navigate function

  const handleNavigate = (featureId) => {
    if (localStorage.getItem('is_authenticate') === 'true') {
      navigate(`/portal/work-space/${featureId}`);
    } else {
      if (localStorage.getItem('previous_page')) {
        localStorage.removeItem('previous_page');
      }
      localStorage.setItem('previous_page', `/portal/work-space/${featureId}`);
      navigate('/login');
    }
  };
  return (
    <Container maxWidth="xl" style={{ marginTop: '3rem' }}>
      {/* Page Title */}
      <Typography variant="h4" fontWeight="bold" textAlign="center" gutterBottom>
        Personal Workspace
      </Typography>
      <Typography variant="subtitle1" color="textSecondary" textAlign="center" gutterBottom>
        Use these features to create, save, and organize your personal resources.
      </Typography>
      {/* Feature Grid */}
      <Grid container spacing={4} justifyContent="center" style={{ marginTop: '0.2rem' }}>
        {features.map((feature, index) => (
          <Grid item xs={12} sm={6} md={3} key={index} style={{ marginBottom: '1rem' }}>
            <Paper elevation={10} style={{ padding: '1.2rem', textAlign: 'center', height: '100%' }}>
              <Avatar style={{ margin: '0 auto', backgroundColor: '#10d915', width: 60, height: 60 }}>
                {feature.icon}
              </Avatar>
              <Typography variant="h6" style={{ marginTop: '1rem', fontWeight: 'bold' }}>
                {feature.title}
              </Typography>
              <Typography variant="body2" color="textSecondary" style={{ margin: '1rem 0' }}>
                {feature.description}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => handleNavigate(feature.id)} // Pass feature.id to the navigate function
              >
                Explore
              </Button>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default PersonalWorkplacePage;
