import { useState } from "react";
import axios from "axios";

const useCompilerApi = () => {
  const [output, setOutput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState("");

  const runCode = async (code, language) => {
    const data = new URLSearchParams();
    data.append("code", code);
    data.append("language", language);
    data.append("input", ""); // Default empty input, modify if necessary

    setIsLoading(true);
    setApiError("");

    try {
      const response = await axios.post("https://api.codex.jaagrav.in", data, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });

      const { output, error } = response.data;
      setOutput(output || `Error: ${error || "An unknown error occurred."}`);
    } catch (error) {
      setApiError(error.message);
      setOutput("Error: Unable to fetch output.");
    } finally {
      setIsLoading(false);
    }
  };

  return {
    output,
    isLoading,
    apiError,
    runCode,
  };
};

export default useCompilerApi;
