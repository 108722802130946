import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Grid, MenuItem, Select, InputLabel, FormControl } from '@mui/material';
import GoogleAnalyticSection1Page from './GoogleAnalyticSection1Page';
import GoogleAnalyticSection2Page from './GoogleAnalyticSection2Page';

const DashboardPage = () => {

  const [token, setToken] = useState('');
  const [timeRange, setTimeRange] = useState('last_7_days');

  useEffect(() => {
    const localStorageToken = localStorage.getItem('token');
    setToken(localStorageToken);
  }, []);

  // Handle dropdown selection change
  const handleTimeRangeChange = (event) => {
    setTimeRange(event.target.value);
  };

  return (
      <Box sx={{ display: 'flex' }}>
          <Grid container spacing={2} mt={0.5}>
            {/* Grid item for the dropdown on the left */}
            <Grid item xs={12} sm={6} md={3.95} sx={{ textAlign: 'left' }}>
              <FormControl fullWidth>
                <InputLabel id="time-range-select-label">Time Range</InputLabel>
                <Select
                  labelId="time-range-select-label"
                  id="time-range-select"
                  value={timeRange}
                  label="Time Range"
                  onChange={handleTimeRangeChange}
                  size='small'
                >
                  <MenuItem value="last_day">Last Day</MenuItem>
                  <MenuItem value="last_7_days">Last 7 Days</MenuItem>
                  <MenuItem value="last_14_days">Last 14 Days</MenuItem>
                  <MenuItem value="last_30_days">Last 30 Days</MenuItem>
                  <MenuItem value="last_90_days">Last 90 Days</MenuItem>
                  <MenuItem value="all_time">All Time</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {/* The rest of the content will remain centered */}
            <Grid container item xs={12} spacing={3} justifyContent="center">
              <GoogleAnalyticSection1Page token={token} timeRange={timeRange} />
              <GoogleAnalyticSection2Page token={token} timeRange={timeRange} />
            </Grid>
          </Grid>
      </Box>
  );
};

export default DashboardPage;
